import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as UploadIllustration } from "../../Assets/file-upload.svg";
import ReactCountryFlag from "react-country-flag";
import UploadDialog from "./UploadDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  BudgetDialog,
  BudgetUpload,
  SyncActuals,
} from "../FinancialReporting/FinancialReportingActions/FinancialReportingActions";
import { Button } from "@mui/material";
import IconBreadcrumbs from "./IconBreadcrumbs";
import { QuickBooksPLTest } from "../../constants";
import { QuickBooksPLProd } from "../../constants";
import { QuickBooksBSTest } from "../../constants";
import { QuickBooksBSProd } from "../../constants";
import { QuickBooksCFTest } from "../../constants";
import { QuickBooksCFProd } from "../../constants";
import { ZohoPLProd } from "../../constants";
import { ZohoBSProd } from "../../constants";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FinanceAnalyst() {
  const dispatch = useDispatch();

  //--------------------------------------------------------------------------

  //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  //---------------------------------------------------------------------------

  const budgetDialog = useSelector(
    (state) => state.FinancialReportingReducers.budgetDialog
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //----------------------------- Country List Dropdown ---------------------

  const [country, setCountry] = React.useState("INDIA");

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  //-------------------------------------------------------------------------

  const [budgetFile, setBudgetFile] = React.useState("");
  const handleBudgetInput = (e) => {
    setBudgetFile(e.target.files[0]);
  };

  //--------------------------------------------------------------------------

  const handleUploadBudget = () => {
    dispatch(BudgetDialog(!budgetDialog));
    dispatch(BudgetUpload(budgetFile, fetchAccessTokenSucess.access_token,"financial analyst"));
  };

  //-------------- Upload Budget Button ---------------------------------------
  const [uploadBudgetButtonFlag, setUploadBudgetButtonFlag] =
    React.useState(false);

  useEffect(() => {
    checkAllUserInputsforUpload();
  }, [country, budgetFile]);

  const checkAllUserInputsforUpload = () => {
    if (country && budgetFile) {
      setUploadBudgetButtonFlag(true);
    }
  };

  const accountingSoftareType = localStorage.getItem("AcoountingSoftware");

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 4, height: "80vh" }}>
        <Grid
          container
          spacing={0}
          sx={{
            boxShadow: " 0px 30px 60px rgb(39 39 71 / 20%)",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mt: 2,
            }}
          >
            <Typography
              sx={{
                p: 3,
                pb: 1,
                pt: 0,
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Financial{" "}
              <span
                style={{
                  color: "#0093FB",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                }}
              >
                Reporting
              </span>
            </Typography>
            <Divider sx={{ p: 2, pt: 0, ml: 2, mr: 2, pb: 0 }} />
            <Alert
              sx={{ ml: 2, mr: 2, mb: 0, fontFamily: "'Roboto', sans-serif" }}
              severity="info"
            >
              <Typography
                sx={{ fontFamily: "'Roboto', sans-serif", fontSize: 14 }}
              >
                Track, analyse and report your business income
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Box sx={{ width: "100%", pl: 3, pr: 3 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {/* <Tab
                    sx={{ textTransform: "capitalize" }}
                    label="Upload Budget"
                    {...a11yProps(0)}
                  /> */}
                  <Tab
                    sx={{ textTransform: "capitalize" }}
                    label="Profit & Loss"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ textTransform: "capitalize" }}
                    label="Balance Sheet"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{ textTransform: "capitalize" }}
                    label="Cash Flow"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <Grid container>
                <Grid item xs={12} sx={{ mt: 1, ml: 2 }}>
                  <IconBreadcrumbs />
                </Grid>
              </Grid>
              <TabPanel value={value} index={0}>
                <Grid container>
                  {accountingSoftareType == "qb" ? (
                    <>
                      <Grid item xs={12}>
                        <iframe
                          title="Proft & Lose"
                          width="1300"
                          height="541.25"
                          src={QuickBooksPLProd}
                          frameborder="0"
                          allowFullScreen="true"
                        ></iframe>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container>
                  {accountingSoftareType == "qb" ? (
                    <>
                      <Grid item xs={12}>
                        <iframe
                          title="Balance Sheet"
                          width="1300"
                          src={QuickBooksBSProd}
                          height="541.25"
                          frameborder="0"
                          allowFullScreen="true"
                        ></iframe>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid container>
                  {accountingSoftareType == "qb" ? (
                    <>
                      <Grid item xs={12}>
                        <iframe
                          title="Cash Flow"
                          width="1300"
                          height="541.25"
                          src={QuickBooksCFProd}
                          frameborder="0"
                          allowFullScreen="true"
                        ></iframe>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {budgetDialog ? <UploadDialog /> : null}
    </>
  );
}

export default FinanceAnalyst;
