import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ReactComponent as UploadIllustration } from "../../Assets/file-upload.svg";
import UploadDialog from "./UploadDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  BudgetDialog,
  BudgetUpload,
  SyncActuals,
  BudgetApprovalCount,
} from "../FinancialReporting/FinancialReportingActions/FinancialReportingActions";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import IconBreadcrumbs from "./IconBreadcrumbs";
import template from "../../Assets/budget_template.xlsx";
import { AccountsList } from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BudgetReview from "./BudgetReview";
import { CombinedReport } from "../../constants";
import { QuickBooksPLTest } from "../../constants";
import { QuickBooksPLProd } from "../../constants";
import { QuickBooksBSTest } from "../../constants";
import { QuickBooksBSProd } from "../../constants";
import { QuickBooksCFTest } from "../../constants";
import { QuickBooksCFProd } from "../../constants";
import { ZohoPLProd } from "../../constants";
import { ZohoBSProd } from "../../constants";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InsideDataAi from "./InsideDataAi/InsideDataAi";

const budgetTypeList = [
  { name: "Profit & Loss", value: "profit loss" },
  { name: "Balance Sheet", value: "balance sheet" },
];

const templateTypeList = [
  { name: "Profit & Loss", value: "profit loss" },
  { name: "Balance Sheet", value: "balance sheet" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Index() {
  const dispatch = useDispatch();

  //Access Token
  const fetchAccessTokenSucess = useSelector((state) => state.LandingPageReducers.fetchAccessTokenSucess);

  //------------------------------------------------------------------------

  useEffect(() => {
    dispatch(SyncActuals(fetchAccessTokenSucess.access_token, fetchAccessTokenSucess.org_id));
    dispatch(AccountsList(fetchAccessTokenSucess.access_token));
    dispatch(BudgetApprovalCount());
  }, []);

  //------------------------------------------------------------------------

  const accountListLoading = useSelector((state) => state.AccountsPayableReducers.accountListLoading);

  const [accountTypeArray, setAccountTypeArray] = React.useState([]);

  const accountListSucess = useSelector((state) => state.AccountsPayableReducers.accountListSucess);

  useEffect(() => {
    updateAccountList();
  }, [accountListSucess]);

  const updateAccountList = () => {
    let names = [];
    if (accountListSucess.accounts_data) {
      names = accountListSucess.accounts_data.map((item) => item.acc_type);
    }
    setAccountTypeArray(names);
  };
  //------------------------------------------------------------------------
  const budgetDialog = useSelector((state) => state.FinancialReportingReducers.budgetDialog);

  //---------------------------------------------------------------------------
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //-------------------- Selected Budget Type---------------------------------

  const [selectedBudgetType, setselectedBudgetType] = React.useState();

  const handleBudgetType = (e) => {
    setselectedBudgetType(e);
  };

  //-------------------- Selected Template Type---------------------------------

  const [selectedTemplateType, setselectedTemplateType] = React.useState("profit loss");

  const handleTemplateType = (e) => {
    setselectedTemplateType(e);
  };

  //-------------------------------------------------------------------------

  const [budgetFile, setBudgetFile] = React.useState("");
  const handleBudgetInput = (e) => {
    setBudgetFile(e.target.files[0]);
  };

  //--------------------------------------------------------------------------

  const handleUploadBudget = () => {
    dispatch(BudgetDialog(!budgetDialog));
    dispatch(BudgetUpload(budgetFile, fetchAccessTokenSucess.access_token, fetchAccessTokenSucess.org_id));
  };

  //-------------- Upload Budget Button ---------------------------------------

  const [uploadBudgetButtonFlag, setUploadBudgetButtonFlag] = React.useState(false);

  useEffect(() => {
    checkAllUserInputsforUpload();
  }, [selectedBudgetType, budgetFile]);

  const checkAllUserInputsforUpload = () => {
    if (selectedBudgetType && budgetFile) {
      setUploadBudgetButtonFlag(true);
    }
  };

  const role = localStorage.getItem("UserType");
  const accountingSoftware = localStorage.getItem("AcoountingSoftware");

  //-----------------------------------------------------------------------------

  //------------Setting The User Role -----------------------------

  const getUserRoleSuccess = useSelector((state) => state.DashboardReducers.getUserRoleSuccess);

  const userRoleFromLogin = localStorage.getItem("userRoleFromLogin");
  console.log("userRoleFromLogin", userRoleFromLogin);

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 4, height: "80vh" }}>
        <Grid
          container
          spacing={0}
          sx={{
            boxShadow: " 0px 30px 60px rgb(39 39 71 / 20%)",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mt: 2,
            }}
          >
            <Typography
              sx={{
                p: 3,
                pb: 1,
                pt: 0,
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Financial{" "}
              <span
                style={{
                  color: "#0093FB",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                }}
              >
                Reporting
              </span>
            </Typography>
            <Divider sx={{ p: 2, pt: 0, ml: 2, mr: 2, pb: 0 }} />
            <Alert sx={{ ml: 2, mr: 2, mb: 0, fontFamily: "'Roboto', sans-serif" }} severity="info">
              <Typography sx={{ fontFamily: "'Roboto', sans-serif", fontSize: 14 }}>
                Track, analyse and report your business income
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Box sx={{ width: "100%", pl: 3, pr: 3 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab sx={{ textTransform: "capitalize" }} label="Upload Budget" {...a11yProps(0)} />
                  <Tab sx={{ textTransform: "capitalize" }} label="Financial Reporting" {...a11yProps(1)} />
                  {/* <Tab
                    sx={{ textTransform: "capitalize" }}
                    label="balance sheet"
                    {...a11yProps(2)}
                  />
                  <Tab
                    sx={{ textTransform: "capitalize" }}
                    label="Cash Flow"
                    {...a11yProps(3)}
                  /> */}
                  <Tab sx={{ textTransform: "capitalize" }} label="Budget Template" {...a11yProps(2)} />
                  {userRoleFromLogin == "Finance Controller" && accountingSoftware != "skip" ? (
                    <Tab sx={{ textTransform: "capitalize" }} label="Budget Review" {...a11yProps(3)} />
                  ) : null}
                </Tabs>
              </Box>
              <Grid container>
                <Grid item xs={12} sx={{ mt: 1, ml: 2 }}>
                  <IconBreadcrumbs />
                </Grid>
              </Grid>
              <TabPanel value={value} index={0}>
                <Container maxWidth="lg">
                  <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        select
                        id="budget_type"
                        name="budget_type"
                        label="Budget Type"
                        variant="outlined"
                        size="medium"
                        value={selectedBudgetType}
                        onChange={(event) => handleBudgetType(event.target.value)}
                      >
                        {budgetTypeList.map((option, key) => (
                          <MenuItem
                            key={key}
                            value={option.value}
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #008ffc",
                                borderRadius: 1,
                              },
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        border: "1px solid #C5C5C5",
                        display: "flex",
                        borderRadius: 2,
                        flexDirection: "column",
                        mt: 2,
                        ml: 0,
                        mr: 1,
                        p: 2,
                        mb: 5,
                      }}
                    >
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <UploadIllustration />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px",
                          marginBottom: "10px",
                        }}
                      >
                        <input type="file" name="budget" multiple onChange={handleBudgetInput}></input>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        disabled={!uploadBudgetButtonFlag}
                        variant="contained"
                        onClick={handleUploadBudget}
                        sx={{ textTransform: "capitalize" }}
                      >
                        Upload Budget
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container>
                  <>
                    <Grid item xs={12}>
                      <iframe
                        title="Proft & Lose"
                        width="1300"
                        height="541.25"
                        src={CombinedReport}
                        frameborder="0"
                        allowFullScreen="true"
                      ></iframe>
                    </Grid>
                  </>
                </Grid>
              </TabPanel>
              {/* <TabPanel value={value} index={2}>
                <Grid container>
                    <>
                      <Grid item xs={12}>
                        <iframe
                          title="Balance Sheet"
                          width="1300"
                          src={QuickBooksBSProd}
                          height="541.25"
                          frameborder="0"
                          allowFullScreen="true"
                        ></iframe>
                      </Grid>
                    </>
                  
                </Grid>
              </TabPanel> */}
              {/* <TabPanel value={value} index={3}>
                <Grid container>
                  
                    <>
                      <Grid item xs={12}>
                        <iframe
                          title="Cash Flow"
                          width="1300"
                          height="541.25"
                          src={QuickBooksCFProd}
                          frameborder="0"
                          allowFullScreen="true"
                        ></iframe>
                      </Grid>
                    </>
                </Grid>
              </TabPanel> */}
              <TabPanel value={value} index={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                    <TextField
                      fullWidth
                      select
                      id="template_type"
                      name="template_type"
                      label="Template Type"
                      variant="outlined"
                      size="medium"
                      value={selectedTemplateType}
                      onChange={(event) => handleTemplateType(event.target.value)}
                    >
                      {budgetTypeList.map((option, key) => (
                        <MenuItem
                          key={key}
                          value={option.value}
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            "&:hover": {
                              borderLeft: "5px solid #008ffc",
                              borderRadius: 1,
                            },
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <form style={{ marginTop: "20px" }} method="get" action={template}>
                      <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: "capitalize", p: 1.5 }}
                        type="submit"
                      >
                        Download {selectedTemplateType}!
                      </Button>
                    </form>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TableContainer component={Paper} sx={{ mt: 3 }} style={{ maxHeight: 410 }}>
                      <Table stickyHeader sx={{ minWidth: 350, minHeight: 100 }} aria-label="simple table">
                        <TableHead>
                          <TableRow sx={{}}>
                            <TableCell
                              align="center"
                              sx={{
                                color: "#ffffff",
                                fontWeight: 900,
                                background: "#0093FB",
                              }}
                            >
                              Available Account Types
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {accountTypeArray.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
              {userRoleFromLogin == "Finance Controller" && accountingSoftware != "skip" ? (
                <TabPanel value={value} index={3}>
                  <BudgetReview />
                </TabPanel>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Container>
      {budgetDialog ? <UploadDialog /> : null}
      {value === 1 && <InsideDataAi />}
    </>
  );
}

export default Index;
