import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector, useDispatch } from "react-redux";
import {
  TrackInvoiceAR,
  DeleteInvoiceDialogAR,
  DeleteInvoiceFromAccountingSoftware,
} from "../AccountReceivable/AccountReceivableActions/AccountReceivableActions";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import noData from "../../Assets/undraw_No_data_re_kwbl-removebg-preview.png";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InfoIcon from "@mui/icons-material/Info";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DeleteInvoiceDialog from "./DeleteInvoiceDialog";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function TrackInvoice() {
  const dispatch = useDispatch();
  const accountingSoftware = localStorage.getItem("AcoountingSoftware")

  //API Call
  useEffect(() => {
    dispatch(TrackInvoiceAR());
  }, []);

  //API Response
  const trackInvoiceSucess = useSelector(
    (state) => state.AccountsPayableReducers.trackInvoiceSucess
  );

  console.log("trackInvoiceSucess",trackInvoiceSucess)
  console.log("trackInvoiceSucessv - Type",typeof(trackInvoiceSucess))

  const [myArray, setMyArray] = React.useState([]);
  useEffect(() => {
    handleDataUpdate();
  }, [trackInvoiceSucess]);

  const handleDataUpdate = () => {
    if (trackInvoiceSucess.length) {
      let data = trackInvoiceSucess.sort(
        (a, b) => new Date(b.UploadedDate) - new Date(a.UploadedDate)
      );
      setMyArray(data);
    }
  };
  //-----

  const trackInvoiceLoading = useSelector(
    (state) => state.AccountReceivableReducers.trackInvoiceLoading
  );


  //-----------------------------------------------------------------------
  //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  //------------------------------------------------------------------------
  const deleteDialog = useSelector(
    (state) => state.AccountReceivableReducers.deleteDialog
  );
  const [selectedIndex, setSelectedIndex] = React.useState();
  const handleDeleteInvoice = (id) => {
    setSelectedIndex(id);
    dispatch(DeleteInvoiceDialogAR(!deleteDialog));
    dispatch(
      DeleteInvoiceFromAccountingSoftware(
        fetchAccessTokenSucess.access_token,
        accountingSoftware,
        id,
        fetchAccessTokenSucess.org_id
      )
    );
  };

  const handleViewInvoice = (uri) => {
    window.open(uri);
  };

  //Dialog Functions

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [lineItemDetails, setLineItemDetails] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewLineItemDetails = (data) => {
    handleClickOpen(!open);
    setLineItemDetails(data);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 0 }}>
        <Grid container spacing={0}>
          {trackInvoiceLoading ? (
            <>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgressWithLabel />
              </Grid>
            </>
          ) : null}
          {myArray && myArray.length && Object.keys(trackInvoiceSucess).length > 0 ? (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  ml: 0,
                  mr: 0,
                  mt: 2,
                  mb: 2,
                  border: "1px solid #C5C5C5",
                  borderRadius: 2,
                }}
              >
                <TableContainer sx={{ height: "55vh" }}>
                  <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          #
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Client Name
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Invoice Number
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Billing Date
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Due Date
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Uploaded By
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="left"
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <>
                      <TableBody>
                        {myArray.map((row, index) => (
                          <>
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="center">
                                {row.ClientName}
                              </TableCell>
                              <TableCell align="center">
                                {row.InvoiceNum}
                              </TableCell>
                              <TableCell align="center">
                                {row.TotalAmount}
                              </TableCell>
                              <TableCell align="center">
                                {row.InvoiceDate}
                              </TableCell>
                              <TableCell align="center">
                                {row.DueDate}
                              </TableCell>
                              <TableCell align="center">
                                {row.UploadedBy}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "'Roboto', sans-serif",
                                  display: "flex",
                                }}
                              >
                                <Tooltip title="Download Invoice">
                                  <IconButton
                                    disabled={row.FileURL == null}
                                    onClick={() =>
                                      handleViewInvoice(row.FileURL)
                                    }
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ ml: 0, color: "#0093FB" }}
                                  >
                                    <ArrowDownwardIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="View Line Items">
                                  <IconButton
                                    onClick={() =>
                                      handleViewLineItemDetails(row.LineItems)
                                    }
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ ml: 0, color: "#4CBB17" }}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Invoice">
                                  <IconButton
                                   disabled={accountingSoftware == "skip"}
                                    onClick={() => handleDeleteInvoice(row.Id)}
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ ml: 0, color: "#FF0000" }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img src={noData} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Invoice Details</DialogTitle>
        <DialogContent>
          {lineItemDetails && lineItemDetails.length ? (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  ml: 0,
                  mr: 0,
                  mt: 2,
                  mb: 2,
                  border: "1px solid #C5C5C5",
                  borderRadius: 2,
                }}
              >
                <TableContainer sx={{ height: "30vh" }}>
                  <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          #
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Account Name
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Account Type
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Description
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Rate
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "'Roboto', sans-serif",
                            fontWeight: 600,
                          }}
                          align="center"
                        >
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <>
                      <TableBody>
                        {lineItemDetails.map((row, index) => (
                          <>
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="center">
                                {row.AccName}
                              </TableCell>
                              <TableCell align="center">
                                {row.AccType}
                              </TableCell>
                              <TableCell align="center">
                                {row.Description}
                              </TableCell>
                              <TableCell align="center">
                                {row.Quantity}
                              </TableCell>
                              <TableCell align="center">{row.Rate}</TableCell>
                              <TableCell align="center">{row.Total}</TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img src={noData} />
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {deleteDialog ? <DeleteInvoiceDialog index={selectedIndex} /> : null}
    </>
  );
}

export default TrackInvoice;
