import TYPES from "../AccountsPayableTypes/AccountsPayableTypes";
import Axios from "axios";
import { auth_url } from "../../../constants";
import { redirect_url } from "../../../constants";

const accountingSoftareType = localStorage.getItem("AcoountingSoftware");
var base_url = "";

if (accountingSoftareType == "qb") {
  base_url =
    "https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net";
}

if (accountingSoftareType == "zoho") {
  base_url = "https://asvinth-backend-zoho-dev-eastus-staging.azurewebsites.net";
}

if (accountingSoftareType == "xero") {
  base_url = "https://asvinth-backend-xero-dev-eastus.azurewebsites.net";
}

//-------------------------Invoice Parsing--------------------

// export const InvoiceParsingPdfSingle =
//   (invoice, token, inputFields, organizationId, GSTIN) => (dispatch) => {
//     dispatch(InvoiceParsingPdfLoading());

//     //Sending login token to ensure the data privacy

//     const accessTokenLogin = localStorage.getItem("accessTokenLogin");
//     const config = {
//       headers: {
//         Authorization: `Bearer ${accessTokenLogin}`,
//         "Access-Control-Allow-Origin": "*",
//       },
//     };

//     const code = localStorage.getItem("code");
//     const state = localStorage.getItem("state");
//     const relmid = localStorage.getItem("realmId");
//     let length = invoice.length;
//     let data = null;
//     data = new FormData();
//     data.append("category1", inputFields[0].category1);
//     data.append("category2", inputFields[0].category2);
//     data.append("file", invoice[0].data);
//     data.append("vendor", inputFields[0].vendor);
//     data.append("code", code);
//     data.append("state", state);
//     data.append("realmId", relmid);
//     data.append("redirect_uri", redirect_url);
//     data.append("access_token", token);
//     data.append("ven_curr_code", inputFields[0].ven_curr_code);
//     data.append("organization_id", organizationId);
//     data.append("gstin", GSTIN);

//     Axios.post(`${base_url}/file-upload`, data, config)
//       .then((response) => {
//         dispatch({
//           type: TYPES.INVOICE_PARSING_SUCCESS,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: TYPES.INVOICE_PARSING_FAIL,
//           payload: error.response.data,
//         });
//       });
//   };

export const InvoiceParsingPdfSingle =
  (invoice, token, inputFields, organizationId, GSTIN, pip_id) =>
  (dispatch) => {
    dispatch(InvoiceParsingPdfLoading());

    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };

    //-------------------------------------------------------

    //Saving the account type and line item in local storage


    localStorage.setItem("account_type",inputFields[0].category1)
    localStorage.setItem("line_item",inputFields[0].category2)



    //--------------------------------------------------------

    const code = localStorage.getItem("code");
    const state = localStorage.getItem("state");
    const relmid = localStorage.getItem("realmId");
    let length = invoice.length;
    let data = null;
    data = new FormData();
    data.append("account_type", inputFields[0].category1);
    data.append("line_item", inputFields[0].category2);
    data.append("file", invoice[0].data);
    data.append("vendor_name", inputFields[0].vendor);
    data.append("code", code);
    data.append("state", state);
    data.append("realm_id", relmid);
    data.append("redirect_uri", redirect_url);
    data.append("access_token", token);
    data.append("vendor_curr_code", inputFields[0].ven_curr_code);
    data.append("organization_id", organizationId);
    data.append("pip_id", pip_id);
    data.append("gstin", GSTIN);

    Axios.post(`${base_url}/extract-ap-data`, data,config)
      .then((response) => {
        dispatch({
          type: TYPES.INVOICE_PARSING_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.INVOICE_PARSING_FAIL,
          payload: error.response.data,
        });
      });
  };

export const InvoiceParsingPdf =
  (invoice, token, items, organizationId) => (dispatch) => {
    dispatch(InvoiceParsingPdfLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    console.log(JSON.stringify(items));

    let obj = {};
    obj.data = items;

    console.log(JSON.stringify(obj));

    const code = localStorage.getItem("code");
    const state = localStorage.getItem("state");
    const relmid = localStorage.getItem("realmId");

    let data = null;
    data = new FormData();
    console.log("invoice in action", invoice);
    for (let i = 0; i < invoice.length; i++) {
      data.append("files", invoice[i].data);
    }
    data.append("realmId", relmid);
    data.append("access_token", token);
    data.append("files_metadata", JSON.stringify(obj));
    data.append("organization_id", organizationId);

    Axios.post(`${base_url}/file_upload_bulk`, data, config)
      .then((response) => {
        dispatch({
          type: TYPES.INVOICE_PARSING_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.INVOICE_PARSING_FAIL,
          payload: error.response.data,
        });
      });
  };

//-------------------------Invoice Parsing Loading --------------------

export const InvoiceParsingPdfLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.INVOICE_PARSING_LOADING,
  });
};

//-------------------------Invoice Parsing Clean Data--------------------

export const InvoiceParsingPdfClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.INVOICE_PARSING_CLEAR_DATA,
  });
};

//-------------------------AP Upload Invoice Dialog--------------------
export const InvoiceParsingDialogbox = (status) => (dispatch) => {
  dispatch({
    type: TYPES.INVOICE_PARSING_DIALOG,
    payload: status,
  });
};

//-------------------------Pending Invoice List--------------------

export const PendingInvoiceList = () => (dispatch) => {
  dispatch(PendingInvoiceListLoading());

  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  Axios.get(`${base_url}/pending-invoices`, config)
    .then((response) => {
      dispatch({
        type: TYPES.PENDING_INVOICE_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.PENDING_INVOICE_LIST_FAIL,
      });
    });
};

//--------------------------Pending Invoice List Loading --------------------

export const PendingInvoiceListLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.PENDING_INVOICE_LIST_LOADING,
  });
};

//--------------------------Pending Invoice List Clear Data--------------------

export const PendingInvoiceListClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.PENDING_INVOICE_LIST_CLEAR_DATA,
  });
};

//------------------------- Pending Invoice Upload --------------------

export const PendingInvoiceUpload =
  (
    VendorName,
    Category1,
    Category2,
    invoiceNumber,
    invoiceAmount,
    billingDate,
    netTrerms,
    tokenFromFile,
    id,
    tax_amount,
    organizationId
  ) =>
  (dispatch) => {
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };

    const token = localStorage.getItem("accessToken");
    const relmid = localStorage.getItem("realmId");
    dispatch(PendingInvoiceUploadLoading());
    const body = {
      all_pen_invs: [
        {
          VendorName: VendorName,
          Category1: Category1,
          Category2: Category2,
          InvoiceNumber: invoiceNumber,
          InvoiceTotal: invoiceAmount,
          BillingDate: billingDate,
          NetTerms: netTrerms,
          Id: id,
          tax_amount: tax_amount,
          organization_id: organizationId,
        },
      ],
      access_token: tokenFromFile,
      realm_id: relmid,
      organization_id: organizationId,
    };
    Axios.post(`${base_url}/pending-invoices`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.PENDING_INVOICE_UPLOAD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.PENDING_INVOICE_UPLOAD_FAIL,
        });
      });
  };

//-------------------------- Pending Invoice Upload Loading --------------------

export const PendingInvoiceUploadLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.PENDING_INVOICE_UPLOAD_LOADING,
  });
};

//-------------------------- Pending Invoice Upload Clearata --------------------

export const PendingInvoiceUploadClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.PENDING_INVOICE_UPLOAD_CLEAR_DATA,
  });
};

//-------------------------- Invoice Edit Dialog --------------------

export const InvoiceEditDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.INVOICE_EDIT_DIALOG,
    payload: status,
  });
};

//-------------------------Pending Invoice List In Accounting Software--------------------

export const PendingInvoiceListInAccountingSoftware = () => (dispatch) => {
  dispatch(PendingInvoiceListInAccountingSoftwareLoading());

  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  Axios.get(`${base_url}/pending-invoices`, config)
    .then((response) => {
      dispatch({
        type: TYPES.PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_FAIL,
      });
    });
};

//--------------------------Pending Invoice List In Accounting Software Loading --------------------

export const PendingInvoiceListInAccountingSoftwareLoading =
  () => (dispatch) => {
    dispatch({
      type: TYPES.PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_LOADING,
    });
  };

//--------------------------Pending Invoice List In Accounting Software Clear Data--------------------

export const PendingInvoiceListInAccountingSoftwareClearData =
  () => (dispatch) => {
    dispatch({
      type: TYPES.PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_CLEAR_DATA,
    });
  };

//-------------------------Pending Invoice Upload In Accounting Software--------------------

export const PendingInvoiceUploadInAccountingSoftware =
  (tokenFromFile, id) => (dispatch) => {
    dispatch(PendingInvoiceUploadInAccountingSoftwareLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const relmid = localStorage.getItem("realmId");
    const body = {
      ids: [
        {
          Id: id,
        },
      ],
      access_token: tokenFromFile,
      realm_id: relmid,
    };
    Axios.post(`${base_url}/pending_invoices_at_acc_soft`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_FAIL,
        });
      });
  };

//--------------------------Pending Invoice Upload In Accounting Software Loading --------------------

export const PendingInvoiceUploadInAccountingSoftwareLoading =
  () => (dispatch) => {
    dispatch({
      type: TYPES.PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_LOADING,
    });
  };

//--------------------------Pending Invoice Upload In Accounting Software Clear Data--------------------

export const PendingInvoiceUploadInAccountingSoftwareClearData =
  () => (dispatch) => {
    dispatch({
      type: TYPES.PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_CLEAR_DATA,
    });
  };

//-------------------------- Invoice Approve Dialog --------------------

export const InvoiceApproveDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.INVOICE_APPROVE_DIALOG,
    payload: status,
  });
};
//-------------------------Track Invoice List--------------------

export const TrackInvoiceList = () => (dispatch) => {
  dispatch(TrackInvoiceLoading());

  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  Axios.get(`${base_url}/get_account_payables`, config)
    .then((response) => {
      dispatch({
        type: TYPES.TRACK_INVOICE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.TRACK_INVOICE_FAIL,
      });
    });
};

//--------------------------Track Invoice Loading --------------------

export const TrackInvoiceLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.TRACK_INVOICE_LOADING,
  });
};

//--------------------------Track Invoice  Clear Data--------------------

export const TrackInvoiceClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.TRACK_INVOICE_CLEAR_DATA,
  });
};

//-------------------------Accounts List from QB--------------------

export const AccountsList = (tokenFromFile) => (dispatch) => {
  dispatch(AccountsListLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const relmid = localStorage.getItem("realmId");
  const body = {
    access_token: tokenFromFile,
    realm_id: relmid,
  };
  Axios.get(`${base_url}/get_accounts`, config)
    .then((response) => {
      dispatch({
        type: TYPES.ACCOUNT_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.ACCOUNT_LIST_FAIL,
      });
    });
};

//--------------------------Accounts List from QB Loading --------------------

export const AccountsListLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ACCOUNT_LIST_LOADING,
  });
};

//--------------------------Accounts List from QB  Clear Data--------------------

export const AccountsListClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ACCOUNT_LIST_CLEAR_DATA,
  });
};

//------------------------- Customer  List from QB--------------------

export const CustomerList = (tokenFromFile, organizationId) => (dispatch) => {
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const relmid = localStorage.getItem("realmId");
  const body = {
    access_token: tokenFromFile,
    realm_id: relmid,
    organization_id: organizationId,
  };
  dispatch(CustomerListLoading());
  Axios.post(`${base_url}/get_stakeholders`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.CUSTOMER_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.CUSTOMER_LIST_FAIL,
      });
    });
};

//--------------------------Customer  List from QB Loading --------------------

export const CustomerListLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.CUSTOMER_LIST_LOADING,
  });
};

//--------------------------Customer  List from QB  Clear Data--------------------

export const CustomerListClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.CUSTOMER_LIST_CLEAR_DATA,
  });
};

//------------------------- Account Mapping --------------------

export const AccountMapping = (tokenFromFile, vendorName) => (dispatch) => {
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  const relmid = localStorage.getItem("tokenFromFile");
  const body = {
    name: vendorName,
    access_token: tokenFromFile,
    realm_id: relmid,
  };
  dispatch(AccountMappingLoading());
  Axios.post(`${base_url}/get_account_mapping`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.ACCOUNT_MAPPING_SUCCESS,
        payload: response.data,
      });
      dispatch(AccountsList(tokenFromFile));
    })
    .catch((error) => {
      dispatch({
        type: TYPES.ACCOUNT_MAPPING_FAIL,
      });
    });
};

//-------------------------- Account Mapping Loading --------------------

export const AccountMappingLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ACCOUNT_MAPPING_LOADING,
  });
};

//-------------------------- Account Mapping Clear Data--------------------

export const AccountMappingClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ACCOUNT_MAPPING_CLEAR_DATA,
  });
};

//-------------------------  Get VendorKey --------------------

export const GetVendorKey = () => (dispatch) => {
  dispatch(GetVendorKeyLoading());

  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  Axios.get(`${base_url}/get_vendor_keys`, config)
    .then((response) => {
      dispatch({
        type: TYPES.GET_VENDORKEY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.GET_VENDORKEY_FAIL,
      });
    });
};

//--------------------------  Get VendorKey Loading --------------------

export const GetVendorKeyLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_VENDORKEY_LOADING,
  });
};

//--------------------------  Get VendorKey Clear Data--------------------

export const GetVendorKeyClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_VENDORKEY_CLEAR_DATA,
  });
};

//-------------------------  Save VendorKey --------------------

export const SaveVendorKey =
  (
    tokenFromFile,
    vendor_name,
    inv_amount_key,
    inv_date_key,
    inv_number_key,
    net_terms_key,
    due_date_key,
    vat_amount_key,
    id
  ) =>
  (dispatch) => {
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const relmid = localStorage.getItem("realmId");
    const body = {
      access_token: tokenFromFile,
      realm_id: relmid,
      vendor_name: vendor_name,
      inv_amount_key: inv_amount_key,
      inv_date_key: inv_date_key,
      inv_number_key: inv_number_key,
      net_terms_key: net_terms_key,
      due_date_key: due_date_key,
      vat_amount_key: vat_amount_key,
      id: id,
    };
    dispatch(SaveVendorKeyLoading());
    Axios.post(`${base_url}/update_vendor_key`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.SAVE_VENDORKEY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.SAVE_VENDORKEY_FAIL,
        });
      });
  };

//--------------------------   Save VendorKey Loading --------------------

export const SaveVendorKeyLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_VENDORKEY_LOADING,
  });
};

//--------------------------   Save VendorKey Clear Data--------------------

export const SaveVendorKeyClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_VENDORKEY_CLEAR_DATA,
  });
};

//-------------------------- View Invoice Dialog --------------------

export const ViewInvoiceDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.VIEW_INVOICE_DIALOG,
    payload: status,
  });
};

//-------------------------Login --------------------

export const LoginUser = (email, password) => (dispatch) => {
  dispatch(LoginLoading());
  const body = {
    email: email,
    password: password,
  };

  Axios.post(`${auth_url}/auth/login`, body)
    .then((response) => {
      dispatch({
        type: TYPES.LOGIN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.LOGIN_FAIL,
      });
    });
};

//-------------------------Login Loading --------------------

export const LoginLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.LOGIN_LOADING,
  });
};

//-------------------------Login Clear Data--------------------
export const LoginClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.LOGIN_CLEAR_DATA,
  });
};

//-------------------------Register--------------------

export const Register = (name, email, password, role) => (dispatch) => {
  dispatch(RegisterLoading());
  const body = {
    name: name,
    email: email,
    password: password,
    role: role,
  };
  Axios.post(`${auth_url}/auth/signup`, body)
    .then((response) => {
      dispatch({
        type: TYPES.REGISTER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.REGISTER_FAIL,
        payload: error.response.data,
      });
    });
};

//-------------------------Register Loading --------------------

export const RegisterLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.REGISTER_LOADING,
  });
};

//-------------------------Register Clear Data--------------------
export const RegisterClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.REGISTER_CLEAR_DATA,
  });
};

//-------------------------OTP--------------------

export const OTPValidation = (id, otp) => (dispatch) => {
  dispatch(OTPLoading());
  const body = {
    id: id,
    otp: otp,
  };
  Axios.post(`${auth_url}/auth/verify_otp`, body)
    .then((response) => {
      dispatch({
        type: TYPES.OTP_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.OTP_FAIL,
        payload: error.response.data,
      });
    });
};

//-------------------------OTP Loading --------------------

export const OTPLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.OTP_LOADING,
  });
};

//-------------------------OTP Clear Data--------------------
export const OTPClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.OTP_CLEAR_DATA,
  });
};

//--------------------- Delete From Track Invoice --------------------

export const DeleteInvoice = (id) => (dispatch) => {
  dispatch(DeleteInvoiceLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  Axios.delete(`${base_url}/delete_accounts_payable/${id}`, config)
    .then((response) => {
      dispatch({
        type: TYPES.DELETE_INVOICE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.DELETE_INVOICE_FAIL,
      });
    });
};

//-------------------------Delete From Track Invoice Loading --------------------

export const DeleteInvoiceLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_INVOICE_LOADING,
  });
};

//-------------------------Delete From Track Invoice Clear Data--------------------

export const DeleteInvoiceClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_INVOICE_CLEAR_DATA,
  });
};

//-------------------------Delete From Track Invoice Dialog--------------------

export const DeleteInvoiceDialogView = (status) => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_INVOICE_DIALOG,
    payload: status,
  });
};

//--------------------- Delete From Accounting Software - Track Invoice --------------------

export const DeleteInvoiceFromAccountingSoftware =
  (tokenFromFile, type, id, organizationId, invoice_number) => (dispatch) => {
    dispatch(DeleteInvoiceFromAccountingSoftwareLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const relmid = localStorage.getItem("realmId");
    let data = null;
    data = new FormData();
    data.append("access_token", tokenFromFile);
    data.append("realm_id", relmid);
    data.append("invoice_id", id);
    data.append("organization_id", organizationId);
    data.append("invoice_number", invoice_number);

    Axios.post(`${base_url}/delete_accounts_payable/${type}`, data, config)
      .then((response) => {
        dispatch({
          type: TYPES.DELETE_QB_INVOICE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.DELETE_QB_INVOICE_FAIL,
        });
      });
  };

//-------------------------Delete From Accounting Software - Track Invoice -Loading --------------------

export const DeleteInvoiceFromAccountingSoftwareLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_QB_INVOICE_LOADING,
  });
};

//-------------------------Delete From Accounting Software - Track Invoice - Clear Data--------------------

export const DeleteInvoiceFromAccountingSoftwareClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_QB_INVOICE_CLEAR_DATA,
  });
};

//--------------------- Zoho Token --------------------

export const ZohoToken = () => (dispatch) => {
  dispatch(ZohoTokenLoading());
  Axios.get(`https://asvinth-backend-zoho-dev-eastus-staging.azurewebsites.net`)
    .then((response) => {
      dispatch({
        type: TYPES.ZOHO_TOKEN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.ZOHO_TOKEN_FAIL,
      });
    });
};

//-------------------------Zoho Token Loading --------------------

export const ZohoTokenLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ZOHO_TOKEN_LOADING,
  });
};

//-------------------------Zoho Token Clear Data--------------------

export const ZohoTokenClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.ZOHO_TOKEN_CLEAR_DATA,
  });
};

//--------------------------Delete Pending Invoice Dialog --------------------

export const DeletePendingInvoiceDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_PENDING_INVOICE_DIALOG,
    payload: status,
  });
};

//--------------------- Delete From Pending Invoice --------------------

export const DeletePendingInvoice = (id) => (dispatch) => {
  dispatch(DeletePendingInvoiceLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  Axios.delete(`${base_url}/delete_pending_invoice/${id}`, config)
    .then((response) => {
      dispatch({
        type: TYPES.DELETE_PENDING_INVOICE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.DELETE_PENDING_INVOICE_FAIL,
      });
    });
};

//-------------------------Delete From Pending Invoice Loading --------------------

export const DeletePendingInvoiceLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_PENDING_INVOICE_LOADING,
  });
};

//-------------------------Delete From Pending Invoice Clear Data--------------------

export const DeletePendingInvoiceClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_PENDING_INVOICE_CLEAR_DATA,
  });
};

//-------------------------Invoice Extraction View Dialog--------------------

export const InvoiceExtractionViewDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.INVOICE_EXTRACTION_VIEW_DIALOG,
    payload: status,
  });
};


//---------------------  Training  --------------------

export const Training = (bill_data,access_token,vendor_name,file_data,organizationId,tds_id) => (dispatch) => {
  dispatch(TrainingLoading());

  //----------------------------------------------
  const account_type = localStorage.getItem("account_type")
  const line_item = localStorage.getItem("line_item")

  //----------------------------------------------

  const relmid = localStorage.getItem("realmId");

  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  const body = {
    realm_id: relmid,
    bill_data: bill_data,
    account_type: account_type,
    line_item: line_item,
    access_token: access_token,
    vendor_name: vendor_name,
    file_data: file_data,
    organization_id:organizationId,
    tds_id:tds_id
  };


  Axios.post(`${base_url}/training`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.TRAINING_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.TRAINING_FAIL,
      });
    });
};

//-------------------------Training Loading --------------------

export const TrainingLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.TRAINING_LOADING,
  });
};

//-------------------------Training Clear Data--------------------

export const TrainingClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.TRAINING_CLEAR_DATA,
  });
};

//---------------------  Save Ap Data  --------------------

export const SaveApData = (bill_data,access_token,vendor_name,file_data,pip_id,organizationId,tds_id) => (dispatch) => {
  dispatch(SaveApDataLoading());

  //----------------------------------------------
  const account_type = localStorage.getItem("account_type")
  const line_item = localStorage.getItem("line_item")

  //----------------------------------------------

  const relmid = localStorage.getItem("realmId");

  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  const body = {
    realm_id: relmid,
    bill_data: bill_data,
    account_type: account_type,
    line_item: line_item,
    access_token: access_token,
    vendor_name: vendor_name,
    file_data: file_data,
    pip_id: pip_id,
    organization_id:organizationId,
    tds_id
  };


  Axios.post(`${base_url}/save-ap-details`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.SAVE_AP_DATA_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.SAVE_AP_DATA_FAIL,
      });
    });
};

//-------------------------Save Ap Data  Loading --------------------

export const SaveApDataLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_AP_DATA_LOADING,
  });
};

//-------------------------Save Ap Data  Data--------------------

export const SaveApDataClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_AP_DATA_CLEAR_DATA,
  });
};

//--------------------- TDX Tax --------------------

export const TDXTax = (access_token,organizationId) => (dispatch) => {
  dispatch(TDXTaxLoading());
  //Sending login token to ensure the data privacy
  const body = {
    access_token: access_token,
    organization_id: organizationId
  };

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  Axios.post(`${base_url}/tds-taxes`,body,config)
    .then((response) => {
      dispatch({
        type: TYPES.TDX_TAX_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.TDX_TAX_FAIL,
      });
    });
};

//-------------------------TDX Tax Loading --------------------

export const TDXTaxLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.TDX_TAX_LOADING,
  });
};

//-------------------------TDX Tax Clear Data--------------------

export const TDXTaxClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.TDX_TAX_CLEAR_DATA,
  });
};
