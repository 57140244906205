import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { ReactComponent as UploadIllustration } from "../../Assets/file-upload.svg";
import { Button } from "@mui/material";
import BackupIcon from "@mui/icons-material/Backup";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import {
  AR,
  ARDialog,
  ARInvoiceUpload,
  ARUploadDialog,
  FetchCustomerEmail,
  ARInvoiceUploadSingle,
  TaxSlab,
} from "../AccountReceivable/AccountReceivableActions/AccountReceivableActions";

import {
  CustomerList,
  AccountsList,
} from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";
import CircularProgress from "@mui/material/CircularProgress";

import moment from "moment";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Calculate } from "@mui/icons-material";
import SubmitDialog from "./SubmitDialog";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SubmitDialogUpload from "./SubmitDialogUpload";
import IconBreadcrumbs from "./IconBreadcrumbs";
import { SendEmailDialog } from "../AccountReceivable/AccountReceivableActions/AccountReceivableActions";
import database from "../../Assets/database.png";
import Backdrop from "@mui/material/Backdrop";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TrackInvoice from "./TrackInvoice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Index() {
  const dispatch = useDispatch();

  //Get Accounting Software

  const accountingSoftware = localStorage.getItem("AcoountingSoftware");

  //Get access token - Loading Status
  const fetchAccessTokenLoading = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenLoading
  );
  // Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );
  useEffect(() => {
    dispatch(
      CustomerList(
        fetchAccessTokenSucess.access_token,
        fetchAccessTokenSucess.org_id
      )
    );
    dispatch(AccountsList(fetchAccessTokenSucess.access_token));
    dispatch(
      TaxSlab(
        fetchAccessTokenSucess.access_token,
        fetchAccessTokenSucess.org_id
      )
    );
  }, []);

  //------------------------------------------------------------------------

  //QB Customer List
  const customerListSucess = useSelector(
    (state) => state.AccountsPayableReducers.customerListSucess
  );

  const [customerListUpdated, setCustomerListUpdated] = React.useState([]);
  const [currencyDataUpdated, setCurrencyDataUpdated] = React.useState([]);

  // multiCurrencyEnabledFlag === True -> Accounting software will accept different currency when user create a new vendor
  // multiCurrencyEnabledFlag === False -> Newly created vendor will be assigned with "homeCurrency" <- Which is provided from the accounting software

  const [multiCurrencyEnabledFlag, setMultiCurrencyEnabledFlag] =
    React.useState();

  //"homeCurrency" <- Which is provided from the accounting software
  const [homeCurrency, setHomeCurrency] = React.useState();
  const [userSelectedCurrency, setUserSelectedCurrency] = React.useState();
  const [addNewFlag, setAddNewFlag] = React.useState(false);
  const [addNewLineItemFlag, setNewLineItemFlag] = React.useState(false);

  useEffect(() => {
    updateCustomerList();
  }, [customerListSucess]);

  const updateCustomerList = () => {
    // setCustomerListUpdated(customerListSucess.customers);
    if(customerListSucess && customerListSucess.customers) {
      const customerNames = customerListSucess.customers.map(customer => customer.name);
      setCustomerListUpdated(customerNames);
    }
  
    setCurrencyDataUpdated(
      customerListSucess.currency_detail
        ? customerListSucess.currency_detail.currency_data  
        : []
    );
    setMultiCurrencyEnabledFlag(
      customerListSucess.currency_detail
        ? customerListSucess.currency_detail.multi_currency_enabled
        : ""
    );
    setHomeCurrency(
      customerListSucess.currency_detail
        ? customerListSucess.currency_detail.home_currency
        : ""
    );
  };

  //--------------------------------------------------------------------------
  const handleCurrencyChange = (e) => {
    setUserSelectedCurrency(e);
    handleUserInputs(invoiceCount, "cust_curr_code", e);
  };

  //-------------------------- List OF Account Type ---------------------

  const [accountType, setAccountType] = React.useState([]);

  const accountListSucess = useSelector(
    (state) => state.AccountsPayableReducers.accountListSucess
  );

  useEffect(() => {
    updateAccountList();
  }, [accountListSucess]);

  const updateAccountList = () => {
    setAccountType(accountListSucess.accounts_data);
  };

  //---------------------- Sort Alphabetically -  -------------------------

  function sortAlphabetically(arr) {
    return arr.sort(function (a, b) {
      return a.localeCompare(b);
    });
  }
  //---------------------------------------------------------------------

  const [lineitemArray, setLineitemArray] = React.useState([]);
  const [lineitemArrayFlag, setLineitemArrayFlag] = React.useState(false);
  const [lineitemArrayFlagForUpload, setLineitemArrayFlagForUpload] =
    React.useState(false);

  // Line Item Details
  const [inputFields, setInputFields] = React.useState([
    {
      id: uuidv4(),
      acc_type: "",
      acc_name: "",
      rate: 0,
      quantity: 0,
      total: 0,
      description: "",
    },
  ]);
  const handleAddNewitem = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        acc_type: "",
        acc_name: "",
        rate: 0,
        quantity: 0,
        total: 0,
        description: "",
      },
    ]);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        acc_type: "",
        acc_name: "",
        rate: 0,
        quantity: 0,
        total: 0,
        description: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id && event.target.name == "acc_type") {
        i[event.target.name] = event.target.value;
        const type = accountType.filter(
          (item) => item.acc_type == event.target.value
        );
        if (type.length > 0) {
          setLineitemArray(sortAlphabetically(type[0].acc_names));
        }
      }
      if (id === i.id && event.target.name == "description") {
        i[event.target.name] = event.target.value;
      }

      if (id === i.id && event.target.name == "acc_name") {
        if (event.target.value == "Add New Line Item") {
          setLineitemArrayFlag(true);
        } else {
          i[event.target.name] = event.target.value;
        }
      }

      if (id === i.id && event.target.name == "rate") {
        i[event.target.name] = Number(event.target.value);
        i["total"] = Number(event.target.value) * i["quantity"];
      }

      if (id === i.id && event.target.name == "quantity") {
        i[event.target.name] = Number(event.target.value);
        i["total"] = Number(event.target.value) * i["rate"];
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  //Dialog Status -> True : Open , False is close, On Upload Invoice Button Dialog Status will set True
  const arDialog = useSelector(
    (state) => state.AccountReceivableReducers.arDialog
  );

  const [invoiceDate, setInvoiceDate] = React.useState("");
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalAmountWithoutTax, settotalAmountWithoutTax] = React.useState(0);

  const [totalTAX, setTotalTAX] = React.useState(0);
  const [selectedTaxSlab, setSelectedTaxSlab] = React.useState();
  const [dueDate, setDueDate] = React.useState("");
  const [client, setClient] = React.useState("");

  //------------- List of Tax Slab From Accounting Software ----------------

  const taxSlabSucess = useSelector(
    (state) => state.AccountReceivableReducers.taxSlabSucess
  );

  //----------------- Handle TaxSlab Change --------------------------------

  const handletaxSlabChange = (e) => {
    setSelectedTaxSlab(e);
    if (e != 0) {
      var totalAmountValue = inputFields
        .map((item) => item.total)
        .reduce((prev, next) => prev + next);
      settotalAmountWithoutTax(totalAmountValue);
      var percentage = Number(e) / 100;
      var taxAmount = totalAmountValue * percentage;
      var newTotal = Number(totalAmountValue) + Number(taxAmount);
      setTotalAmount(newTotal);
      setTotalTAX(taxAmount);
    } else {
      var totalAmountValue = inputFields
        .map((item) => item.total)
        .reduce((prev, next) => prev + next);
      settotalAmountWithoutTax(totalAmountValue);
      setTotalAmount(totalAmountValue);
      setTotalTAX(0);
    }
  };

  //-------------------------------------------------------------------------

  const handleClientChange = (e) => {
    dispatch(FetchCustomerEmail(e, fetchAccessTokenSucess.access_token));
    if (e == "Add New Client") {
      setAddNewFlag(true);
      setClient("");
    } else {
      setClient(e);
    }
  };

  const format2 = "YYYY-MM-DD";

  const handleInvoiceDateChange = (newValue) => {
    if (newValue) {
      var tempDate = newValue.$d;
      var formattedDate = moment(tempDate).format(format2);
      setInvoiceDate(formattedDate);
    }
  };

  const handleDueDateChange = (newValue) => {
    if (newValue) {
      var tempDate = newValue.$d;
      var formattedDate = moment(tempDate).format(format2);
      setDueDate(formattedDate);
    }
  };

  //-------------- Calculate the total Invoice Amount --------------

  useEffect(() => {
    calculateTotalAmount();
  }, [inputFields]);

  const calculateTotalAmount = () => {
    if (selectedTaxSlab != 0 && selectedTaxSlab) {
      var totalAmountValue = inputFields
        .map((item) => item.total)
        .reduce((prev, next) => prev + next);

      settotalAmountWithoutTax(totalAmountValue);

      var percentage = Number(selectedTaxSlab) / 100;
      var taxAmount = totalAmountValue * percentage;
      var newTotal = Number(totalAmountValue) + Number(taxAmount);
      setTotalAmount(newTotal);
      setTotalTAX(taxAmount);
    } else {
      var totalAmountValue = inputFields
        .map((item) => item.total)
        .reduce((prev, next) => prev + next);
      if (isNaN(totalAmountValue)) {
        setTotalAmount(0);
        setTotalTAX(0);
      } else {
        setTotalAmount(totalAmountValue);
        setTotalTAX(0);
      }
    }
  };

  //-----------------------------------------------------------------

  // useEffect(() => {
  //   calculateTotalAmountWithTax();
  // }, [totalTAX]);

  // const calculateTotalAmountWithTax = () => {
  //   let amount  = Number(totalAmount) + Number(totalTAX)
  //   setTotalAmount(amount)
  // }

  //----------------------------------------------------------------

  const handleArUpload = () => {
    let user_currency = "";
    if (multiCurrencyEnabledFlag == false) {
      user_currency = homeCurrency;
    }

    if (multiCurrencyEnabledFlag == true && addNewFlag === true) {
      user_currency = userSelectedCurrency;
    }

    if (multiCurrencyEnabledFlag == true && addNewFlag === false) {
      user_currency = homeCurrency;
    }

    dispatch(ARDialog(!arDialog));
    dispatch(
      AR(
        client,
        invoiceDate,
        dueDate,
        totalAmountWithoutTax,
        fetchAccessTokenSucess.access_token,
        inputFields,
        user_currency,
        fetchAccessTokenSucess.org_id,
        selectedTaxSlab
      )
    );
  };

  //---------------------------------------------- AR Upload Invoice --------------------------------------------------------------------

  // Flag to keep the count of Batch Invoice

  const [invoiceCount, setInvoiceCount] = React.useState(1);
  // Handle Add New Invoice - Batch Upload of Invoice

  const handleAddNewInvoice = () => {
    setLineitemArrayFlagForUpload(false);
    setUserSelectedCurrency("");
    setCategory1("");
    setCategory2("");
    setClientUpload("");
    setInvoiceCount(invoiceCount + 1);
    setInputFieldsUpload([
      ...inputFieldsUpload,
      {
        id: invoiceCount + 1,
        client_name: "",
        cust_curr_code: "",
        file: "",
        category1: "",
        category2: "",
      },
    ]);
  };

  // Line Item Details
  const [inputFieldsUpload, setInputFieldsUpload] = React.useState([
    {
      id: 1,
      client_name: "",
      cust_curr_code: "",
      file: "",
      category1: "",
      category2: "",
    },
  ]);

  // Function to update Batch Upload Files - Object
  //=> If invoice Id = 1 => which means the first invoice details is entering ->
  // on click of add new invoice index count will increase
  // => seletedItem is the dropdown which user is selected

  const handleUserInputs = (invoiceIId, selectedItem, value) => {
    const newInputFields = inputFieldsUpload.map((i) => {
      if (invoiceIId === i.id) {
        i[selectedItem] = value;
      }
      return i;
    });

    setInputFieldsUpload(newInputFields);
  };

  //delete invoice

  const handleDeleteInvoice = (id, file) => {
    setClientUpload("");
    const values = [...inputFieldsUpload];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsUpload(values);

    //------- Delete corresponding invoice

    const invoiceData = [...invoice];
    const filteredItems = invoiceData.filter((item) => item.data.name != file);
    setInvoice(filteredItems);
  };

  //-------------------------------------------------------------------------------------------------
  useEffect(() => {
    updateArray();
  }, [inputFieldsUpload]);

  const updateArray = () => {
    if (inputFieldsUpload.length === 0) {
      setInputFieldsUpload([
        ...inputFieldsUpload,
        {
          id: invoiceCount + 1,
          client_name: "",
          cust_curr_code: "",
          file: "",
          category1: "",
          category2: "",
        },
      ]);
      setInvoiceCount(invoiceCount + 1);
    }
  };

  ///-----------------------------------------------------------------------------------------------------------------------------------

  //Dialog Status -> True : Open , False is close, On Upload Invoice Button Dialog Status will set True
  const arUploadDialog = useSelector(
    (state) => state.AccountReceivableReducers.arUploadDialog
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setUserSelectedCurrency("");
    setAddNewFlag(false);
  };
  const [invoice, setInvoice] = React.useState("");
  const [clientUpload, setClientUpload] = React.useState("");
  const [category1, setCategory1] = React.useState("");
  const [category2, setCategory2] = React.useState("");

  const handleCategoryOne = (e) => {
    setCategory1(e);
    handleUserInputs(invoiceCount, "category1", e);
    const type = accountType.filter((item) => item.acc_type == e);
    if (type.length > 0) {
      setLineitemArray(sortAlphabetically(type[0].acc_names));
    }
  };

  const handleCategoryTwo = (e) => {
    if (e == "Add New Line Item") {
      setLineitemArrayFlagForUpload(true);
      setCategory2("");
    } else {
      setCategory2(e);
      handleUserInputs(invoiceCount, "category2", e);
    }
  };

  const handleChangeClientInvoiceUpload = (e) => {
    if (e == "Add New Client") {
      setAddNewFlag(true);
      setClientUpload("");
    } else {
      setClientUpload(e);
      handleUserInputs(invoiceCount, "client_name", e);
      handleUserInputs(invoiceCount, "cust_curr_code", homeCurrency);
    }
  };
  const handleInvoiceFileInput = (e) => {
    const data = e.target.files[0];
    if (invoiceCount === 1) {
      setInvoice([
        {
          data,
        },
      ]);
    }
    if (invoiceCount > 1) {
      setInvoice([
        ...invoice,
        {
          data,
        },
      ]);
    }
    handleUserInputs(invoiceCount, "file", e.target.files[0].name);
  };

  useEffect(() => {
    checkForTheInvoieCount();
  }, [invoice]);

  const checkForTheInvoieCount = () => {
    if (invoice.length > inputFieldsUpload.length) {
      invoice.splice(invoice.length - 2, 1);
    }
  };

  const handleArUploadInvoice = () => {
    if (inputFieldsUpload.length === 1) {
      let user_currency = "";
      if (multiCurrencyEnabledFlag == false) {
        user_currency = homeCurrency;
      }

      if (multiCurrencyEnabledFlag == true && addNewFlag === true) {
        user_currency = userSelectedCurrency;
      }

      if (multiCurrencyEnabledFlag == true && addNewFlag === false) {
        user_currency = homeCurrency;
      }
      dispatch(ARUploadDialog(!arUploadDialog));
      dispatch(
        ARInvoiceUploadSingle(
          clientUpload,
          fetchAccessTokenSucess.access_token,
          invoice,
          user_currency,
          inputFieldsUpload,
          fetchAccessTokenSucess.org_id
        )
      );
    } else {
      dispatch(ARUploadDialog(!arUploadDialog));
      dispatch(
        ARInvoiceUpload(
          fetchAccessTokenSucess.access_token,
          invoice,
          inputFieldsUpload,
          fetchAccessTokenSucess.org_id
        )
      );
    }
  };

  //----------------------- Loader Display  -----------------------------------

  const customerListLoading = useSelector(
    (state) => state.AccountsPayableReducers.customerListLoading
  );

  const accountListLoading = useSelector(
    (state) => state.AccountsPayableReducers.accountListLoading
  );

  //------------------------- Save Details Button Validation - Invoice Inputs -----------------

  const [saveDetailsButtonFlag, setSaveDetailsButtonFlag] =
    React.useState(false);

  useEffect(() => {
    checkAllUserInputs();
  }, [client, invoiceDate, dueDate, totalAmount, selectedTaxSlab]);

  const checkAllUserInputs = () => {
    if (
      client &&
      invoiceDate &&
      dueDate &&
      totalAmount > 0 &&
      selectedTaxSlab
    ) {
      setSaveDetailsButtonFlag(true);
    }
  };

  //------------------------- Save Details Button Validation - Upload Invoice -----------------

  const [saveDetailsButtonUploadFlag, setSaveDetailsButtonUploadFlag] =
    React.useState(false);

  useEffect(() => {
    checkAllUserInputsforUpload();
  }, [clientUpload, invoice]);

  const checkAllUserInputsforUpload = () => {
    if (clientUpload && invoice) {
      setSaveDetailsButtonUploadFlag(true);
    }
  };

  //---------------------------------------------------------------------------------------------
  //Dialog Open Staus --> True or False
  const sendMailDialog = useSelector(
    (state) => state.AccountReceivableReducers.sendMailDialog
  );

  //UI Validation if API Failed - Should show Illustration

  const customerListFail = useSelector(
    (state) => state.AccountsPayableReducers.customerListFail
  );

  const accountListFail = useSelector(
    (state) => state.AccountsPayableReducers.accountListFail
  );

  //--single function that replaces underscores with spaces and capitalizes the first letter ---

  function replaceAndCapitalize(str) {
    return str.replace(/_/g, " ").replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 4, height: "80vh" }}>
        <Grid
          container
          spacing={0}
          sx={{
            boxShadow: " 0px 30px 60px rgb(39 39 71 / 20%)",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mt: 2,
            }}
          >
            <Typography
              sx={{
                p: 3,
                pb: 1,
                pt: 0,
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Account{" "}
              <span
                style={{ color: "#0093FB", fontFamily: "'Roboto', sans-serif" }}
              >
                Receivable
              </span>
            </Typography>
            <Divider sx={{ p: 2, pt: 0, ml: 2, mr: 2, pb: 0 }} />
            <Alert sx={{ ml: 2, mr: 2, mb: 0 }} severity="info">
              <Typography
                sx={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px" }}
              >
                Kindly fill out all fields to create an invoice
              </Typography>
            </Alert>
            {accountingSoftware == "skip" ? (
              <>
               <Box sx={{ pl: 3, pr: 3 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                       <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Track Invoice"
                        {...a11yProps(0)}
                      />
                      </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                        <Grid container>
                          <Grid item xs={12}>
                            <TrackInvoice />
                          </Grid>
                        </Grid>
                      </TabPanel>
                  </Box>
              </>
            ) : (
              <>
                <Box sx={{ pl: 3, pr: 3 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Invoice Inputs"
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Upload Invoice"
                        {...a11yProps(1)}
                      />
                      <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Track Invoice"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <Grid container>
                    <Grid item xs={12} sx={{ mt: 1, ml: 2 }}>
                      <IconBreadcrumbs />
                    </Grid>
                  </Grid>
                  {(customerListLoading || accountListLoading) && value <= 1 ? (
                    <>
                      <Container maxWidth="lg">
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item xs={12} md={4} sx={{ p: 1 }}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              sx={{ borderRadius: 2 }}
                              // width={335}
                              height={60}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} sx={{ p: 1 }}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              sx={{ borderRadius: 2 }}
                              // width={335}
                              height={60}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} sx={{ p: 1 }}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              sx={{ borderRadius: 2 }}
                              // width={335}
                              height={60}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ p: 1 }}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              sx={{ borderRadius: 2 }}
                              // width={1040}
                              height={280}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
                            <Container maxWidth="xs">
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{ borderRadius: 2 }}
                                width={350}
                                height={40}
                              />
                            </Container>
                          </Grid>
                        </Grid>
                      </Container>
                    </>
                  ) : (
                    <>
                      {customerListFail || accountListFail ? (
                        <>
                          <Grid container>
                            <Grid item xs={12} sx={{ pt: 3 }}>
                              {customerListFail ? (
                                <>
                                  <Alert severity="error">
                                    <Typography
                                      sx={{
                                        fontFamily: "'Roboto', sans-serif",
                                        fontSize: 14,
                                      }}
                                    >
                                      Sorry.. Failed to fetch the customer
                                      details from accounting software!
                                    </Typography>
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  <Alert severity="error">
                                    <Typography
                                      sx={{
                                        fontFamily: "'Roboto', sans-serif",
                                        fontSize: 14,
                                      }}
                                    >
                                      Sorry.. Failed to fetch the account
                                      details from accounting software!
                                    </Typography>
                                  </Alert>
                                </>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                pt: 8,
                                pb: 8,
                              }}
                            >
                              <img src={database} />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <TabPanel value={value} index={0}>
                            <>
                              <Container maxWidth="lg">
                                <Grid container sx={{ mt: 2 }}>
                                  {Object.keys(customerListSucess).length > 0 &&
                                  customerListUpdated &&
                                  addNewFlag === false ? (
                                    <>
                                      <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                        <TextField
                                          select
                                          fullWidth
                                          id="Client"
                                          label="Client Name"
                                          variant="outlined"
                                          value={client}
                                          onChange={(event) =>
                                            handleClientChange(
                                              event.target.value
                                            )
                                          }
                                        >
                                          {[
                                            ["Add New Client"],
                                            ...customerListUpdated,
                                          ].map((option, key) => (
                                            <MenuItem
                                              key={key}
                                              value={option}
                                              sx={{
                                                fontFamily:
                                                  "'Roboto', sans-serif",
                                                color:
                                                  key == 0 ? "#FF0000" : "",
                                                "&:hover": {
                                                  borderLeft:
                                                    "5px solid #008ffc",
                                                  borderRadius: 1,
                                                  fontWeight: 600,
                                                },
                                              }}
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                        <TextField
                                          fullWidth
                                          id="Client"
                                          label="Client Name"
                                          variant="outlined"
                                          value={client}
                                          helperText={
                                            customerListUpdated
                                              ? "Click for list of Clients"
                                              : ""
                                          }
                                          FormHelperTextProps={{
                                            onClick: () => setAddNewFlag(false),
                                          }}
                                          onChange={(event) =>
                                            handleClientChange(
                                              event.target.value
                                            )
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}

                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <DesktopDatePicker
                                          label="Invoice Date"
                                          inputFormat="MM/DD/YYYY"
                                          // maxDate={date}
                                          value={invoiceDate}
                                          onChange={handleInvoiceDateChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              error={false}
                                            />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <DesktopDatePicker
                                          label="Due Date"
                                          inputFormat="MM/DD/YYYY"
                                          // maxDate={date}
                                          value={dueDate}
                                          onChange={handleDueDateChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              error={false}
                                            />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </Grid>
                                  {addNewFlag === true &&
                                  multiCurrencyEnabledFlag == true ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        sx={{
                                          pl: { xs: 0, md: 1 },
                                          pr: { xs: 0, md: 1 },
                                        }}
                                      >
                                        <TextField
                                          fullWidth
                                          id="userselectedcurrency"
                                          select
                                          value={userSelectedCurrency}
                                          label="Please choose the currency"
                                          onChange={(event) =>
                                            handleCurrencyChange(
                                              event.target.value
                                            )
                                          }
                                        >
                                          {currencyDataUpdated.map(
                                            (option, key) => (
                                              <MenuItem
                                                key={key}
                                                value={option.code}
                                                sx={{
                                                  fontFamily:
                                                    "'Roboto', sans-serif",
                                                  "&:hover": {
                                                    borderLeft:
                                                      "5px solid #008ffc",
                                                    borderRadius: 1,
                                                    fontWeight: 600,
                                                  },
                                                }}
                                              >
                                                {option.name} {"-"}{" "}
                                                {option.code}
                                              </MenuItem>
                                            )
                                          )}
                                        </TextField>
                                      </Grid>
                                    </>
                                  ) : null}
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      border: "1px solid #C5C5C5",
                                      display: "flex",
                                      borderRadius: 2,
                                      flexDirection: "column",
                                      mt: 2,
                                      ml: 1,
                                      mr: 1,
                                      p: 2,
                                      mb: 5,
                                    }}
                                  >
                                    <Grid container spacing={1}>
                                      <Grid item xs={6} md={2}>
                                        <div
                                          style={{
                                            borderBottom: "2px solid #0093FB",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontFamily:
                                                "'Roboto', sans-serif",
                                            }}
                                          >
                                            Invoice Items
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        md={10}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={handleAddNewitem}
                                          endIcon={<AddIcon />}
                                          sx={{ textTransform: "capitalize" }}
                                        >
                                          Add New Item
                                        </Button>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Divider sx={{ mt: 1, mb: 1 }} />
                                      </Grid>
                                      {inputFields.map((inputField) => (
                                        <>
                                          <Grid
                                            container
                                            sx={{ mt: 1.5 }}
                                            spacing={1}
                                          >
                                            {accountType ? (
                                              <>
                                                <Grid item xs={12} md={2}>
                                                  <TextField
                                                    fullWidth
                                                    select
                                                    id="acc_type"
                                                    name="acc_type"
                                                    label="Category"
                                                    variant="outlined"
                                                    size="small"
                                                    value={inputField.acc_type}
                                                    onChange={(event) =>
                                                      handleChangeInput(
                                                        inputField.id,
                                                        event
                                                      )
                                                    }
                                                  >
                                                    {accountType.map(
                                                      (option, key) => (
                                                        <MenuItem
                                                          key={key}
                                                          value={
                                                            option.acc_type
                                                          }
                                                          sx={{
                                                            fontFamily:
                                                              "'Roboto', sans-serif",
                                                            "&:hover": {
                                                              borderLeft:
                                                                "5px solid #008ffc",
                                                              borderRadius: 1,
                                                            },
                                                          }}
                                                        >
                                                          {replaceAndCapitalize(
                                                            option.acc_type
                                                          )}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  </TextField>
                                                </Grid>
                                              </>
                                            ) : null}
                                            {lineitemArrayFlag === false ? (
                                              <>
                                                <Grid item xs={12} md={2}>
                                                  <TextField
                                                    fullWidth
                                                    select
                                                    id="acc_name"
                                                    name="acc_name"
                                                    label="Line Item"
                                                    variant="outlined"
                                                    size="small"
                                                    disabled={
                                                      !inputField.acc_type
                                                    }
                                                    value={inputField.acc_name}
                                                    onChange={(event) =>
                                                      handleChangeInput(
                                                        inputField.id,
                                                        event
                                                      )
                                                    }
                                                  >
                                                    {[
                                                      ["Add New Line Item"],
                                                      ...lineitemArray,
                                                    ].map((option, key) => (
                                                      <MenuItem
                                                        sx={{
                                                          fontFamily:
                                                            "'Roboto', sans-serif",
                                                          color:
                                                            key == 0
                                                              ? "#FF0000"
                                                              : "",
                                                          "&:hover": {
                                                            borderLeft:
                                                              "5px solid #008ffc",
                                                            borderRadius: 1,
                                                            fontWeight: 600,
                                                          },
                                                        }}
                                                        key={key}
                                                        value={option}
                                                      >
                                                        {option}
                                                      </MenuItem>
                                                    ))}
                                                  </TextField>
                                                </Grid>
                                              </>
                                            ) : (
                                              <>
                                                <Grid item xs={12} md={2}>
                                                  <TextField
                                                    fullWidth
                                                    id="acc_name"
                                                    name="acc_name"
                                                    label="Line Item"
                                                    variant="outlined"
                                                    size="small"
                                                    helperText="Click for list of Line Items"
                                                    FormHelperTextProps={{
                                                      onClick: () =>
                                                        setLineitemArrayFlag(
                                                          false
                                                        ),
                                                    }}
                                                    disabled={
                                                      !inputField.acc_type
                                                    }
                                                    value={inputField.acc_name}
                                                    onChange={(event) =>
                                                      handleChangeInput(
                                                        inputField.id,
                                                        event
                                                      )
                                                    }
                                                  ></TextField>
                                                </Grid>
                                              </>
                                            )}

                                            <Grid item xs={12} md={2}>
                                              <TextField
                                                fullWidth
                                                id="description"
                                                name="description"
                                                label="Description"
                                                InputProps={{
                                                  inputProps: { min: 0 },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                value={inputField.description}
                                                onChange={(event) =>
                                                  handleChangeInput(
                                                    inputField.id,
                                                    event
                                                  )
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                              <TextField
                                                fullWidth
                                                number
                                                id="rate"
                                                name="rate"
                                                label="Rate per Item"
                                                InputProps={{
                                                  inputProps: { min: 0 },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                value={inputField.rate}
                                                onChange={(event) =>
                                                  handleChangeInput(
                                                    inputField.id,
                                                    event
                                                  )
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                              <TextField
                                                fullWidth
                                                number
                                                id="quantity"
                                                name="quantity"
                                                label="Quantity"
                                                InputProps={{
                                                  inputProps: { min: 0 },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                value={inputField.quantity}
                                                onChange={(event) =>
                                                  handleChangeInput(
                                                    inputField.id,
                                                    event
                                                  )
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                              <TextField
                                                fullWidth
                                                number
                                                id="total"
                                                name="total"
                                                label="Total"
                                                inputProps={{ readOnly: true }}
                                                variant="outlined"
                                                size="small"
                                                value={inputField.total}
                                                // onChange={(event) =>
                                                //   handleCategoryChange(event.target.value)
                                                // }
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              md={1}
                                              sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip title="Add New Item">
                                                <IconButton
                                                  aria-label="delete"
                                                  onClick={handleAddFields}
                                                  style={{ color: "#008000" }}
                                                >
                                                  <AddIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              md={1}
                                              sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Tooltip title="Delete Row">
                                                <IconButton
                                                  disabled={
                                                    Object.keys(inputFields)
                                                      .length == 1
                                                  }
                                                  aria-label="delete"
                                                  onClick={() =>
                                                    handleRemoveFields(
                                                      inputField.id
                                                    )
                                                  }
                                                  style={{ color: "#ff0000" }}
                                                >
                                                  <DeleteIcon
                                                    sx={{
                                                      cursor:
                                                        Object.keys(inputFields)
                                                          .length == 1
                                                          ? "not-allowed"
                                                          : "",
                                                    }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </>
                                      ))}
                                      <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Divider />
                                      </Grid>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={12}
                                          md={3}
                                          sx={{ pt: 2 }}
                                        >
                                          <TextField
                                            select
                                            sx={{ ml: 1 }}
                                            fullWidth
                                            id="taxslab"
                                            name="taxslab"
                                            label="Select Tax Slab %"
                                            variant="outlined"
                                            size="small"
                                            value={selectedTaxSlab}
                                            onChange={(event) =>
                                              handletaxSlabChange(
                                                event.target.value
                                              )
                                            }
                                          >
                                            {taxSlabSucess.map(
                                              (option, key) => (
                                                <MenuItem
                                                  key={key}
                                                  value={option}
                                                  sx={{
                                                    fontFamily:
                                                      "'Roboto', sans-serif",
                                                    "&:hover": {
                                                      borderLeft:
                                                        "5px solid #008ffc",
                                                      borderRadius: 1,
                                                    },
                                                  }}
                                                >
                                                  {option}
                                                </MenuItem>
                                              )
                                            )}
                                          </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={3}></Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          md={6}
                                          sx={{ pt: 2 }}
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                              <TextField
                                                sx={{ ml: 1 }}
                                                label="TAX/VAT"
                                                id="tax"
                                                name="tax"
                                                fullWidth
                                                type="number"
                                                value={totalTAX}
                                                // onChange={(e) => setTotalTAX(e.target.value) }
                                                // onChange={(e) =>
                                                //   handleTaxChange(e.target.value)
                                                // }
                                                size="small"
                                              ></TextField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                              <TextField
                                                sx={{ ml: 1 }}
                                                label="Total Amount"
                                                value={totalAmount}
                                                fullWidth
                                                size="small"
                                              ></TextField>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sx={{ pt: 1 }}>
                                    <Container maxWidth="xs">
                                      <Button
                                        disabled={!saveDetailsButtonFlag}
                                        fullWidth
                                        sx={{
                                          textTransform: "capitalize",
                                          padding: 1,
                                          background: "#0093FB",
                                          fontFamily: "'Roboto', sans-serif",
                                          "&:hover": {
                                            background: "#0093FB",
                                          },
                                        }}
                                        variant="contained"
                                        onClick={handleArUpload}
                                      >
                                        Save Details
                                      </Button>
                                    </Container>
                                  </Grid>
                                </Grid>
                              </Container>
                            </>
                          </TabPanel>
                        </>
                      )}
                      {customerListLoading === false &&
                      accountListLoading === false &&
                      !customerListFail &&
                      !accountListFail ? (
                        <>
                          <TabPanel value={value} index={1}>
                            <>
                              <Container maxWidth="lg">
                                <Grid container sx={{ mt: 2 }} spacing={1}>
                                  {Object.keys(customerListSucess).length > 0 &&
                                  customerListUpdated &&
                                  addNewFlag === false ? (
                                    <>
                                      <Grid item xs={12} md={3}>
                                        <TextField
                                          select
                                          fullWidth
                                          id="Client"
                                          label="Client Name"
                                          variant="outlined"
                                          value={clientUpload}
                                          onChange={(event) =>
                                            handleChangeClientInvoiceUpload(
                                              event.target.value
                                            )
                                          }
                                        >
                                          {[
                                            ["Add New Client"],
                                            ...customerListUpdated,
                                          ].map((option, key) => (
                                            <MenuItem
                                              key={key}
                                              value={option}
                                              sx={{
                                                fontFamily:
                                                  "'Roboto', sans-serif",
                                                color:
                                                  key == 0 ? "#FF0000" : "",
                                                "&:hover": {
                                                  borderLeft:
                                                    "5px solid #008ffc",
                                                  borderRadius: 1,
                                                  fontWeight: 600,
                                                },
                                              }}
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid item xs={12} md={3}>
                                        <TextField
                                          fullWidth
                                          id="Client"
                                          label="Client Name"
                                          variant="outlined"
                                          value={clientUpload}
                                          helperText={
                                            customerListUpdated
                                              ? "Click for list of Clients"
                                              : ""
                                          }
                                          FormHelperTextProps={{
                                            onClick: () => setAddNewFlag(false),
                                          }}
                                          onChange={(event) =>
                                            handleChangeClientInvoiceUpload(
                                              event.target.value
                                            )
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}
                                  {accountType ? (
                                    <>
                                      <Grid item xs={12} md={3}>
                                        <TextField
                                          fullWidth
                                          select
                                          id="acc_type"
                                          name="acc_type"
                                          label="Category"
                                          variant="outlined"
                                          value={category1}
                                          onChange={(event) =>
                                            handleCategoryOne(
                                              event.target.value
                                            )
                                          }
                                        >
                                          {accountType.map((option, key) => (
                                            <MenuItem
                                              key={key}
                                              value={option.acc_type}
                                              sx={{
                                                fontFamily:
                                                  "'Roboto', sans-serif",
                                                "&:hover": {
                                                  borderLeft:
                                                    "5px solid #008ffc",
                                                  borderRadius: 1,
                                                },
                                              }}
                                            >
                                              {replaceAndCapitalize(
                                                option.acc_type
                                              )}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </>
                                  ) : null}
                                  {lineitemArrayFlagForUpload === false ? (
                                    <>
                                      <Grid item xs={12} md={3}>
                                        <TextField
                                          fullWidth
                                          select
                                          id="acc_name"
                                          name="acc_name"
                                          label="Line Item"
                                          variant="outlined"
                                          disabled={!category1}
                                          value={category2}
                                          onChange={(event) =>
                                            handleCategoryTwo(
                                              event.target.value
                                            )
                                          }
                                        >
                                          {[
                                            ["Add New Line Item"],
                                            ...lineitemArray,
                                          ].map((option, key) => (
                                            <MenuItem
                                              sx={{
                                                fontFamily:
                                                  "'Roboto', sans-serif",
                                                color:
                                                  key == 0 ? "#FF0000" : "",
                                                "&:hover": {
                                                  borderLeft:
                                                    "5px solid #008ffc",
                                                  borderRadius: 1,
                                                  fontWeight: 600,
                                                },
                                              }}
                                              key={key}
                                              value={option}
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid item xs={12} md={3}>
                                        <TextField
                                          fullWidth
                                          id="acc_name"
                                          name="acc_name"
                                          label="Line Item"
                                          variant="outlined"
                                          helperText="Click for list of Line Items"
                                          FormHelperTextProps={{
                                            onClick: () =>
                                              setLineitemArrayFlagForUpload(
                                                false
                                              ),
                                          }}
                                          disabled={!category1}
                                          value={category2}
                                          onChange={(event) =>
                                            handleCategoryTwo(
                                              event.target.value
                                            )
                                          }
                                        ></TextField>
                                      </Grid>
                                    </>
                                  )}

                                  {addNewFlag === true &&
                                  multiCurrencyEnabledFlag == true ? (
                                    <>
                                      <Grid
                                        item
                                        sx={{ pl: { xs: 0, md: 2 } }}
                                        xs={12}
                                        md={3}
                                      >
                                        <TextField
                                          fullWidth
                                          id="userselectedcurrency"
                                          select
                                          value={userSelectedCurrency}
                                          label="Please choose the currency"
                                          onChange={(event) =>
                                            handleCurrencyChange(
                                              event.target.value
                                            )
                                          }
                                        >
                                          {currencyDataUpdated.map(
                                            (option, key) => (
                                              <MenuItem
                                                key={key}
                                                value={option.code}
                                                sx={{
                                                  fontFamily:
                                                    "'Roboto', sans-serif",
                                                  "&:hover": {
                                                    borderLeft:
                                                      "5px solid #008ffc",
                                                    borderRadius: 1,
                                                    fontWeight: 600,
                                                  },
                                                }}
                                              >
                                                {option.name} {"-"}{" "}
                                                {option.code}
                                              </MenuItem>
                                            )
                                          )}
                                        </TextField>
                                      </Grid>
                                    </>
                                  ) : null}
                                  <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    sx={{
                                      pl: { xs: 0, md: 2 },
                                      mt: { xs: 1.5, md: 0 },
                                    }}
                                  >
                                    <Button
                                      onClick={handleAddNewInvoice}
                                      startIcon={<AddIcon />}
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        textTransform: "capitalize",
                                        height: 55,
                                      }}
                                    >
                                      Add New Invoice
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      border: "1px solid #C5C5C5",
                                      display: "flex",
                                      borderRadius: 2,
                                      flexDirection: "column",
                                      mt: 2,
                                      ml: 1,
                                      // mr: 1,
                                      // p: 2,
                                      mb: 2,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <UploadIllustration />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "30px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name="invoice"
                                        multiple
                                        onChange={handleInvoiceFileInput}
                                      ></input>
                                    </div>
                                  </Grid>

                                  {invoiceCount > 1 ? (
                                    <>
                                      <Grid item xs={12}>
                                        <Typography
                                          sx={{
                                            textAlign: "center",
                                            fontWeight: 600,
                                            pt: 1,
                                            pb: 1,
                                          }}
                                        >
                                          {" "}
                                          Invoice Details
                                        </Typography>
                                      </Grid>
                                      <TableContainer>
                                        <Table
                                          sx={{ minWidth: 650 }}
                                          aria-label="simple table"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell
                                                sx={{
                                                  fontFamily:
                                                    "'Roboto', sans-serif",
                                                  fontWeight: 600,
                                                }}
                                                align="center"
                                              >
                                                #
                                              </TableCell>
                                              <TableCell
                                                sx={{
                                                  fontFamily:
                                                    "'Roboto', sans-serif",
                                                  fontWeight: 600,
                                                }}
                                                align="center"
                                              >
                                                Client Name
                                              </TableCell>
                                              <TableCell
                                                sx={{
                                                  fontFamily:
                                                    "'Roboto', sans-serif",
                                                  fontWeight: 600,
                                                }}
                                                align="center"
                                              >
                                                Invoice File
                                              </TableCell>
                                              <TableCell
                                                sx={{
                                                  fontFamily:
                                                    "'Roboto', sans-serif",
                                                  fontWeight: 600,
                                                }}
                                                align="center"
                                              >
                                                Action
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <>
                                            <TableBody>
                                              {inputFieldsUpload.map(
                                                (row, index) => (
                                                  <TableRow
                                                    key={index}
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        { border: 0 },
                                                    }}
                                                  >
                                                    <TableCell align="center">
                                                      {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {row.client_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {row.file}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      <Tooltip title="Delete Invoice">
                                                        <IconButton
                                                          onClick={() =>
                                                            handleDeleteInvoice(
                                                              row.id,
                                                              row.file
                                                            )
                                                          }
                                                          edge="start"
                                                          color="inherit"
                                                          aria-label="menu"
                                                          sx={{
                                                            ml: 0,
                                                            color: "#FF0000",
                                                          }}
                                                        >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </>
                                        </Table>
                                      </TableContainer>
                                      <Divider />
                                    </>
                                  ) : null}
                                  <Grid item xs={12} sx={{ pt: 1 }}>
                                    <Container maxWidth="xs">
                                      <Button
                                        disabled={!saveDetailsButtonUploadFlag}
                                        fullWidth
                                        sx={{
                                          textTransform: "capitalize",
                                          padding: 1,
                                          background: "#0093FB",
                                          fontFamily: "'Roboto', sans-serif",
                                          "&:hover": {
                                            background: "#0093FB",
                                          },
                                        }}
                                        variant="contained"
                                        onClick={handleArUploadInvoice}
                                      >
                                        Save Details
                                      </Button>
                                    </Container>
                                  </Grid>
                                </Grid>
                              </Container>
                            </>
                          </TabPanel>
                        </>
                      ) : null}
                      <TabPanel value={value} index={2}>
                        <Grid container>
                          <Grid item xs={12}>
                            <TrackInvoice />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fetchAccessTokenLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {arDialog ? <SubmitDialog /> : null}
      {arUploadDialog ? <SubmitDialogUpload /> : null}
    </>
  );
}

export default Index;
