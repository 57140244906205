import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import {
  PendingInvoiceList,
  DeletePendingInvoiceDialog,
} from "../AccountsPayableActions/AccountsPayableActions";
import { useSelector, useDispatch } from "react-redux";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";
import Alert from "@mui/material/Alert";

export default function DeletePendinginvoice(props) {
  const dispatch = useDispatch();
  //---------------------------------------------------------------------------

  // Dialog Status

  const deletePendingInvoiceDialog = useSelector(
    (state) => state.AccountsPayableReducers.deletePendingInvoiceDialog
  );

  const deletePendingInvoiceLoading = useSelector(
    (state) => state.AccountsPayableReducers.deletePendingInvoiceLoading
  );

  const deletePendingInvoiceSuccess = useSelector(
    (state) => state.AccountsPayableReducers.deletePendingInvoiceSuccess
  );

  const deletePendingInvoiceFail = useSelector(
    (state) => state.AccountsPayableReducers.deletePendingInvoiceFail
  );

  const handleClose = () => {
    dispatch(DeletePendingInvoiceDialog(!deletePendingInvoiceDialog))
    dispatch(PendingInvoiceList());
  };

  return (
    <div>
      <Dialog
        open={deletePendingInvoiceDialog}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Delete Invoice</DialogTitle>
        <DialogContent>
          {deletePendingInvoiceLoading ? (
            <>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <CircularProgressWithLabel />
                </div>
              </Grid>
            </>
          ) : null}

          {deletePendingInvoiceSuccess ? (
            <>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Alert severity="success">
                  <Typography>Invoice deleted successfully.</Typography>
                </Alert>
              </Grid>
            </>
          ) : null}
          {deletePendingInvoiceFail ? (
            <>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Alert severity="error">
                  <Typography>
                    Sorry! Failed to delete invoice.
                  </Typography>
                </Alert>
              </Grid>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
