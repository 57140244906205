import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import { BudgetDialog } from "../FinancialReporting/FinancialReportingActions/FinancialReportingActions";
import Grid from "@mui/material/Grid";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadDialog() {
  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  //Dialog Status -> True : Open , False is close, On Upload Invoice Button Dialog Status will set True
  const budgetDialog = useSelector(
    (state) => state.FinancialReportingReducers.budgetDialog
  );

  const budgetUploadLoading = useSelector(
    (state) => state.FinancialReportingReducers.budgetUploadLoading
  );
  const budgetUploadSucessStatus = useSelector(
    (state) => state.FinancialReportingReducers.budgetUploadSucessStatus
  );
  const budgetUploadFail = useSelector(
    (state) => state.FinancialReportingReducers.budgetUploadFail
  );

  const handleClose = () => {
    dispatch(BudgetDialog(!budgetDialog));
  };

  const role = localStorage.getItem("UserType");

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={budgetDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontFamily: "'Roboto', sans-serif",
            borderBottom: "1px solid #008ffc",
            p: 2,
            fontWeight: 600,
            fontSize: 17,
          }}
        >
          <Typography
            sx={{
              p: 0,
              pb: 0,
              pt: 0,
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            Upload{" "}
            <span
              style={{
                color: "#0093FB",
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              Budget
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText
            sx={{
              fontFamily: "'Roboto', sans-serif",
              fontWeight: 600,
              fontSize: "15px",
            }}
            id="alert-dialog-slide-description"
          >
            Budget data will be updated in Power BI reports.
          </DialogContentText>
        </DialogContent>
        {budgetUploadLoading ? (
          <DialogContent sx={{ mt: 3 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgressWithLabel />
            </div>
          </DialogContent>
        ) : null}

        {budgetUploadSucessStatus ? (
          <DialogContent sx={{ mt: -2 }}>
            {role == "finance controller" ? (
              <>
                <Alert severity="success">
                  <span
                    style={{
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Your budget has been successfully updated!
                  </span>
                </Alert>
              </>
            ) : (
              <>
                <Alert severity="success">
                  <span
                    style={{
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Your budget has been successfully uploaded and is currently
                    under review!
                  </span>
                </Alert>
              </>
            )}
          </DialogContent>
        ) : null}
        {budgetUploadFail ? (
          <DialogContent sx={{ mt: -2 }}>
            <Alert severity="error">
              <span
                style={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px" }}
              >
                Sorry! Failed to process the request.
              </span>
            </Alert>
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button
            sx={{ fontFamily: "'Roboto', sans-serif" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
