import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AP from "../../Assets/AP.jpg";
import PL from "../../Assets/8749.jpg";
import AR from "../../Assets/AR.jpg";
import { useDispatch, useSelector } from "react-redux";
import { UserMenuSelection } from "../Dashboard/DashboardActions/DashboardActions";
import { useLocation } from "react-router-dom";

function Intro() {
  const dispatch = useDispatch();

  const location = useLocation();
  const routeName = location.pathname;

  console.log("routeName", routeName);

  const handleAccountsPayable = () => {
    if (routeName != "/reports") {
      dispatch(UserMenuSelection("AccountsPayable"));
    }
  };

  const handleAccountReceivable = () => {
    if (routeName != "/reports") {
      dispatch(UserMenuSelection("AccountReceivable"));
    }
  };

  const handleFinancialReporting = () => {
    dispatch(UserMenuSelection("FinancialReporting"));
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 0 }}>
      <Grid container sx={{ mt: 10 }}>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={10}>
          <Typography
            sx={{ textAlign: "center", fontWeight: 900, fontSize: 24 }}
          >
            Explore our range of pre-designed software solutions that can
          </Typography>
          <Typography
            sx={{ textAlign: "center", fontWeight: 900, fontSize: 24 }}
          >
            streamline and automate various financial processes for your
            convenience
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>
      <Grid container spacing={2} sx={{ pt: 10 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            onClick={handleAccountsPayable}
            sx={{
              maxWidth: 345,
              cursor: "pointer",
              transition: "transform 0.6s ease-in-out",
              "&:hover": {
                transform: "translateY(-10px)",
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image={AP}  />
            <CardContent>
              <Typography
                sx={{ fontWeight: 900, fontSize: 19, color: "#0093FB" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                Account Payable
              </Typography>
              <Typography
                sx={{ fontWeight: 600, fontSize: 16 }}
                variant="body2"
                color="text.secondary"
              >
                AP Automation is simply uploading and processing supplier
                invoices automatically with a minimum of manual tasks.
              </Typography>
            </CardContent>
            <CardActions sx={{ mt: 3 }}>
              <Button sx={{ textTransform: "capitalize" }} size="small">
                View More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            onClick={handleAccountReceivable}
            sx={{
              maxWidth: 345,
              cursor: "pointer",
              transition: "transform 0.6s ease-in-out",
              "&:hover": {
                transform: "translateY(-10px)",
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image={AR}  />
            <CardContent>
              <Typography
                sx={{ fontWeight: 900, fontSize: 19, color: "#0093FB" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                Account Receivable
              </Typography>
              <Typography
                sx={{ fontWeight: 600, fontSize: 16 }}
                variant="body2"
                color="text.secondary"
              >
                AR Automation is, simply put, a way of automating repetitive and
                time-consuming tasks for your accounts receivable team.
              </Typography>
            </CardContent>
            <CardActions sx={{ mt: 3 }}>
              <Button sx={{ textTransform: "capitalize" }} size="small">
                View More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            onClick={handleFinancialReporting}
            sx={{
              maxWidth: 345,
              cursor: "pointer",
              transition: "transform 0.6s ease-in-out",
              "&:hover": {
                transform: "translateY(-10px)",
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image={PL}  />
            <CardContent>
              <Typography
                sx={{ fontWeight: 900, fontSize: 19, color: "#0093FB" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                Financial Reporting
              </Typography>
              <Typography
                sx={{ fontWeight: 600, fontSize: 16 }}
                variant="body2"
                color="text.secondary"
              >
                Financial reporting is the comprehensive review of monthly,
                quarterly, or yearly financial data to drive better business
                performance and results.
              </Typography>
            </CardContent>
            <CardActions>
              <Button sx={{ textTransform: "capitalize" }} size="small">
                View More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Intro;
