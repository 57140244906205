import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import {
  SendEmailDialog,
  SendEmailData,
  ARDialog,
} from "../AccountReceivable/AccountReceivableActions/AccountReceivableActions";
import Grid from "@mui/material/Grid";
import { TextField, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";

export default function SendEmail() {
  const dispatch = useDispatch();

   // Access Token
   const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  //Dialog Open Staus --> True or False
  const sendMailDialog = useSelector(
    (state) => state.AccountReceivableReducers.sendMailDialog
  );

  //Dialog Status -> True : Open , False is close, On Upload Invoice Button Dialog Status will set True
  const arDialog = useSelector(
    (state) => state.AccountReceivableReducers.arDialog
  );

  //Open === True will enable input field to add Cc and Bcc
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    dispatch(SendEmailDialog(!sendMailDialog));
    dispatch(ARDialog(!arDialog));
  };

  //Customer Email

  const fetchCustomerEmailSucess = useSelector(
    (state) => state.AccountReceivableReducers.fetchCustomerEmailSucess
  );

  console.log("fetchCustomerEmailSucess", fetchCustomerEmailSucess);

  //User Inputs -------------------------------------------------------------------

  const [from, setFrom] = React.useState("asvinth@corestrat.ai");
  const [to, setTo] = React.useState(
    fetchCustomerEmailSucess ? fetchCustomerEmailSucess.email_address : ""
  );
  const [cc, setCCC] = React.useState("");
  const [bcc, setBCC] = React.useState("");
  const [subject, setSubject] = React.useState("QuickBooks Invoice");
  const [emailBody, setEmailBody] = React.useState(
    "Dear Customer, We appreciate your business. Please find your invoice details here. Feel free to contact us if you have any questions.Have a great day! Corestrat Decision Solutions"
  );
  const [sentCopyFlag, setSentCopyFlag] = React.useState(false);

  const handleFrom = (e) => {
    setFrom(e);
  };
  const handleTo = (e) => {
    setTo(e);
  };
  const handleCCC = (e) => {
    setCCC(e);
  };
  const handleBCC = (e) => {
    setBCC(e);
  };
  const handleSubject = (e) => {
    setSubject(e);
  };
  const handleEmailBody = (e) => {
    setEmailBody(e);
  };

  const handleFlag = () => {
    setSentCopyFlag(!sentCopyFlag);
  };

  //PDF Creation Status

  const fetchInvoicePDFSucess = useSelector(
    (state) => state.AccountReceivableReducers.fetchInvoicePDFSucess
  );

  console.log("fetchInvoicePDFSucess", fetchInvoicePDFSucess);

  const handleSend = () => {
    dispatch(
      SendEmailData(
        from,
        to,
        cc,
        bcc,
        subject,
        emailBody,
        sentCopyFlag,
        fetchInvoicePDFSucess,
        fetchAccessTokenSucess.access_token
      )
    );
  };

  //
  const sendMailLoading = useSelector(
    (state) => state.AccountReceivableReducers.sendMailLoading
  );

  const sendMailSucessStatus = useSelector(
    (state) => state.AccountReceivableReducers.sendMailSucessStatus
  );

  const sendMailFail = useSelector(
    (state) => state.AccountReceivableReducers.sendMailFail
  );

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={sendMailDialog}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            fontWeight: 600,
            fontFamily: "'Roboto', sans-serif",
            color:"#0093FB"
          }}
        >
          Send Invoice
        </DialogTitle>
        <Grid container sx={{ pl: 3, pr: 3 }}>
          {/* <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              From
            </Typography>
          </Grid> */}
          {/* <Grid item xs={12} sx={{ pt: 1 }}>
            <TextField
              id="form"
              name="form"
              size="small"
              fullWidth
              value={from}
              onChange={(e) => handleFrom(e.target.value)}
            />
          </Grid> */}
          <Grid
            item
            xs={12}
            sx={{ pt: 1.5, display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              To
            </Typography>
            <Typography
              onClick={handleClick}
              sx={{
                fontSize: 14,
                color: "#0077c5",
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
                cursor: "pointer",
              }}
            >
              Cc/Bcc
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1 }}>
            <TextField
              size="small"
              placeholder="Separate multiple emails with commas"
              fullWidth
              id="to"
              name="to"
              value={to}
              onChange={(e) => handleTo(e.target.value)}
            />
          </Grid>
          {/* <Grid item xs={12} sx={{ pt: 1, display: "flex", pl: -1 }}>
            <Checkbox
              onClick={handleFlag}
              value={sentCopyFlag}
              checked={sentCopyFlag}
            />
            <Typography sx={{ pt: 1.7, fontSize: 12 }}>
              Send me a copy
            </Typography>
          </Grid> */}
          {open ? (
            <>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  Cc
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ pt: 1 }}>
                <TextField
                  size="small"
                  fullWidth
                  id="cc"
                  name="cc"
                  value={cc}
                  onChange={(e) => handleCCC(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sx={{ pt: 1 }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  Bcc
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ pt: 1 }}>
                <TextField
                  size="small"
                  fullWidth
                  id="bcc"
                  name="bcc"
                  value={bcc}
                  onChange={(e) => handleBCC(e.target.value)}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} sx={{ pt: 1 }}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Subject
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1 }}>
            <TextField
              size="small"
              fullWidth
              id="subject"
              name="subject"
              value={subject}
              onChange={(e) => handleSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, pb: 1,display:"flex" }}>
            <AttachFileIcon />
            <Typography
              sx={{
                fontSize: 14,
                pt:0.3,
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Invoice PDF
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1 }}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Email Body
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1 }}>
            <TextField
              size="small"
              fullWidth
              id="emailBody"
              multiline
              rows={4}
              name="emailBody"
              value={emailBody}
              onChange={(e) => handleEmailBody(e.target.value)}
            />
            {/* <textarea
              fullWidth
              id="w3review"
              name="w3review"
              rows="6"
              cols="93"
            >
              Dear Adwin Ko,
              This is the second line. We appreciate yourbusiness. Please find your invoice details here.
              Feel free to contact us if you have any questions. 
              Have a great day,
              Sandbox Company_GB_3
            </textarea> */}
          </Grid>
          <Grid item xs={12}>
            {sendMailLoading ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ mt: 3, display: "flex", justifyContent: "center" }}
                >
                  <CircularProgressWithLabel />
                </Grid>
              </Grid>
            ) : null}
            {sendMailSucessStatus ? (
              <Grid container>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Alert severity="success">
                    <Typography
                      sx={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Email sent successfully!
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            ) : null}
            {sendMailFail ? (
              <Grid container>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Alert severity="error">
                    <Typography
                      sx={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Sorry! Email generation failed.
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <DialogActions>
          <Grid container sx={{ pl: 2, pr: 2, pt: 2, pb: 2 }}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                sx={{ textTransform: "capitalize"}}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                sx={{ textTransform: "capitalize",pl:4,pr:4 }}
                variant="contained"
                onClick={handleSend}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
