import TYPES from "../FinancialReportingTypes/FinancialReportingTypes";
import Axios from "axios";
// import { base_url } from "../../../constants";
const accountingSoftareType = localStorage.getItem("AcoountingSoftware");
var base_url = "";

if (accountingSoftareType == "qb") {
  base_url =
    "https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net";
}

if (accountingSoftareType == "zoho") {
  base_url = "https://asvinth-backend-zoho-dev-eastus-staging.azurewebsites.net";
}

if (accountingSoftareType == "xero") {
  base_url = "https://asvinth-backend-xero-dev-eastus.azurewebsites.net";
}

//------------------------- Budget Dialog--------------------

export const BudgetDialog = (status) => (dispatch) => {
  // alert(status)
  dispatch({
    type: TYPES.BUGET_DIALOG,
    payload: status,
  });
};

//------------------------- Budget Upload --------------------

export const BudgetUpload = (excel, token, organizationId) => (dispatch) => {
  dispatch(BudgetUploadLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const role = localStorage.getItem("UserType");
  const relmid = localStorage.getItem("realmId");
  let data = null;
  data = new FormData();
  data.append("budget_file", excel);
  data.append("access_token", token);
  data.append("realm_id", relmid);
  data.append("role", role);
  data.append("name", "profit loss");
  data.append("organization_id", organizationId);

  Axios.post(`${base_url}/upload_budget`, data)
    .then((response) => {
      dispatch({
        type: TYPES.BUDGET_UPLOAD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.BUDGET_UPLOAD_FAIL,
      });
    });
};

//-------------------------Budge Upload Loading --------------------

export const BudgetUploadLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_UPLOAD_LOADING,
  });
};

//------------------------- Budget Upload  Clean Data--------------------

export const BudgetUploadClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_UPLOAD_CLEAR_DATA,
  });
};

//------------------------- Sync Actuals --------------------

export const SyncActuals = (token, organizationId) => (dispatch) => {
  dispatch(SyncActualsLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const relmid = localStorage.getItem("realmId");
  const body = {
    access_token: token,
    realm_id: relmid,
    organization_id: organizationId,
  };

  Axios.post(`${base_url}/sync_txns`,body)
    .then((response) => {
      dispatch({
        type: TYPES.SYNC_ACTUALS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.SYNC_ACTUALS_FAIL,
      });
    });
};

//-------------------------Sync Actuals Loading --------------------

export const SyncActualsLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.SYNC_ACTUALS_LOADING,
  });
};

//------------------------- Sync Actualsd  Clean Data--------------------

export const SyncActualsClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.SYNC_ACTUALS_CLEAR_DATA,
  });
};

//------------------------- budget_approval_count --------------------

export const BudgetApprovalCount = () => (dispatch) => {
  dispatch(BudgetApprovalCountLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  Axios.get(`${base_url}/budget_approval_count`, config)
    .then((response) => {
      dispatch({
        type: TYPES.BUDGET_APPROVAL_COUNT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.BUDGET_APPROVAL_COUNT_FAIL,
      });
    });
};

//-------------------------budget_approval_count Loading --------------------

export const BudgetApprovalCountLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_APPROVAL_COUNT_LOADING,
  });
};

//------------------------- budget_approval_count  Clean Data--------------------

export const BudgetApprovalCountClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_APPROVAL_COUNT_CLEAR_DATA,
  });
};

//------------------------- budget_approval--------------------

export const BudgetApproval = (id) => (dispatch) => {
  dispatch(BudgetApprovalLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const body = {
    id: id,
  };
  Axios.post(`${base_url}/budget_approval`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.BUDGET_APPROVAL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.BUDGET_APPROVAL_FAIL,
      });
    });
};

//-------------------------budget_approval Loading --------------------

export const BudgetApprovalLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_APPROVAL_LOADING,
  });
};

//------------------------- budget_approval  Clean Data--------------------

export const BudgetApprovalClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_APPROVAL_CLEAR_DATA,
  });
};

//------------------------- budget_approval Dialog--------------------

export const BudgetApprovalDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_APPROVAL_DIALOG,
    payload: status,
  });
};

//------------------------- budget_Decline Dialog--------------------

export const BudgetDeclineDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_DECLINE_DIALOG,
    payload: status,
  });
};

//------------------------- budget_decline --------------------

export const BudgetDecline = (id) => (dispatch) => {
  dispatch(BudgetDeclineLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const body = {
    id: id,
  };
  Axios.post(`${base_url}/budget_reject`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.BUDGET_DECLINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.BUDGET_DECLINE_FAIL,
      });
    });
};

//-------------------------budget_decline Loading --------------------

export const BudgetDeclineLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_DECLINE_LOADING,
  });
};

//------------------------- budget_decline  Clean Data--------------------

export const BudgetDeclineClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.BUDGET_DECLINE_CLEAR_DATA,
  });
};
