import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import CorestartLogo from "../../Assets/CorestartLogo.png";
import { Divider, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import MailIcon from "@mui/icons-material/Mail";
import DialpadIcon from "@mui/icons-material/Dialpad";
import { OTPValidation } from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

function OTPView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    navigate("/");
  };


  //OTP Validation
  const UserEmail = localStorage.getItem("UserEmail");
  const UserName = localStorage.getItem("Name");
  const UserID = localStorage.getItem("Id");
  const [otp, setOtp] = React.useState();
  const [otpFlag, setOtpFalg] = React.useState(false);

  const handleOTPChange = (event) => {
    setOtp(event.target.value);
  };

  const handleRegister = () => {
    dispatch(OTPValidation(UserID, otp));
    setSnackBarOpen(true);
  };

  //Snackbar Message Logic

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setSnackBarOpen(false);
  };

  //API Response
  const otpLoading = useSelector(
    (state) => state.AccountsPayableReducers.otpLoading
  );
  const otpStatus = useSelector(
    (state) => state.AccountsPayableReducers.otpStatus
  );
  const otpSucess = useSelector(
    (state) => state.AccountsPayableReducers.otpSucess
  );

  const otpFail = useSelector((state) => state.AccountsPayableReducers.otpFail);
  console.log("otpFail", otpFail);

  useEffect(() => {
    reportAccess();
  }, [otpSucess]);

  const reportAccess = () => {
    if (otpSucess) {
      if (
        otpSucess.message == "Mail sent to admin for activation." ||
        otpSucess.message ==
          "Email authenticated. Ask admin to activate account."
      ) {
        navigate("/reportaccess");
      }
    }
  };

  console.log("otpSucess", otpSucess);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ m: { xs: 3, md: 6, lg: 13 }, mt: { xs: 7 }, mb: { lg: 5 } }}
        >
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <img
              src={CorestartLogo}
              width="200"
              height="33"
              style={{ cursor: "pointer" }}
              onClick={handleLogoClick}
              alt="Corestart Logo"
            />
          </motion.div>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            backgroundColor: "#e5f6fd",
            borderRadius: "5px",
            m: { xs: 3, md: 6, lg: 25 },
            mt: { lg: 0 },
            mb: { lg: 1 },
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.7 }}
          >
            <Typography
              sx={{ textAlign: "center", mt: 5, fontWeight: 900, fontSize: 20 }}
            >
              Finance Automation Platform - OTP Verification
            </Typography>
            <Divider sx={{ ml: 8, mr: 8, mt: 2 }} />
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  mt: 4,
                  ml: { xs: 4, lg: 34 },
                  mr: { xs: 4, lg: 34 },
                }}
              >
                <Typography sx={{ fontWeight: 900, mb: 2 }}>
                  Email Address
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-email"
                    type={"email"}
                    value={UserEmail}
                    readOnly // Add the readOnly prop to make it a read-only field
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <MailIcon style={{ color: "#000000" }} />
                        </IconButton>
                      </InputAdornment>
                    }
                    style={{ background: "white" }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: 4,
                  ml: { xs: 4, lg: 34 },
                  mr: { xs: 4, lg: 34 },
                }}
              >
                <Typography sx={{ fontWeight: 900, mb: 2 }}>OTP</Typography>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-otp"
                    type={"number"}
                    value={otp}
                    onChange={handleOTPChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton>
                          <DialpadIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    style={{ background: "white" }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: 4,
                  ml: { xs: 4, lg: 34 },
                  mr: { xs: 4, lg: 34 },
                  mb: 5,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleRegister}
                  endIcon={<DoneIcon />}
                  fullWidth
                  sx={{ textTransform: "capitalize", p: 1.8 }}
                >
                  Verify OTP
                </Button>
              </Grid>
            </Grid>
          </motion.div>
        </Grid>
      </Grid>
      {otpLoading ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackBarOpen}
            onClose={handleClose}
            message="Validating OTP..."
            key={vertical + horizontal}
            action={
              <React.Fragment>
                <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          ></Snackbar>
        </>
      ) : null}
      { Object.keys(otpFail).length ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackBarOpen}
            onClose={handleClose}
            message={Object.keys(otpFail).length ? otpFail.message : "Process failed."}
            key={vertical + horizontal}
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          ></Snackbar>
        </>
      ) : null}
    </>
  );
}

export default OTPView;
