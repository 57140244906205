import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";
import {
  PendingInvoiceList,
  TrackInvoiceList,
  DeleteInvoice,
  DeleteInvoiceDialogView,
  DeleteInvoiceFromAccountingSoftware,
} from "../AccountsPayableActions/AccountsPayableActions";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import noData from "../../../Assets/undraw_No_data_re_kwbl-removebg-preview.png";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import SortIcon from "@mui/icons-material/Sort";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteInvoiceDialog from "./DeleteInvoiceDialog";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export default function TrackInvoice() {
  const dispatch = useDispatch();

  //Pending Invoice List

  const accountingSoftware = localStorage.getItem("AcoountingSoftware")


  //

  useEffect(() => {
    dispatch(PendingInvoiceList());
    dispatch(TrackInvoiceList());
  }, []);

  //Pending Invoice List
  const pendingInvoiceListSucess = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceListSucess
  );

  //AP Invoice List
  const trackInvoiceSucess = useSelector(
    (state) => state.AccountsPayableReducers.trackInvoiceSucess
  );

  var listOfAPInvoice = [];

  //Track Invoice List -- Loading Status
  const trackInvoiceLoading = useSelector(
    (state) => state.AccountsPayableReducers.trackInvoiceLoading
  );

  var listOfAPInvoice = trackInvoiceSucess ? trackInvoiceSucess.ap_data : [];

  const [myArray, setMyArray] = useState([]);
  useEffect(() => {
    handleDataUpdate();
  }, [listOfAPInvoice]);

  const handleDataUpdate = () => {
    if (listOfAPInvoice) {
      let data = listOfAPInvoice.sort(
        (a, b) => new Date(b.UploadedDate) - new Date(a.UploadedDate)
      );
      setMyArray(data);
    }
  };
  //--------------------------------------------------------------------------------

  const handleSortVendor = () => {
    const sortedData = [...myArray].sort((a, b) =>
      a.VendorName.localeCompare(b.VendorName)
    );
    setMyArray(sortedData);
  };

  const handleSortDate = () => {
    const sortedData = [...myArray].sort((a, b) =>
      a.BillingDate.localeCompare(b.BillingDate)
    );
    setMyArray(sortedData);
  };
  const handleSortAmount = () => {
    const sortedData = [...myArray].sort(
      (a, b) => a.InvoiceTotal - b.InvoiceTotal
    );
    setMyArray(sortedData);
  };

  //
  //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  // Dialog Status
  const deleteInvoiceDialog = useSelector(
    (state) => state.AccountsPayableReducers.deleteInvoiceDialog
  );

  const [selectedIndex, setSelectedIndex] = React.useState();

  const handleDeleteInvoice = (id,InvoiceNumber) => {
    setSelectedIndex(id);
    dispatch(DeleteInvoiceDialogView(!deleteInvoiceDialog));
    dispatch(
      DeleteInvoiceFromAccountingSoftware(
        fetchAccessTokenSucess.access_token,
        accountingSoftware,
        id,
        fetchAccessTokenSucess.org_id,
        InvoiceNumber
      )
    );
  };

  const handleViewInvoice = (uri) => {
    window.open(uri);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 0 }}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sx={{
              mt: 0,
            }}
          >
            <Alert sx={{ ml: 0, mr: 0, mb: 0 }} severity="info">
              <Typography
                sx={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px" }}
              >
                {" "}
                List of invoices
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            {trackInvoiceLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <CircularProgressWithLabel />
              </div>
            ) : null}
          </Grid>
          {myArray && myArray.length ? (
            <Grid
              item
              xs={12}
              sx={{
                ml: 0,
                mr: 0,
                mt: 2,
                mb: 2,
                border: "1px solid #C5C5C5",
                borderRadius: 2,
              }}
            >
              <TableContainer sx={{ height: "65vh" }}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        #
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Vendor Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Category
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Line item
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Invoice Number
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Tax
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Billing Date
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Net Terms
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Uploaded Date
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Uploaded By
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <>
                    <TableBody>
                      {myArray.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row.VendorName}</TableCell>
                          <TableCell align="center">{row.Category1}</TableCell>
                          <TableCell align="center">{row.Category2}</TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.InvoiceNumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.InvoiceTotal}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.tax_amount}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.BillingDate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.NetTerms}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {moment(row.UploadedDate).format("L")}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.UploadedBy}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            <Tooltip title="Download Invoice">
                              <IconButton
                                disabled={!row.FileURL}
                                onClick={() => handleViewInvoice(row.FileURL)}
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ ml: 0, color: "#0093FB" }}
                              >
                                <ArrowDownwardIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Invoice">
                              <IconButton
                                disabled={accountingSoftware == "skip"}
                                onClick={() => handleDeleteInvoice(row.Id,row.InvoiceNumber)}
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ ml: 0, color: "#FF0000" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img src={noData} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      {deleteInvoiceDialog ? (
        <DeleteInvoiceDialog index={selectedIndex} />
      ) : null}
    </>
  );
}
