import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";
import {
  PendingInvoiceListInAccountingSoftware,
  PendingInvoiceUploadInAccountingSoftware,
  PendingInvoiceUpload,
  InvoiceApproveDialog,
  TrackInvoiceList,
} from "../AccountsPayableActions/AccountsPayableActions";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import EditInvoice from "./EditInvoice";
import noData from "../../../Assets/undraw_No_data_re_kwbl-removebg-preview.png";
import IconBreadcrumbsPendingInvoice from "./IconBreadcrumbsPendingInvoice";
import ApproveDialog from "./ApproveDialog";

export default function PendingInvoiceInAccountingSoftware() {
  const dispatch = useDispatch();

  //Pending Invoice List
  useEffect(() => {
    dispatch(PendingInvoiceListInAccountingSoftware());
    dispatch(TrackInvoiceList());
  }, []);

  //Pending Invoice List
  const pendingInvoiceListInAccountingSoftwareSucess = useSelector(
    (state) =>
      state.AccountsPayableReducers.pendingInvoiceListInAccountingSoftwareSucess
  );

  useEffect(() => {
    handlePendingInvoice();
  }, [pendingInvoiceListInAccountingSoftwareSucess]);

  var listOfPendingInvoice = [];

  const handlePendingInvoice = () => {
    // var listOfPendingInvoice = pendingInvoiceListSucess
    //   ? pendingInvoiceListSucess.all_pen_invs
    //   : [];
  };

  //Pending Invoice List --Loading Status
  const pendingInvoiceListInAccountingSoftwareLoading = useSelector(
    (state) =>
      state.AccountsPayableReducers
        .pendingInvoiceListInAccountingSoftwareLoading
  );

  var listOfPendingInvoice = pendingInvoiceListInAccountingSoftwareSucess
    ? pendingInvoiceListInAccountingSoftwareSucess.all_pen_invs
    : [];

  //--------------------------------------------------------------------------------

    // //Access Token
    const fetchAccessTokenSucess = useSelector(
      (state) => state.LandingPageReducers.fetchAccessTokenSucess
    );
  
    var accessToken = fetchAccessTokenSucess
      ? fetchAccessTokenSucess.access_token
      : "";
  
    useEffect(() => {
      updateAccessToken();
    }, [fetchAccessTokenSucess]);
  
    const updateAccessToken = () => {
      localStorage.setItem(
        "accessToken-upload",
        fetchAccessTokenSucess.access_token
      );
    };

  //Pending Invoice List -- Loading Status
  const invoiceApproveDialog = useSelector(
    (state) => state.AccountsPayableReducers.invoiceApproveDialog
  );

  const handleEdit = (
    Id
  ) => {
    dispatch(
      PendingInvoiceUploadInAccountingSoftware(
        fetchAccessTokenSucess.access_token,
        Id
      )
    );
    dispatch(InvoiceApproveDialog(!invoiceApproveDialog));
  };

  //------------------------------------------------------------------------------
  var listOfAPInvoice = [];

  //AP Invoice List
  const trackInvoiceSucess = useSelector(
    (state) => state.AccountsPayableReducers.trackInvoiceSucess
  );

  //Track Invoice List -- Loading Status
  const trackInvoiceLoading = useSelector(
    (state) => state.AccountsPayableReducers.trackInvoiceLoading
  );

  var listOfAPInvoice = trackInvoiceSucess
    ? trackInvoiceSucess.pending_at_acc_soft
    : [];

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {trackInvoiceLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <CircularProgressWithLabel />
              </div>
            ) : null}
          </Grid>
          {listOfAPInvoice && listOfAPInvoice.length ? (
            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid #C5C5C5",
                borderRadius: 2,
              }}
            >
              <TableContainer>
                <Table sx={{ minWidth: 850 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        #
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Vendor Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Category
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Line item
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Invoice Number
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Invoice Amount
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Billing Date
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Net Terms
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Uploaded By
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <>
                    <TableBody>
                      {listOfAPInvoice.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row.VendorName}</TableCell>
                          <TableCell align="center">{row.Category1}</TableCell>
                          <TableCell align="center">{row.Category2}</TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.InvoiceNumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.InvoiceTotal}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.BillingDate}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.NetTerms}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontFamily: "'Roboto', sans-serif",
                            }}
                          >
                            {row.UploadedBy}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() =>
                                handleEdit(
                                  row.Id
                                )
                              }
                              sx={{
                                textTransform: "capitalize",
                                fontFamily: "'Roboto', sans-serif",
                                backgroundColor: "#50C878",
                                color: "#ffffff",
                                "&:hover": {
                                  backgroundColor: "#50C878",
                                  color: "#ffffff",
                                },
                              }}
                            >
                              Approve
                            </Button>
                            {/* <Button
                            sx={{
                              ml: 1,
                              textTransform: "capitalize",
                              backgroundColor: "#4CBB17",
                              color: "#ffffff",
                              "&:hover": {
                                backgroundColor: "#4CBB17",
                                color: "#ffffff",
                              },
                            }}
                          >
                            Approve
                          </Button> */}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img src={noData} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      {invoiceApproveDialog ? <ApproveDialog /> : null}
    </>
  );
}
