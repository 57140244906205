import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import {
  InvoiceEditDialog,
  PendingInvoiceUpload,
  PendingInvoiceUploadClearData,
  PendingInvoiceList,
} from "../AccountsPayableActions/AccountsPayableActions";
import Typography from "@mui/material/Typography";
import { Divider, Grid, TextField } from "@mui/material";
import moment from "moment";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";

export default function EditInvoice(props) {
  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const selectedIndex = props.index;

  useEffect(() => {
    dispatch(PendingInvoiceUploadClearData());
  }, []);

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  //Pending Invoice List -- Loading Status
  const invoiceEditDialog = useSelector(
    (state) => state.AccountsPayableReducers.invoiceEditDialog
  );
  const handleClose = () => {
    dispatch(InvoiceEditDialog(!invoiceEditDialog));
    dispatch(PendingInvoiceList());
  };

  const pendingInvoiceListSucess = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceListSucess
  );

  var listOfPendingInvoice = pendingInvoiceListSucess
    ? pendingInvoiceListSucess.all_pen_invs
    : [];

  var selectedArray = listOfPendingInvoice[selectedIndex];

  useEffect(() => {
    handleDateValidation();
  }, [selectedArray]);

  const handleDateValidation = () => {
    const dateStr = listOfPendingInvoice[selectedIndex].BillingDate;

    console.log("dateStr", dateStr);
    const dateObj = new Date(dateStr);
    const formattedDate = dateObj
      .toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-");

    console.log("formattedDate", formattedDate);
    setSelectedDate(formattedDate);
  };
  //-------------------------------------------------------------------------------

  const [invoiceNumber, setInvoiceNumber] = React.useState(
    listOfPendingInvoice[selectedIndex].InvoiceNumber
  );
  const [invoiceAmount, setInvoiceAmount] = React.useState(
    listOfPendingInvoice[selectedIndex].InvoiceTotal
  );
  const [billingDate, setBillingDate] = React.useState(
    listOfPendingInvoice[selectedIndex].BillingDate
  );

  const handleChnageUserInputs =(e) => {
    setBillingDate(e)
  }

  const [selectedDate, setSelectedDate] = React.useState();

  console.log("selectedDate", selectedDate);

  //-------------------------------------------------------------------------------

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  //-------------------------------------------------------------------------------

  const [netTrerms, setNetTrerms] = React.useState(
    listOfPendingInvoice[selectedIndex].NetTerms
  );

  const [taxAmount, setTaxAmount] = React.useState(
    listOfPendingInvoice[selectedIndex].tax_amount
  );
  console.log("taxAmount", taxAmount);
  console.log("typeof", typeof taxAmount);

  const handleInvoiceNumber = (e) => {
    setInvoiceNumber(e);
  };
  const handleInvoiceAmount = (e) => {
    setInvoiceAmount(e);
  };
  const handleBillingDate = (newValue) => {
    const dateStr = newValue;
    const dateObj = new Date(dateStr);
    const formattedDate = dateObj
      .toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-");
    setSelectedDate(formattedDate);
  };
  const handleNetTrerms = (e) => {
    setNetTrerms(e);
  };

  const handleTaxAmount = (e) => {
    setTaxAmount(e);
  };

  // //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  var accessToken = fetchAccessTokenSucess
    ? fetchAccessTokenSucess.access_token
    : "";

  useEffect(() => {
    updateAccessToken();
  }, [fetchAccessTokenSucess]);

  const updateAccessToken = () => {
    localStorage.setItem(
      "accessToken-upload",
      fetchAccessTokenSucess.access_token
    );
  };

  const handleApproveInvoice = () => {
    var finaldate
    var date = moment(selectedDate);
    var formattedDate = date.format("DD/MM/YYYY");
    if (billingDate) {
      finaldate = billingDate
    }
    else
    {
      finaldate =formattedDate
    }
    dispatch(
      PendingInvoiceUpload(
        selectedArray.VendorName,
        selectedArray.Category1,
        selectedArray.Category2,
        invoiceNumber,
        invoiceAmount,
        finaldate,
        netTrerms,
        fetchAccessTokenSucess.access_token,
        selectedArray.Id,
        taxAmount,
        fetchAccessTokenSucess.org_id
      )
    );
  };

  //Upload Pending Invoice Loading
  const pendingInvoiceUploadLoading = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceUploadLoading
  );

  //Upload Pending Invoice Success
  const pendingInvoiceUploadSucessStatus = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceUploadSucessStatus
  );

  //Upload Pending Invoice Success -Message
  const pendingInvoiceUploadSucess = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceUploadSucess
  );

  //Upload Pending Invoice Fail
  const pendingInvoiceUploadFail = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceUploadFail
  );

  //--------------------------- Validation for Approve Button -----------------------

  const [approveButtonEnable, setApproveButtonEnable] = React.useState(false);

  useEffect(() => {
    checkAlluSerInput();
  }, [invoiceNumber, invoiceAmount, selectedDate, netTrerms, taxAmount,billingDate]);

  const checkAlluSerInput = () => {
    if (
      invoiceNumber &&
      invoiceAmount &&
      selectedDate &&
      netTrerms &&
      Number(taxAmount) !== ""
    ) {
      setApproveButtonEnable(true);
    } else {
      setApproveButtonEnable(false);
    }

    if(!selectedDate || selectedDate == "Invalid Date") {
      if(!billingDate) {
        setApproveButtonEnable(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={invoiceEditDialog}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography
            sx={{
              p: 0,
              pt: 1,
              fontSize: "16px",
              fontWeight: 600,
              fontSize: 18,
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            Edit{" "}
            <span
              style={{ color: "#0093FB", fontFamily: "'Roboto', sans-serif" }}
            >
              Invoice
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{ border: "1px solid #C5C5C5", borderRadius: 2, p: 2, mt: 1 }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{ fontFamily: "'Roboto', sans-serif", fontWeight: 600 }}
              >
                <span
                  style={{
                    color: "#0093FB",
                    fontFamily: "'Roboto', sans-serif",
                    fontWeight: 600,
                  }}
                >
                  #
                </span>{" "}
                Basic Details
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Vendor Name"
                fullWidth
                value={selectedArray.VendorName}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Category"
                fullWidth
                value={selectedArray.Category1}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Line Item"
                fullWidth
                value={selectedArray.Category2}
              ></TextField>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography
                sx={{ fontFamily: "'Roboto', sans-serif", fontWeight: 600 }}
              >
                <span
                  style={{
                    color: "#0093FB",
                    fontFamily: "'Roboto', sans-serif",
                    fontWeight: 600,
                  }}
                >
                  #
                </span>{" "}
                Invoice Details
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                name="invoicenumber"
                id="invoicenumber"
                label="Invoice Number"
                fullWidth
                error={!invoiceNumber}
                value={invoiceNumber}
                onChange={(e) => handleInvoiceNumber(e.target.value)}
                helperText={!invoiceNumber ? "Data Missing" : ""}
              ></TextField>
            </Grid>

            {listOfPendingInvoice[selectedIndex].BillingDate ? (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    name="date"
                    id="date"
                    fullWidth
                    label="Billing Date"
                    onChange={(e) => handleChnageUserInputs(e.target.value)}
                    value={billingDate}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={3}>
                  <form noValidate>
                    <TextField
                      id="date"
                      error={!selectedDate || selectedDate == "Invalid Date"}
                      label="Billing Date"
                      fullWidth
                      type="date"
                      value={selectedDate}
                      helperText={
                        !selectedDate || selectedDate == "Invalid Date"
                          ? "Data Missing"
                          : ""
                      }
                      onChange={(e) => handleDateChange(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={2} sx={{ mb: 3 }}>
              <TextField
                label="Net Terms"
                name="netterms"
                id="netterms"
                type="number"
                fullWidth
                error={!netTrerms}
                onChange={(e) => handleNetTrerms(e.target.value)}
                value={netTrerms}
                helperText={!netTrerms ? "Data Missing" : ""}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                type="text"
                label="Invoice Total"
                fullWidth
                name="invoicetotal"
                format="dd/mm/yyyy"
                id="invoicetotal"
                error={!invoiceAmount}
                value={invoiceAmount}
                onChange={(e) => handleInvoiceAmount(e.target.value)}
                helperText={!invoiceAmount ? "Data Missing" : ""}
              ></TextField>
            </Grid>

            <Grid item xs={12} md={2} sx={{ mb: 3 }}>
              <TextField
                label="Tax Amount"
                name="taxAmount"
                id="taxAmount"
                type="number"
                fullWidth
                error={Number(taxAmount) === ""}
                onChange={(e) => handleTaxAmount(e.target.value)}
                value={taxAmount}
                helperText={Number(taxAmount) === "" ? "Data Missing" : ""}
              ></TextField>
            </Grid>
            {pendingInvoiceUploadLoading ? (
              <>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <CircularProgressWithLabel />
                  </div>
                </Grid>
              </>
            ) : null}
            {pendingInvoiceUploadSucessStatus ? (
              <>
                <Grid item xs={12}>
                  <Alert severity="info">
                    {pendingInvoiceUploadSucess.message}
                  </Alert>
                </Grid>
              </>
            ) : null}
            {pendingInvoiceUploadFail ? (
              <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Alert severity="error">
                    Invoice Upload failed! Please try again
                  </Alert>
                </Grid>
              </>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mr: 2 }}>
          <Button
            sx={{
              textTransform: "capitalize",
              fontFamily: "'Roboto', sans-serif",
            }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            disabled={!approveButtonEnable || pendingInvoiceUploadSucessStatus}
            sx={{
              ml: 1,
              textTransform: "capitalize",
              backgroundColor: "#4CBB17",
              fontFamily: "'Roboto', sans-serif",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#4CBB17",
                color: "#ffffff",
              },
            }}
            onClick={handleApproveInvoice}
          >
            Approve Invoice
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
