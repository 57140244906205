import TYPES from "../DashboardTypes/DashboardTypes";
import Axios from "axios";
// import { base_url } from "../../../constants";
import { redirect_url } from "../../../constants";

const accountingSoftareType = localStorage.getItem("AcoountingSoftware");
var base_url = "";

if (accountingSoftareType == "qb") {
  base_url =
    "https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net";
}

if (accountingSoftareType == "zoho") {
  base_url = "https://asvinth-backend-zoho-dev-eastus-staging.azurewebsites.net";
}

if (accountingSoftareType == "xero") {
  base_url = "https://asvinth-backend-xero-dev-eastus.azurewebsites.net";
}

//------------------------- User Menu Selection --------------------

export const UserMenuSelection = (selectedItem) => (dispatch) => {
  dispatch({
    type: TYPES.SELECTED_MENU_ITEM,
    payload: selectedItem,
  });
};

//-------------------------  Get User Role--------------------

export const GetUserRole = (tokenFromFile, organizationId) => (dispatch) => {
  dispatch(GetUserRoleLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  let data = null;
  data = new FormData();
  data.append("access_token", tokenFromFile);
  data.append("organization_id", organizationId);

  Axios.post(`${base_url}/get_user_role`, data, config)
    .then((response) => {
      dispatch({
        type: TYPES.GET_USER_ROLE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.GET_USER_ROLE_FAIL,
      });
    });
};

//--------------------------Get User Role Loading --------------------

export const GetUserRoleLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_USER_ROLE_LOADING,
  });
};

//--------------------------Get User Role Clear Data--------------------

export const GetUserRoleClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_USER_ROLE_CLEAR_DATA,
  });
};
