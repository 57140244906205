import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import CorestartLogo from "../../Assets/CorestartLogo.png";
import { Divider, Typography } from "@mui/material";
import { LoginUser } from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailIcon from "@mui/icons-material/Mail";
import { Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";

function LoginView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Clear All Redux Values

  useEffect(() => {
    handleClear();
  }, []);

  const handleClear = () => {
    localStorage.removeItem("UserEmail");
    localStorage.removeItem("Id");
    localStorage.removeItem("Name");
  };

  //Email - User Input

  const [email, setEmail] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  //Password - User Inputs

  const [password, setPassword] = React.useState();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  //Hide the password

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  //New User

  const handleNewAccount = () => {
    navigate("/register");
  };

  //Logo Click

  const handleLogoClick = () => {
    navigate("/");
  };

  //Login API Response

  const loginLoading = useSelector(
    (state) => state.AccountsPayableReducers.loginLoading
  );

  const loginStatus = useSelector(
    (state) => state.AccountsPayableReducers.loginStatus
  );

  console.log("loginStatus", loginStatus);

  const loginFail = useSelector(
    (state) => state.AccountsPayableReducers.loginFail
  );

  // useEffect(() => {
  //   handleUserInfo();
  // }, [loginStatus]);

  // const handleUserInfo = () => {
  //   if(loginStatus.length) {
  //     let data = loginStatus.data;
  //     console.log("data",data)
  //     let role = data.role
  //     console.log("role",role)
  //     localStorage.setItem("userRoleFromLogin",role)
  //   }
  // }

  //Snackbar Message Logic

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setSnackBarOpen(false);
  };

  //Sign In Button Click

  const handleLogin = () => {
    dispatch(LoginUser(email, password));
    setSnackBarOpen(true);
  };

  //Handle Success User Login
  useEffect(() => {
    handleLoginSuccess();
  }, [loginStatus]);

  const handleLoginSuccess = () => {
    if (loginStatus) {
      if (loginStatus.message == "User logged in.") {
        let data = loginStatus.data;
        let role = data.role;
        let access_token_login = data.access_token;
        localStorage.setItem("accessTokenLogin", access_token_login);
        localStorage.setItem("userRoleFromLogin", role);
        navigate("/accountingsoftware");
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ m: { xs: 3, md: 6, lg: 13 }, mt: { xs: 7 }, mb: { lg: 5 } }}
        >
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <img
              src={CorestartLogo}
              width="200"
              height="33"
              style={{ cursor: "pointer" }}
              onClick={handleLogoClick}
              alt="Corestart Logo"
            />
          </motion.div>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#e5f6fd",
            borderRadius: "5px",
            m: { xs: 3, md: 6, lg: 25 },
            mt: { lg: 0 },
            mb: { lg: 1 },
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 5,
                    fontWeight: 900,
                    fontSize: 20,
                  }}
                >
                  Finance Automation Platform - Sign In
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{ ml: { xs: 4, lg: 30 }, mr: { xs: 4, lg: 30 }, mt: 2 }}
                />
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={5} sx={{ ml: 2, mr: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontFamily: "'Roboto', sans-serif",
                      mt: 4,
                      mb: 1.5,
                    }}
                  >
                    Email Address
                  </Typography>
                  <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-email"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <MailIcon
                              style={{ color: "#0093FB", cursor: "auto" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{ background: "white" }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={5} sx={{ ml: 2, mr: 2 }}>
                  <Typography
                    sx={{
                      mt: 2,
                      ml: 0.1,
                      mb: 2,
                      fontWeight: 600,
                      fontFamily: "'Roboto', sans-serif",
                    }}
                  >
                    Password
                  </Typography>
                  <FormControl sx={{ mb: 2 }} fullWidth variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityOff style={{ color: "#0093FB" }} />
                            ) : (
                              <Visibility style={{ color: "#0093FB" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{ background: "white" }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={5} sx={{ ml: 2, mr: 2 }}>
                  <Button
                    fullWidth
                    onClick={handleLogin}
                    variant="contained"
                    endIcon={<LoginIcon />}
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      fontFamily: "'Roboto', sans-serif",
                      p: 1.2,
                      mt: 4,
                      mb: 2,
                      fontSize: 16,
                    }}
                  >
                    {" "}
                    Sign In{" "}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: { xs: 1, lg: 2 },
                  mr: { xs: 1, lg: 2 },
                }}
              >
                <Typography
                  style={{
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  Don't have an account?{" "}
                  <span
                    onClick={handleNewAccount}
                    style={{
                      color: "#0093FB",
                      fontWeight: 600,
                      cursor: "pointer",
                      fontFamily: "'Roboto', sans-serif",
                    }}
                  >
                    {" "}
                    Get Started
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </motion.div>
        </Grid>
      </Grid>
      {loginLoading ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackBarOpen}
            onClose={handleClose}
            message="Fetching Details... "
            key={vertical + horizontal}
            action={
              <React.Fragment>
                <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          ></Snackbar>
        </>
      ) : null}
      {loginFail ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackBarOpen}
            onClose={handleClose}
            message="Invalid Credentials "
            key={vertical + horizontal}
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          ></Snackbar>
        </>
      ) : null}
    </>
  );
}

export default LoginView;
