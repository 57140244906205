const TYPES = {
  //---- Accounting Software URL Fetch Parsing ------
  URL_FETCH_LOADING: "URL_FETCH_LOADING",
  URL_FETCH_SUCCESS: "URL_FETCH_SUCCESS",
  URL_FETCH_FAIL: "URL_FETCH_LIST_FAIL",
  URL_FETCH_CLEAR_DATA: "URL_FETCH_CLEAR_DATA",

  //---- Fecth Access Token ------
  FETCH_ACCESS_TOKEN_LOADING: "FETCH_ACCESS_TOKEN_LOADING",
  FETCH_ACCESS_TOKEN_SUCCESS: "FETCH_ACCESS_TOKEN_SUCCESS",
  FETCH_ACCESS_TOKEN_FAIL: "FETCH_ACCESS_TOKEN_FAIL",
  FETCH_ACCESS_TOKEN_CLEAR_DATA: "FETCH_ACCESS_TOKEN_CLEAR_DATA",

  //---- zoho login ------
  ZOHO_LOGIN_LOADING: "ZOHO_LOGIN_LOADING",
  ZOHO_LOGIN_SUCCESS: "ZOHO_LOGIN_SUCCESS",
  ZOHO_LOGIN_FAIL: "ZOHO_LOGIN_FAIL",
  ZOHO_LOGIN_CLEAR_DATA: "ZOHO_LOGIN_CLEAR_DATA",

  //---- xero login ------
  XERO_LOGIN_LOADING: "XERO_LOGIN_LOADING",
  XERO_LOGIN_SUCCESS: "XERO_LOGIN_SUCCESS",
  XERO_LOGIN_FAIL: "XERO_LOGIN_FAIL",
  XERO_LOGIN_CLEAR_DATA: "XERO_LOGIN_CLEAR_DATA",

  //----- Report Permission Dialog ---------

  REPORT_PERMISSION_DIALOG:"REPORT_PERMISSION_DIALOG",

  //------ User Confirmation ---------------

  USER_CONFIRMATION_LOADING:"USER_CONFIRMATION_LOADING",
  USER_CONFIRMATION_SUCCESS:"USER_CONFIRMATION_SUCCESS",
  USER_CONFIRMATION_FAIL:"USER_CONFIRMATION_FAIL",
  USER_CONFIRMATION_CLEAR_DATA:"USER_CONFIRMATION_CLEAR_DATA",

};
export default TYPES;
