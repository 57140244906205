import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import { ARDialog } from "../AccountReceivable/AccountReceivableActions/AccountReceivableActions";
import Grid from "@mui/material/Grid";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import Alert from "@mui/material/Alert";
import { CustomerList } from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";
import SendEmail from "./SendEmail";
import { SendEmailDialog } from "../AccountReceivable/AccountReceivableActions/AccountReceivableActions";
import MailIcon from "@mui/icons-material/Mail";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubmitDialog() {
  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  //----------------------------------------------------------------------------------------------------

  //Dialog Status -> True : Open , False is close, On Upload Invoice Button Dialog Status will set True
  const arDialog = useSelector(
    (state) => state.AccountReceivableReducers.arDialog
  );

  const arLoading = useSelector(
    (state) => state.AccountReceivableReducers.arLoading
  );
  const arSucess = useSelector(
    (state) => state.AccountReceivableReducers.arSucess
  );

  const arFail = useSelector((state) => state.AccountReceivableReducers.arFail);

  const arFailResponse = useSelector(
    (state) => state.AccountReceivableReducers.arFailResponse
  );

  //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  //---------------------------------------------------------------------------------------------------

  const handleClose = () => {
    dispatch(CustomerList(fetchAccessTokenSucess.access_token));
    dispatch(ARDialog(!arDialog));
  };
  ///-------------------------------------------------------------------------------------------------
  //Dialog Open Staus --> True or False
  const sendMailDialog = useSelector(
    (state) => state.AccountReceivableReducers.sendMailDialog
  );
  const handleSendInvoice = () => {
    dispatch(SendEmailDialog(!sendMailDialog));
  };

  //--------------------------------------------------------------------------------------------------

  //PDF Creation Status

  const fetchInvoicePDFSucess = useSelector(
    (state) => state.AccountReceivableReducers.fetchInvoicePDFSucess
  );

  console.log("fetchInvoicePDFSucess", fetchInvoicePDFSucess);

  return (
    <>
      <div>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={arDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            sx={{
              fontFamily: "'Roboto', sans-serif",
              borderBottom: "1px solid #008ffc",
              p: 2,
              fontWeight: 600,
              fontSize: 17,
            }}
          >
            <Typography
              sx={{
                p: 0,
                pb: 0,
                pt: 0,
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Account{" "}
              <span
                style={{
                  color: "#0093FB",
                  fontFamily: "'Roboto', sans-serif",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Receivable
              </span>
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <DialogContentText
              sx={{
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 600,
                fontSize: "15px",
              }}
              id="alert-dialog-slide-description"
            >
              Invoice processing in progress. Details will be saved into
              QuickBooks.
            </DialogContentText>
          </DialogContent>
          {arLoading ? (
            <DialogContent sx={{ mt: 3 }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgressWithLabel />
              </div>
            </DialogContent>
          ) : null}

          {Object.keys(arSucess).length > 0 && !arLoading ? (
            <DialogContent sx={{ mt: -2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Alert severity="info">
                    <Typography
                      sx={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      {arSucess.message}
                    </Typography>
                  </Alert>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography></Typography>
                  <Button
                    onClick={handleSendInvoice}
                    disabled={
                      arSucess.message ===
                        "Could not store in database and ar deleted." ||
                      arSucess.message ===
                        "Did not store in DB and failed to upload to QB"
                    }
                    variant="contained"
                    fullWidth
                    endIcon={<MailIcon />}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {" "}
                    Send Invoice
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          ) : null}
          {arFail ? (
            <DialogContent sx={{ mt: -2 }}>
              {Object.keys(arFailResponse).length > 0 ? (
                <>
                  <Alert severity="error">
                    <Typography
                      sx={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      {arFailResponse.message
                        ? arFailResponse.message
                        : "Sorry! Failed to process the request"}
                    </Typography>
                  </Alert>
                </>
              ) : (
                <>
                  <Alert severity="error">
                    <Typography
                      sx={{
                        fontFamily: "'Roboto', sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Sorry! Failed to process the request
                    </Typography>
                  </Alert>
                </>
              )}
            </DialogContent>
          ) : null}
          <DialogActions>
            <Button
              sx={{ fontFamily: "'Roboto', sans-serif" }}
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {sendMailDialog ? <SendEmail /> : null}
    </>
  );
}
