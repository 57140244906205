import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import {
  InvoiceExtractionViewDialog,
  Training,
  SaveVendorKey,
  GetVendorKey,
  SaveApData,
} from "../AccountsPayableActions/AccountsPayableActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Container, Divider, TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { makeStyles } from "@mui/styles";
import LinearBuffer from "./LinearBuffer";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Document, Page, pdfjs } from "react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import noData from "../../../Assets/undraw_No_data_re_kwbl-removebg-preview.png";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";
import Alert from "@mui/material/Alert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InvoiceExtractionView(props) {
  const dispatch = useDispatch();

  /**
   * Checking the Accounting software - if zoho
   * then user should have option for tdx selection which is optional
   * if zoho user select the tdx then id should be passing with /training and /save-ap-details apis */

  const accountingSoftareType = localStorage.getItem("AcoountingSoftware");

  //Logic to move Professional Fees"  top in the list
  function moveProfessionalFeesToTop(list) {
    // Find the index of "Professional Fees" in the list
    const professionalFeesIndex = list.findIndex(
      (item) => item.name === "Professional Fees"
    );

    // If "Professional Fees" is found, move it to the top
    if (professionalFeesIndex !== -1) {
      const professionalFees = list.splice(professionalFeesIndex, 1)[0];
      list.unshift(professionalFees);
    }

    return list;
  }

  //API response for get - TDX Data

  //Get the vendor key details

  const tdxTaxSuccessResponse = useSelector(
    (state) => state.AccountsPayableReducers.tdxTaxSuccessResponse
  );

  const [updatedTdxTaxList, setUpdatedTdxTaxList] = React.useState();

  useEffect(() => {
    handletdxTaxList();
  }, [tdxTaxSuccessResponse]);

  const handletdxTaxList = () => {
    if (handletdxTaxList) {
      const updatedList = moveProfessionalFeesToTop(tdxTaxSuccessResponse);
      setUpdatedTdxTaxList(updatedList);
    }
  };

  // console.log("tdxTaxSuccessResponse", tdxTaxSuccessResponse);
  // console.log("updatedTdxTaxList", updatedTdxTaxList);

  //-----------------------------------------------------------------------

  useEffect(() => {
    dispatch(GetVendorKey());
  }, []);

  //----------------------------------------------------------------------

  // const vendorName = props.vendor;

  const vendorName = localStorage.getItem("SelectedVendor");

  //Data Extraction GIF

  let loadingGif = require("../../../Assets/loader.gif");

  const handleClose = () => {
    dispatch(InvoiceExtractionViewDialog(!invoiceExtractionViewDialog));
  };

  const invoiceExtractionViewDialog = useSelector(
    (state) => state.AccountsPayableReducers.invoiceExtractionViewDialog
  );

  // Extraction API Response

  const InvoiceParsingLoading = useSelector(
    (state) => state.AccountsPayableReducers.InvoiceParsingLoading
  );
  const InvoiceParsingSucess = useSelector(
    (state) => state.AccountsPayableReducers.InvoiceParsingSucess
  );

  const InvoiceParsingFail = useSelector(
    (state) => state.AccountsPayableReducers.InvoiceParsingFail
  );

  //------------------ On success extration of Invocie ---------------------

  const [invoiceData, setInvoiceData] = React.useState();
  const [pdfBase64, setPDFBase64] = React.useState();
  const [pdfUrlView, setPdfUrlView] = React.useState();

  //--------- Setting Up Individual Data from extracted invoice Data -------

  const [invoiceNumber, setInvoiceNumber] = React.useState();

  const [invoiceAmount, setInvoiceAmount] = React.useState();

  const [invoiceTax, setInvoiceTax] = React.useState();

  const [invoiceBillingDate, setBillingDate] = React.useState();

  const [invoiceBillingDateFormat, setBillingDateFormat] = React.useState();

  const [invoiceNetTerms, setInvoiceNetTerms] = React.useState();

  const [invoicePdf, setInvoicePdf] = React.useState();



  useEffect(() => {
    fetchInvoiceData();
  }, [InvoiceParsingSucess]);

  const fetchInvoiceData = () => {
    if (InvoiceParsingSucess) {
      if (InvoiceParsingSucess.message == "Data extracted.") {
        setInvoiceData(InvoiceParsingSucess.bill_data);
        setPDFBase64(InvoiceParsingSucess.file_data);
      }
    }
  };

  //-? PDF Preview

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };

  //function to convert base64 to pdf

  useEffect(() => {
    convertbase64topdf();
  }, [pdfBase64]);

  const convertbase64topdf = () => {
    if (pdfBase64) {
      const base64Content = pdfBase64;
      const base64Data = base64Content;
      const pdfData = atob(base64Data);
      const uint8Array = new Uint8Array(pdfData.length);
      for (let i = 0; i < pdfData.length; i++) {
        uint8Array[i] = pdfData.charCodeAt(i);
      }
      const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrlView(pdfUrl);
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }
  };

  //handle Download Invoice

  const handleDownload = () => {
    fetch(pdfUrlView)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Invoice_File.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  //Setting Up the basic invoice Deatils

  useEffect(() => {
    fetchIndividualData();
  }, [invoiceData]);

  const fetchIndividualData = () => {
    if (invoiceData) {
      setInvoiceNumber(invoiceData.InvoiceNumber);
      setInvoiceAmount(invoiceData.TotalDue);
      setInvoiceTax(invoiceData.tax_amount);
      setBillingDateFormat(invoiceData.date_format_text);
      setInvoiceNetTerms(invoiceData.NetTermDays);
      setInvoicePdf(invoiceData.Document);

      //Setting Up the billing date format.
      if (invoiceData.InvoiceDate) {
        // Convert the API date format to "yyyy-MM-dd"
        const parts = invoiceData.InvoiceDate.split("/");
        if (parts.length === 3) {
          const [day, month, year] = parts;
          const formattedDate = `${year}-${month}-${day}`;
          // console.log("formattedDate", formattedDate);
          setSelectedDate(formattedDate);
        }
        // const dateParts = invoiceData.InvoiceDate.split("/");
        // console.log("dateParts", dateParts);
        // const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        // console.log("formattedDate", formattedDate);
        // setBillingDate(formattedDate);
      }
    }
  };

  //Vendor Key Flags for each Input - invoice Number
  const [invoiceNumberFlag, setInvoiceNumberFlag] = React.useState(false);
  const [invoiceNumberVendorKey, setinvoiceNumberVendorKey] =
    React.useState("");

  const handleinvoiceNumber = (e) => {
    setInvoiceNumber(e);
    setInvoiceNumberFlag(true);
  };
  const handleClickInvoiceNumber = () => {
    setInvoiceNumberFlag(true);
  };

  const handleConfirmInvoiceNumber = () => {
    setInvoiceNumberFlag(false);
  };

  const handleinvoiceNumberVendorKeyChange = (e) => {
    setinvoiceNumberVendorKey(e);
  };

  //Vendor Key Flags for each Input - invoice Amount
  const [invoiceAmountFlag, setinvoiceAmountFlag] = React.useState(false);
  const [invoiceAmountVendorKey, setinvoiceAmountVendorKey] =
    React.useState("");

  const handleinvoiceAmount = (e) => {
    setInvoiceAmount(e);
    setinvoiceAmountFlag(true);
  };
  const handleClickInvoiceAmount = () => {
    setinvoiceAmountFlag(true);
  };

  const handleConfirmInvoiceAmount = () => {
    setinvoiceAmountFlag(false);
  };

  const handleinvoiceAmountVendorKeyChange = (e) => {
    setinvoiceAmountVendorKey(e);
  };

  //Vendor Key Flags for each Input - invoice Tax
  const [invoiceTaxFlag, setinvoiceTaxFlag] = React.useState(false);
  const [invoiceTaxVendorKey, setinvoiceTaxVendorKey] = React.useState("");

  const handleinvoiceTax = (e) => {
    setInvoiceTax(e);
    setinvoiceTaxFlag(true);
  };
  const handleClickInvoiceTax = () => {
    setinvoiceTaxFlag(true);
  };

  const handleConfirmInvoiceTax = () => {
    setinvoiceTaxFlag(false);
  };

  const handleinvoiceTaxVendorKeyChange = (e) => {
    setinvoiceTaxVendorKey(e);
  };

  //Vendor Key Flags for each Input - invoice BillingDate
  const [invoiceBillingDateFlag, setinvoiceBillingDateFlag] =
    React.useState(false);
  const [invoiceBillingDateVendorKey, setinvoiceBillingDateVendorKey] =
    React.useState("");

  const handleinvoiceBillingDate = (e) => {
    // const dateParts = e.split("-");
    // const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    // setBillingDate(formattedDate);
    setinvoiceBillingDateFlag(true);
  };
  const handleClickInvoiceBillingDate = () => {
    setinvoiceBillingDateFlag(true);
  };

  const handleConfirmInvoiceBillingDate = () => {
    setinvoiceBillingDateFlag(false);
  };

  const handleinvoiceBillingDateVendorKeyChange = (e) => {
    setinvoiceBillingDateVendorKey(e);
  };

  const formatInputValue = (value) => {
    if (value) {
      const dateParts = value.split("/");
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      return formattedDate;
    }
  };

  //Vendor Key Flags for each Input - invoice Net Terms

  const [invoiceTermsFlag, setinvoiceTermsFlag] = React.useState(false);
  const [invoiceTermsVendorKey, setinvoiceTermsVendorKey] = React.useState("");

  const handleinvoiceTems = (e) => {
    setInvoiceNetTerms(e);
    setinvoiceTermsFlag(true);
  };
  const handleClickInvoiceTerms = () => {
    setinvoiceTermsFlag(true);
  };

  const handleConfirmInvoiceTerms = () => {
    setinvoiceTermsFlag(false);
  };

  const handleinvoiceTermsVendorKeyChange = (e) => {
    setinvoiceTermsVendorKey(e);
  };

  //---------------------------------------------------------------------

  const [enableConfirmButton, setEnableConfirmButton] = React.useState(false);

  useEffect(() => {
    confirmDetailsButton();
  }, [
    invoiceNumberFlag,
    invoiceAmountFlag,
    invoiceTaxFlag,
    invoiceBillingDateFlag,
    invoiceTermsFlag,
  ]);

  const confirmDetailsButton = () => {
    if (
      !invoiceNumberFlag &&
      !invoiceAmountFlag &&
      !invoiceTaxFlag &&
      !invoiceBillingDateFlag &&
      !invoiceTermsFlag
    ) {
      setEnableConfirmButton(true);
    } else {
      setEnableConfirmButton(false);
    }
  };

  //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  function formatDateNew(inputDate) {
    if (invoiceBillingDateFormat == "dd/mm/yyyy") {
      const dateParts = inputDate.split("-");
      if (dateParts.length === 3) {
        const [year, month, day] = dateParts;
        return `${day}/${month}/${year}`;
      }
      // Return the input date if it doesn't match the "yyyy-MM-dd" format
      return inputDate;
    } else {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        // Create a new Date object with the parsed values
        const date = new Date(year, month - 1, day); // Note: month - 1 because months are zero-based

        // Format the date in "MM/dd/yyyy" format
        const formattedDate = `${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${date
          .getDate()
          .toString()
          .padStart(2, "0")}/${date.getFullYear()}`;

        return formattedDate;
      }
      return inputDate; // Return the input as is if it doesn't match the expected format
    }
  }

  //Confirm Details and save - then only it be saved into databse and accounting software

  const handleConfirmDetails = () => {
    //To check any vendor name or extracted data has been changed
    if (
      invoiceAmountVendorKey == "" &&
      invoiceBillingDateVendorKey == "" &&
      invoiceNumberVendorKey == "" &&
      invoiceTermsVendorKey == "" &&
      invoiceTaxVendorKey == "" &&
      InvoiceParsingSucess.bill_data.InvoiceNumber == invoiceNumber &&
      InvoiceParsingSucess.bill_data.NetTermDays == invoiceNetTerms &&
      InvoiceParsingSucess.bill_data.tax_amount == invoiceTax &&
      InvoiceParsingSucess.bill_data.TotalDue == invoiceAmount &&
      InvoiceParsingSucess.bill_data.InvoiceDate == formatDateNew(selectedDate)
    ) {
      //replacing the object vales with user inpouts/ if any

      InvoiceParsingSucess.bill_data.InvoiceNumber = invoiceNumber;
      InvoiceParsingSucess.bill_data.NetTermDays = invoiceNetTerms;
      InvoiceParsingSucess.bill_data.tax_amount = invoiceTax;
      InvoiceParsingSucess.bill_data.TotalDue = invoiceAmount;

      //yy--mm-dd -> should be changed to format givcen from the api //Logic goes down

      const formattedDate = formatDateNew(selectedDate);
      InvoiceParsingSucess.bill_data.InvoiceDate = formattedDate;

      //Getting the pip_id

      const pip_id = localStorage.getItem("pip_id");
      //console.log("pip_id", pip_id);
      //console.log("pip_id - type", typeof(pip_id));

      // pip_id is null then set to 1 or keep it as it is

      const final_pip_id = pip_id == "null" ? 1 : pip_id;

      //console.log("final_pip_id", final_pip_id);

      setOpen(true);
      localStorage.setItem("calledAPI","SaveApData")
      dispatch(
        SaveApData(
          InvoiceParsingSucess.bill_data,
          fetchAccessTokenSucess.access_token,
          vendorName,
          InvoiceParsingSucess.file_data,
          final_pip_id,
          fetchAccessTokenSucess.org_id,
          selectedOption ? selectedOption : null
        )
      );
    } else {
      //replacing the object vales with user inpouts/ if any
      InvoiceParsingSucess.bill_data.InvoiceNumber = invoiceNumber;
      InvoiceParsingSucess.bill_data.NetTermDays = invoiceNetTerms;
      InvoiceParsingSucess.bill_data.tax_amount = invoiceTax;
      InvoiceParsingSucess.bill_data.TotalDue = invoiceAmount;

      //yy--mm-dd -> should be changed to format givcen from the api //Logic goes down

      const formattedDate = formatDateNew(selectedDate);
      InvoiceParsingSucess.bill_data.InvoiceDate = formattedDate;

      setOpen(true);
      dispatch(
        SaveVendorKey(
          fetchAccessTokenSucess.access_token,
          vendorName,
          invoiceAmountVendorKey ? invoiceAmountVendorKey : "",
          invoiceBillingDateVendorKey ? invoiceBillingDateVendorKey : "",
          invoiceNumberVendorKey ? invoiceNumberVendorKey : "",
          invoiceTermsVendorKey ? invoiceTermsVendorKey : "",
          "",
          invoiceTaxVendorKey ? invoiceTaxVendorKey : "",
          0
        )
      );
      localStorage.setItem("calledAPI","Training")
      dispatch(
        Training(
          InvoiceParsingSucess.bill_data,
          fetchAccessTokenSucess.access_token,
          vendorName,
          InvoiceParsingSucess.file_data,
          fetchAccessTokenSucess.org_id,
          selectedOption ? selectedOption : null
        )
      );
    }
  };

  //------------- Confirm Dialog -------------------------

  const [open, setOpen] = React.useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  //Confirm Details - API call response ------------------

  const trainingLoading = useSelector(
    (state) => state.AccountsPayableReducers.trainingLoading
  );

  const trainingSuccess = useSelector(
    (state) => state.AccountsPayableReducers.trainingSuccess
  );

  //console.log("trainingSuccess",trainingSuccess)

  const trainingFail = useSelector(
    (state) => state.AccountsPayableReducers.trainingFail
  );

  //Save Ap Data - API call resposne

  const saveApDataLoading = useSelector(
    (state) => state.AccountsPayableReducers.saveApDataLoading
  );

  const saveApDataSuccess = useSelector(
    (state) => state.AccountsPayableReducers.saveApDataSuccess
  );
  // console.log("saveApDataSuccess",saveApDataSuccess)

  const saveApDataFail = useSelector(
    (state) => state.AccountsPayableReducers.saveApDataFail
  );

  //----------------------------------------------------
  // Initialize a state variable to store the date

  const [selectedDate, setSelectedDate] = useState("");

  // Function to handle date change
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
  };

  //handle TDX operations

  const [selectedOption, setSelectedOption] = useState("");
  const handleTDXChange = (e) => {
    setSelectedOption(e);
  };

  return (
    <>
      <div>
        <Dialog
          open={invoiceExtractionViewDialog}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          maxWidth={InvoiceParsingLoading ? "sm" : "xl"}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            style: {
              marginTop: "0vh", // Adjust the value as per your requirement
              borderRadius: 5,
            },
          }}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={11}>
                <Typography sx={{ fontWeight: 900, fontSize: 16, pl: 1 }}>
                  Invoice <span style={{ color: "#0093FB" }}>Details</span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4} sm={3} md={2} lg={1} sx={{ pt: 1 }}>
                {!InvoiceParsingLoading ? (
                  <>
                    <Divider />
                  </>
                ) : null}
              </Grid>
              <Grid item xs={8} sm={9} md={10} lg={11}></Grid>
            </Grid>
          </DialogTitle>
          {InvoiceParsingLoading ? (
            <>
              <Grid container sx={{ pt: 2 }}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  xs={12}
                >
                  <img
                    height="200"
                    width="350"
                    src={loadingGif}
                    alt="wait until the page loads"
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    mt: 2,
                    mb: 3,
                  }}
                  xs={12}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 16,
                    }}
                  >
                    Fetching Information ...
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    mt: 0,
                    mb: 3,
                    p: 8,
                    pt: 0,
                    pb: 1,
                  }}
                  xs={12}
                >
                  <LinearBuffer />
                </Grid>
              </Grid>
            </>
          ) : null}
          {!InvoiceParsingLoading && InvoiceParsingSucess ? (
            <>
              <DialogContent>
                <Grid container sx={{ pt: 2 }}>
                  {invoiceData && pdfBase64 ? (
                    <>
                      <Grid xs={12} md={5} sx={{ mt: 4 }}>
                        <div
                          style={{
                            padding: "10px",
                            height: "550px",
                            overflow: "auto",
                          }}
                        >
                          <div style={{ height: "500px" }}>
                            <Document
                              file={pdfUrlView}
                              onLoadSuccess={onDocumentLoadSuccess}
                              options={{ workerSrc: "/pdf.worker.js" }}
                            >
                              <Page pageNumber={pageNumber} />
                            </Document>
                          </div>
                          {numPages && (
                            <p>
                              Page {pageNumber} of {numPages}
                            </p>
                          )}
                        </div>
                        {numPages && (
                          <Grid container>
                            <Grid item xs={12} md={7} sx={{ mb: 2 }}>
                              <div>
                                <Button
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={handlePrevPage}
                                  disabled={pageNumber === 1}
                                >
                                  Previous Page
                                </Button>
                                <Button
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={handleNextPage}
                                  disabled={pageNumber === numPages}
                                >
                                  Next Page
                                </Button>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={5} sx={{ mb: 2 }}>
                              <Button
                                endIcon={<DownloadIcon />}
                                onClick={handleDownload}
                                sx={{ textTransform: "capitalize" }}
                              >
                                Download Invoice
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        xs={12}
                        md={5}
                        sx={{
                          mt: 4,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={noData} />
                      </Grid>
                    </>
                  )}

                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Divider orientation="vertical"></Divider>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Grid container sx={{ mt: 4 }} spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography sx={{ pb: 1, fontWeight: 900 }}>
                          Vendor Name
                        </Typography>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          value={vendorName}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {invoiceNumberFlag ? (
                          <>
                            <Typography
                              sx={{
                                pb: 1,
                                fontWeight: 900,
                                color: "#FF0000",
                                textDecoration: "underline",
                              }}
                            >
                              <span>Invoice Number</span>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              Invoice Number
                            </Typography>
                          </>
                        )}

                        <TextField
                          name="invoiceNumber"
                          id="invoiceNumber"
                          onChange={(e) => handleinvoiceNumber(e.target.value)}
                          value={invoiceNumber}
                          onClick={handleClickInvoiceNumber}
                          fullWidth
                        />
                      </Grid>
                      {invoiceNumberFlag ? (
                        <>
                          <Grid item xs={8}>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              <span>Vendor Key</span> [ Invoice Number ]
                            </Typography>
                            <TextField
                              name="invoiceNumberVendorKey"
                              id="invoiceNumberVendorKey"
                              value={invoiceNumberVendorKey}
                              onChange={(e) =>
                                handleinvoiceNumberVendorKeyChange(
                                  e.target.value
                                )
                              }
                              fullWidth
                            />{" "}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Button
                              sx={{ textTransform: "capitalize" }}
                              variant="outlined"
                              endIcon={<DoneIcon />}
                              onClick={handleConfirmInvoiceNumber}
                            >
                              Confirm
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12} md={6}>
                        {invoiceAmountFlag ? (
                          <>
                            <Typography
                              sx={{
                                pb: 1,
                                fontWeight: 900,
                                color: "#FF0000",
                                textDecoration: "underline",
                              }}
                            >
                              <span>Invoice Amount</span>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              Invoice Amount
                            </Typography>
                          </>
                        )}

                        <TextField
                          name="invoiceAmount"
                          id="invoiceAmount"
                          onChange={(e) => handleinvoiceAmount(e.target.value)}
                          value={invoiceAmount}
                          onClick={handleClickInvoiceAmount}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {invoiceTaxFlag ? (
                          <>
                            <Typography
                              sx={{
                                pb: 1,
                                fontWeight: 900,
                                color: "#FF0000",
                                textDecoration: "underline",
                              }}
                            >
                              <span>Tax</span>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              Tax
                            </Typography>
                          </>
                        )}
                        <TextField
                          name="invoiceTax"
                          id="invoiceTax"
                          onChange={(e) => handleinvoiceTax(e.target.value)}
                          value={invoiceTax}
                          onClick={handleClickInvoiceTax}
                          fullWidth
                        />
                      </Grid>
                      {invoiceAmountFlag ? (
                        <>
                          <Grid item xs={8}>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              <span>Vendor Key</span> [ Invoice Amount ]
                            </Typography>
                            <TextField
                              name="invoiceAmountVendorKey"
                              id="invoiceAmountVendorKey"
                              value={invoiceAmountVendorKey}
                              onChange={(e) =>
                                handleinvoiceAmountVendorKeyChange(
                                  e.target.value
                                )
                              }
                              fullWidth
                            />{" "}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Button
                              sx={{ textTransform: "capitalize" }}
                              variant="outlined"
                              endIcon={<DoneIcon />}
                              onClick={handleConfirmInvoiceAmount}
                            >
                              Confirm
                            </Button>
                            {/* <Tooltip title="Confirm">
                              <DoneIcon
                                onClick={handleConfirmInvoiceAmount}
                                sx={{
                                  mt: 3,
                                  color: "#228B22",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip> */}
                          </Grid>
                        </>
                      ) : null}
                      {invoiceTaxFlag ? (
                        <>
                          <Grid item xs={8}>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              <span>Vendor Key</span> [ Tax ]
                            </Typography>
                            <TextField
                              name="invoiceTaxVendorKey"
                              id="invoiceTaxVendorKey"
                              value={invoiceTaxVendorKey}
                              onChange={(e) =>
                                handleinvoiceTaxVendorKeyChange(e.target.value)
                              }
                              fullWidth
                            />{" "}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Button
                              sx={{ textTransform: "capitalize" }}
                              variant="outlined"
                              endIcon={<DoneIcon />}
                              onClick={handleConfirmInvoiceTax}
                            >
                              Confirm
                            </Button>
                            {/* <Tooltip title="Confirm">
                              <DoneIcon
                                onClick={handleConfirmInvoiceTax}
                                sx={{
                                  mt: 3,
                                  color: "#228B22",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip> */}
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12} md={6}>
                        {invoiceBillingDateFlag ? (
                          <>
                            <Typography
                              sx={{
                                pb: 1,
                                fontWeight: 900,
                                color: "#FF0000",
                                textDecoration: "underline",
                              }}
                            >
                              <span>Billing Date</span>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              Billing Date
                            </Typography>
                          </>
                        )}
                        {/* <TextField
                          type="date"
                          id="invoiceBillingDate"
                          name="invoiceBillingDate"
                          // value={formatInputValue(invoiceBillingDate)}
                          value={invoiceBillingDate}
                          onChange={(e) =>
                            handleinvoiceBillingDate(e.target.value)
                          }
                          onClick={handleClickInvoiceBillingDate}
                          fullWidth
                        /> */}
                        <TextField
                          type="date"
                          id="invoiceBillingDate"
                          name="invoiceBillingDate"
                          value={selectedDate}
                          onChange={handleDateChange}
                          onClick={handleClickInvoiceBillingDate}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {invoiceTermsFlag ? (
                          <>
                            <Typography
                              sx={{
                                pb: 1,
                                fontWeight: 900,
                                color: "#FF0000",
                                textDecoration: "underline",
                              }}
                            >
                              <span>Net Terms</span>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              Net Terms
                            </Typography>
                          </>
                        )}
                        <TextField
                          name="invoiceTerms"
                          id="invoiceTerms"
                          value={invoiceNetTerms}
                          onChange={(e) => handleinvoiceTems(e.target.value)}
                          onClick={handleClickInvoiceTerms}
                          fullWidth
                        />
                      </Grid>
                      {invoiceBillingDateFlag ? (
                        <>
                          <Grid item xs={8}>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              <span>Vendor Key</span> [ Billing Date ]
                            </Typography>
                            <TextField
                              name="invoiceBillingdateVendorKey"
                              id="invoiceBillingdateVendorKey"
                              value={invoiceBillingDateVendorKey}
                              onChange={(e) =>
                                handleinvoiceBillingDateVendorKeyChange(
                                  e.target.value
                                )
                              }
                              fullWidth
                            />{" "}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Button
                              sx={{ textTransform: "capitalize" }}
                              variant="outlined"
                              endIcon={<DoneIcon />}
                              onClick={handleConfirmInvoiceBillingDate}
                            >
                              Confirm
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                      {invoiceTermsFlag ? (
                        <>
                          <Grid item xs={8}>
                            <Typography sx={{ pb: 1, fontWeight: 900 }}>
                              <span>Vendor Key</span> [ Net Terms ]
                            </Typography>
                            <TextField
                              name="invoiceTermsVendorKey"
                              id="invoiceTermsVendorKey"
                              value={invoiceTermsVendorKey}
                              onChange={(e) =>
                                handleinvoiceTermsVendorKeyChange(
                                  e.target.value
                                )
                              }
                              fullWidth
                            />{" "}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Button
                              sx={{ textTransform: "capitalize" }}
                              variant="outlined"
                              endIcon={<DoneIcon />}
                              onClick={handleConfirmInvoiceTerms}
                            >
                              Confirm
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12} md={6}>
                        {accountingSoftareType === "zoho" &&
                        updatedTdxTaxList ? (
                          <>
                            <TextField
                              id="tdx"
                              name="tdx"
                              label="TDS Tax (optional)"
                              value={selectedOption}
                              onChange={(event) =>
                                handleTDXChange(event.target.value)
                              }
                              variant="outlined"
                              select
                              fullWidth
                            >
                              {updatedTdxTaxList.map((item) => (
                                <MenuItem
                                  key={item.id}
                                  value={item.id}
                                  sx={{
                                    fontFamily: "'Roboto', sans-serif",
                                    "&:hover": {
                                      borderLeft: "5px solid #008ffc",
                                      borderRadius: 1,
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  {`${item.name} - ${item.percentage}%`}
                                </MenuItem>
                              ))}
                            </TextField>
                          </>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 3, mb: 5 }}>
                        <Container maxWidth="xs">
                          <Button
                            fullWidth
                            variant="contained"
                            disabled={!enableConfirmButton}
                            sx={{ textTransform: "capitalize", p: 1.4 }}
                            endIcon={<DoneIcon />}
                            onClick={handleConfirmDetails}
                          >
                            Confirm Details
                          </Button>
                        </Container>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          ) : null}
        </Dialog>
      </div>
      <div>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Deatils"}</DialogTitle>
          <DialogContent>
            {trainingLoading || saveApDataLoading ? (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <CircularProgressWithLabel />
                  </Grid>
                </Grid>
              </>
            ) : null}

            {saveApDataSuccess ? (
              <>
                <Alert severity="info">
                  {saveApDataSuccess.message}
                </Alert>
              </>
            ) : null}

            {trainingSuccess ? (
              <>
                <Alert severity="info">
                  {trainingSuccess}
                </Alert>
              </>
            ) : null}

            {trainingFail || saveApDataFail ? (
              <>
                <Alert severity="error">
                  Sorry!! Failed to process the invoice
                </Alert>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
