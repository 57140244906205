import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import { ARUploadDialog } from "../AccountReceivable/AccountReceivableActions/AccountReceivableActions";
import Grid from "@mui/material/Grid";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import Alert from "@mui/material/Alert";
import { CustomerList } from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubmitDialogUpload() {
  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  //Dialog Status -> True : Open , False is close, On Upload Invoice Button Dialog Status will set True
  const arUploadDialog = useSelector(
    (state) => state.AccountReceivableReducers.arUploadDialog
  );

  const arUploadLoading = useSelector(
    (state) => state.AccountReceivableReducers.arUploadLoading
  );
  const arUploadSucess = useSelector(
    (state) => state.AccountReceivableReducers.arUploadSucess
  );

  const arUploadFail = useSelector(
    (state) => state.AccountReceivableReducers.arUploadFail
  );

  const arUploadFailResponse = useSelector(
    (state) => state.AccountReceivableReducers.arUploadFailResponse
  );


 //Access Token
 const fetchAccessTokenSucess = useSelector(
  (state) => state.LandingPageReducers.fetchAccessTokenSucess
);

  const handleClose = () => {
    dispatch(CustomerList(fetchAccessTokenSucess.access_token,fetchAccessTokenSucess.org_id));
    dispatch(ARUploadDialog(!arUploadDialog));
  };

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={arUploadDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontFamily: "'Roboto', sans-serif",
            borderBottom: "1px solid #008ffc",
            p: 2,
            fontWeight: 600,
            fontSize: 17,
          }}
        >
          <Typography
            sx={{
              p: 0,
              pb: 0,
              pt: 0,
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            Account{" "}
            <span
              style={{
                color: "#0093FB",
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              Receivable
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText
            sx={{
              fontFamily: "'Roboto', sans-serif",
              fontWeight: 600,
              fontSize: "15px",
            }}
            id="alert-dialog-slide-description"
          >
            Invoice processing in progress. Details will be saved into
            QuickBooks.
          </DialogContentText>
        </DialogContent>
        {arUploadLoading ? (
          <DialogContent sx={{ mt: 3 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgressWithLabel />
            </div>
          </DialogContent>
        ) : null}

        {Object.keys(arUploadSucess).length > 0 && !arUploadLoading ? (
          <DialogContent sx={{ mt: -2 }}>
              <Alert severity="info">
                {" "}
                <span style={{ fontFamily: "'Roboto', sans-serif" }}>
               {arUploadSucess.message}
                </span>
              </Alert>
          </DialogContent>
        ) : null}
      
        {arUploadFail ? (
          <DialogContent sx={{ mt: -2 }}>
            {Object.keys(arUploadFailResponse).length > 0 ? (
              <>
                <Alert severity="error">
                  <span style={{ fontFamily: "'Roboto', sans-serif" }}>
                    {arUploadFailResponse.message}
                  </span>
                </Alert>
              </>
            ) : (
              <>
                <Alert severity="error">
                  <span style={{ fontFamily: "'Roboto', sans-serif" }}>
                    Sorry! Failed to process the request
                  </span>
                </Alert>
              </>
            )}
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button
            sx={{ fontFamily: "'Roboto', sans-serif" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
