import { Tune } from "@mui/icons-material";
import TYPES from "../FinancialReportingTypes/FinancialReportingTypes";
const initialState = {
  budgetDialog: false,
  budgetUploadLoading: null,
  budgetUploadSucess: [],
  budgetUploadSucessStatus: null,
  budgetUploadFail: null,
  syncActualsLoading: null,
  syncActualsSucess: null,
  syncActualsFail: null,
  budgetApprovalCountLoading: null,
  budgetApprovalCountSucess: null,
  budgetApprovalCountSucessResponse: [],
  budgetApprovalCountFail: null,

  budgetApprovalLoading: null,
  budgetApprovalSucess: null,
  budgetApprovalSucessResponse: [],
  budgetApprovalFail: null,

  budgetApprovalDialog: false,

  budgetDeclineLoading: null,
  budgetDeclineSucess: null,
  budgetDeclineSucessResponse: [],
  budgetDeclineFail: null,

  budgetDeclineDialog: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.BUGET_DIALOG:
      return {
        ...state,
        budgetDialog: action.payload,
      };
    case TYPES.BUDGET_UPLOAD_LOADING:
      return {
        ...state,
        budgetUploadLoading: true,
        budgetUploadSucess: [],
        budgetUploadSucessStatus: false,
        budgetUploadFail: false,
      };
    case TYPES.BUDGET_UPLOAD_SUCCESS:
      return {
        ...state,
        budgetUploadLoading: false,
        budgetUploadSucess: action.payload,
        budgetUploadSucessStatus: true,
        budgetUploadFail: false,
      };
    case TYPES.BUDGET_UPLOAD_FAIL:
      return {
        ...state,
        budgetUploadLoading: false,
        budgetUploadSucess: [],
        budgetUploadSucessStatus: false,
        budgetUploadFail: true,
      };
    case TYPES.BUDGET_UPLOAD_CLEAR_DATA:
      return {
        ...state,
        budgetUploadLoading: null,
        budgetUploadSucess: [],
        budgetUploadSucessStatus: null,
        budgetUploadFail: null,
      };
    case TYPES.SYNC_ACTUALS_LOADING:
      return {
        ...state,
        syncActualsLoading: true,
        syncActualsSucess: false,
        syncActualsFail: false,
      };
    case TYPES.SYNC_ACTUALS_SUCCESS:
      return {
        ...state,
        syncActualsLoading: false,
        syncActualsSucess: true,
        syncActualsFail: false,
      };
    case TYPES.SYNC_ACTUALS_FAIL:
      return {
        ...state,
        syncActualsLoading: false,
        syncActualsSucess: false,
        syncActualsFail: Tune,
      };
    case TYPES.SYNC_ACTUALS_CLEAR_DATA:
      return {
        ...state,
        syncActualsLoading: null,
        syncActualsSucess: null,
        syncActualsFail: null,
      };
    case TYPES.BUDGET_APPROVAL_COUNT_LOADING:
      return {
        ...state,
        budgetApprovalCountLoading: true,
        budgetApprovalCountSucess: false,
        budgetApprovalCountSucessResponse: [],
        budgetApprovalCountFail: false,
      };
    case TYPES.BUDGET_APPROVAL_COUNT_SUCCESS:
      return {
        ...state,
        budgetApprovalCountLoading: false,
        budgetApprovalCountSucess: true,
        budgetApprovalCountSucessResponse: action.payload,
        budgetApprovalCountFail: false,
      };
    case TYPES.BUDGET_APPROVAL_COUNT_FAIL:
      return {
        ...state,
        budgetApprovalCountLoading: false,
        budgetApprovalCountSucess: false,
        budgetApprovalCountSucessResponse: [],
        budgetApprovalCountFail: true,
      };
    case TYPES.BUDGET_APPROVAL_COUNT_CLEAR_DATA:
      return {
        ...state,
        budgetApprovalCountLoading: null,
        budgetApprovalCountSucess: null,
        budgetApprovalCountSucessResponse: [],
        budgetApprovalCountFail: null,
      };
    case TYPES.BUDGET_APPROVAL_LOADING:
      return {
        ...state,
        budgetApprovalLoading: true,
        budgetApprovalSucess: false,
        budgetApprovalSucessResponse: [],
        budgetApprovalFail: false,
      };
    case TYPES.BUDGET_APPROVAL_SUCCESS:
      return {
        ...state,
        budgetApprovalLoading: false,
        budgetApprovalSucess: true,
        budgetApprovalSucessResponse: action.payload,
        budgetApprovalFail: false,
      };
    case TYPES.BUDGET_APPROVAL_FAIL:
      return {
        ...state,
        budgetApprovalLoading: false,
        budgetApprovalSucess: false,
        budgetApprovalSucessResponse: [],
        budgetApprovalFail: true,
      };
    case TYPES.BUDGET_APPROVAL_CLEAR_DATA:
      return {
        ...state,
        budgetApprovalLoading: null,
        budgetApprovalSucess: null,
        budgetApprovalSucessResponse: [],
        budgetApprovalFail: null,
      };

    case TYPES.BUDGET_APPROVAL_DIALOG:
      return {
        ...state,
        budgetApprovalDialog: action.payload,
      };

    case TYPES.BUDGET_DECLINE_DIALOG:
      return {
        ...state,
        budgetDeclineDialog: action.payload,
      };
    case TYPES.BUDGET_DECLINE_LOADING:
      return {
        ...state,
        budgetDeclineLoading: true,
        budgetDeclineSucess: false,
        budgetDeclineSucessResponse: [],
        budgetDeclineFail: false,
      };
    case TYPES.BUDGET_DECLINE_SUCCESS:
      return {
        ...state,
        budgetDeclineLoading: false,
        budgetDeclineSucess: true,
        budgetDeclineSucessResponse: action.payload,
        budgetDeclineFail: false,
      };
    case TYPES.BUDGET_DECLINE_FAIL:
      return {
        ...state,
        budgetDeclineLoading: false,
        budgetDeclineSucess: false,
        budgetDeclineSucessResponse: [],
        budgetDeclineFail: true,
      };
    case TYPES.BUDGET_DECLINE_CLEAR_DATA:
      return {
        ...state,
        budgetDeclineLoading: null,
        budgetDeclineSucess: null,
        budgetDeclineSucessResponse: [],
        budgetDeclineFail: null,
      };
    default:
      return {
        ...state,
      };
  }
}
