const TYPES = {
  //----Invoice Parsing ------
  INVOICE_PARSING_LOADING: "INVOICE_PARSING_LOADING",
  INVOICE_PARSING_SUCCESS: "INVOICE_PARSING_SUCCESS",
  INVOICE_PARSING_FAIL: "INVOICE_PARSING_LIST_FAIL",
  INVOICE_PARSING_CLEAR_DATA: "INVOICE_PARSING_CLEAR_DATA",

  //----Pending Invoice List ------
  PENDING_INVOICE_LIST_LOADING: "PENDING_INVOICE_LIST_LOADING",
  PENDING_INVOICE_LIST_SUCCESS: "PENDING_INVOICE_LIST_SUCCESS",
  PENDING_INVOICE_LIST_FAIL: "PENDING_INVOICE_LIST_FAIL",
  PENDING_INVOICE_LIST_CLEAR_DATA: "PENDING_INVOICE_LIST_CLEAR_DATA",

  //----Pending Invoice List In Accounting Software ------
  PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_LOADING:
    "PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_LOADING",
  PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_SUCCESS:
    "PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_SUCCESS",
  PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_FAIL:
    "PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_FAIL",
  PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_CLEAR_DATA:
    "PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_CLEAR_DATA",

  //----Pending Invoice Upload ------
  PENDING_INVOICE_UPLOAD_LOADING: "PENDING_INVOICE_UPLOAD_LOADING",
  PENDING_INVOICE_UPLOAD_SUCCESS: "PENDING_INVOICE_UPLOAD_SUCCESS",
  PENDING_INVOICE_UPLOAD_FAIL: "PENDING_INVOICE_UPLOAD_FAIL",
  PENDING_INVOICE_UPLOAD_CLEAR_DATA: "PENDING_INVOICE_UPLOAD_CLEAR_DATA",

  //----Pending Invoice Upload In Accounting Software ------
  PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_LOADING:
    " PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_LOADING",
  PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_SUCCESS:
    "PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_SUCCESS",
  PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_FAIL:
    "PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_FAIL",
  PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_CLEAR_DATA:
    "PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_CLEAR_DATA",

  //----Track Invoice ------
  TRACK_INVOICE_LOADING: "TRACK_INVOICE_LOADING",
  TRACK_INVOICE_SUCCESS: "TRACK_INVOICE_SUCCESS",
  TRACK_INVOICE_FAIL: "TRACK_INVOICE__FAIL",
  TRACK_INVOICE_CLEAR_DATA: "TRACK_INVOICE_CLEAR_DATA",

  //----Invoice Parsing Dialog ------

  INVOICE_PARSING_DIALOG: "INVOICE_PARSING_DIALOG",

  //---- Invoice Edit Dialog  -----------

  INVOICE_EDIT_DIALOG: "INVOICE_EDIT_DIALOG",

  //---- Invoice Approve Dialog  -----------

  INVOICE_APPROVE_DIALOG: "INVOICE_APPROVE_DIALOG",

  //----Accounts List from QB ------

  ACCOUNT_LIST_LOADING: "ACCOUNT_LIST_LOADING",
  ACCOUNT_LIST_SUCCESS: " ACCOUNT_LIST_SUCCESS",
  ACCOUNT_LIST_FAIL: " ACCOUNT_LIST__FAIL",
  ACCOUNT_LIST_CLEAR_DATA: " ACCOUNT_LIST_CLEAR_DATA",

  //----- Customer  List -----

  CUSTOMER_LIST_LOADING: "CUSTOMER_LIST_LOADING",
  CUSTOMER_LIST_SUCCESS: " CUSTOMER_LIST_SUCCESS",
  CUSTOMER_LIST_FAIL: " CUSTOMER_LIST__FAIL",
  CUSTOMER_LIST_CLEAR_DATA: " CUSTOMER_LIST_CLEAR_DATA",

  //----- Account Mapping  --------
  ACCOUNT_MAPPING_LOADING: "ACCOUNT_MAPPING_LOADING",
  ACCOUNT_MAPPING_SUCCESS: "ACCOUNT_MAPPING_SUCCESS",
  ACCOUNT_MAPPING_FAIL: "ACCOUNT_MAPPING__FAIL",
  ACCOUNT_MAPPING_CLEAR_DATA: "ACCOUNT_MAPPING_CLEAR_DATA",

  //----- Get VendorKey  --------

  GET_VENDORKEY_LOADING: "GET_VENDORKEY_LOADING",
  GET_VENDORKEY_SUCCESS: "GET_VENDORKEY_SUCCESS",
  GET_VENDORKEY_FAIL: "GET_VENDORKEY_FAIL",
  GET_VENDORKEY_CLEAR_DATA: "GET_VENDORKEY_CLEAR_DATA",

  //----- Save VendorKey  --------

  SAVE_VENDORKEY_LOADING: "SAVE_VENDORKEY_LOADING",
  SAVE_VENDORKEY_SUCCESS: "SAVE_VENDORKEY_SUCCESS",
  SAVE_VENDORKEY_FAIL: "SAVE_VENDORKEY_FAIL",
  SAVE_VENDORKEY_CLEAR_DATA: "SAVE_VENDORKEY_CLEAR_DATA",

  //---- View Invoice Dialog  -----------
  VIEW_INVOICE_DIALOG: "VIEW_INVOICE_DIALOG",

  //---- Login   -----------

  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGIN_CLEAR_DATA: "LOGIN_CLEAR_DATA",

  //---- Register   -----------

  REGISTER_LOADING: "REGISTER_LOADING",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAIL: "REGISTER_FAIL",
  REGISTER_CLEAR_DATA: "REGISTER_CLEAR_DATA",

  //---- OTP   -----------

  OTP_LOADING: "OTP_LOADING",
  OTP_SUCCESS: "OTP_SUCCESS",
  OTP_FAIL: "OTP_FAIL",
  OTP_CLEAR_DATA: "OTP_CLEAR_DATA",

  //---- Delete From Track Invoice  -----------

  DELETE_INVOICE_LOADING: "DELETE_INVOICE_LOADING",
  DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_FAIL: "DELETE_INVOICE_FAIL",
  DELETE_INVOICE_CLEAR_DATA: "DELETE_INVOICE_CLEAR_DATA",

  //---- Delete QB  -----------

  DELETE_QB_INVOICE_LOADING: "DELETE_QB_INVOICE_LOADING",
  DELETE_QB_INVOICE_SUCCESS: "DELETE_QB_INVOICE_SUCCESS",
  DELETE_QB_INVOICE_FAIL: "DELETE_QB_INVOICE_FAIL",
  DELETE_QB_INVOICE_CLEAR_DATA: "DELETE_QB_INVOICE_CLEAR_DATA",

  //---- Delete From Track Dialog -----------

  DELETE_INVOICE_DIALOG: "DELETE_INVOICE_DIALOG",

  //---- ZOHO Token  -----------

  ZOHO_TOKEN_LOADING: " ZOHO_TOKEN_LOADING",
  ZOHO_TOKEN_SUCCESS: " ZOHO_TOKEN_SUCCESS",
  ZOHO_TOKEN_FAIL: " ZOHO_TOKEN_FAIL",
  ZOHO_TOKEN_CLEAR_DATA: " ZOHO_TOKEN_CLEAR_DATA",

  //---- Delete Pending Invoice Dialog  -------------

  DELETE_PENDING_INVOICE_DIALOG: "DELETE_PENDING_INVOICE_DIALOG",

  //---- Delete From Pending Invoice  -----------

  DELETE_PENDING_INVOICE_LOADING: "DELETE_PENDING_INVOICE_LOADING",
  DELETE_PENDING_INVOICE_SUCCESS: "DELETE_PENDING_INVOICE_SUCCESS",
  DELETE_PENDING_INVOICE_FAIL: "DELETE_PENDING_INVOICE_FAIL",
  DELETE_PENDING_INVOICE_CLEAR_DATA: "DELETE_PENDING_INVOICE_CLEAR_DATA",

  //---- Invoice Extraction View Dialog  -----------
  
  INVOICE_EXTRACTION_VIEW_DIALOG:"INVOICE_EXTRACTION_VIEW_DIALOG",

   //---- Training  -----------

   TRAINING_LOADING: "TRAINING_LOADING",
   TRAINING_SUCCESS: "TRAINING_SUCCESS",
   TRAINING_FAIL: "TRAINING_FAIL",
   TRAINING_CLEAR_DATA: "TRAINING_CLEAR_DATA",

   //---- Save Ap Data  -----------

   SAVE_AP_DATA_LOADING: "SAVE_AP_DATA_LOADING",
   SAVE_AP_DATA_SUCCESS: "SAVE_AP_DATA_SUCCESS",
   SAVE_AP_DATA_FAIL: "SAVE_AP_DATA_FAIL",
   SAVE_AP_DATA_CLEAR_DATA: "SAVE_AP_DATA_CLEAR_DATA",

    //---- TDX Tax  -----------

    TDX_TAX_LOADING: "TDX_TAX_LOADING",
    TDX_TAX_SUCCESS: "TDX_TAX_SUCCESS",
    TDX_TAX_FAIL: "TDX_TAX_FAIL",
    TDX_TAX_CLEAR_DATA: "TDX_TAX_CLEAR_DATA",
};

export default TYPES;
