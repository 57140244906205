import TYPES from "../LandingPageTypes/LandingPageTypes";
import Axios from "axios";
import { redirect_url } from "../../../constants";
import { auth_url } from "../../../constants";

const accountingSoftareType = localStorage.getItem("AcoountingSoftware");
var base_url = ""

if (accountingSoftareType == "qb") {
  base_url = "https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net"
}

if (accountingSoftareType == "zoho") {
  base_url = "https://asvinth-backend-zoho-dev-eastus-staging.azurewebsites.net"
}

if (accountingSoftareType == "xero") {
  base_url = "https://asvinth-backend-xero-dev-eastus.azurewebsites.net"
}



//-------------------------Accounting Software URL Fetch Parsing--------------------

export const URLfetch = () => (dispatch) => {
  dispatch(URLfetchLoading());
  
  let data = null;
  data = new FormData();
  data.append("redirect_uri", redirect_url);

  Axios.post(`https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net/get_oauth_url`, data)
    .then((response) => {
      dispatch({
        type: TYPES.URL_FETCH_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.URL_FETCH_FAIL,
      });
    });
};

//-------------------------Accounting Software URL Fetch Parsing Loading --------------------

export const URLfetchLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.URL_FETCH_LOADING,
  });
};

//-------------------------Accounting Software URL Fetch Parsing Clean Data--------------------

export const URLfetchClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.URL_FETCH_CLEAR_DATA,
  });
};

//------------------------- Fetch Access Token --------------------

export const FetchAccessToken =
  (code, clientAuthStrig, realmId) => (dispatch) => {
    dispatch(FetchAccessTokenLoading());
    let data = null;
    data = new FormData();
    data.append("redirect_uri", redirect_url);
    data.append("auth_code", code);
    data.append("realm_id", realmId);

    Axios.post(
      `${base_url}/get-access-token`,
      data
    )
      .then((response) => {
        dispatch({
          type: TYPES.FETCH_ACCESS_TOKEN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.FETCH_ACCESS_TOKEN_FAIL,
        });
      });
  };

//-------------------------Fetch Access Token Loading --------------------

export const FetchAccessTokenLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.FETCH_ACCESS_TOKEN_LOADING
  });
};

//-------------------------Fetch Access Token Clean Data--------------------

export const FetchAccessTokenClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.FETCH_ACCESS_TOKEN_CLEAR_DATA,
  });
};

//------------------------- zoho login --------------------

export const ZohoLogin = () => (dispatch) => {
  dispatch(ZohoLoginLoading());
  Axios.get(`https://asvinth-backend-zoho-dev-eastus-staging.azurewebsites.net/get_oauth_url`)
    .then((response) => {
      dispatch({
        type: TYPES.ZOHO_LOGIN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.ZOHO_LOGIN_FAIL,
      });
    });
};

//-------------------------zoho login Loading --------------------

export const ZohoLoginLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ZOHO_LOGIN_LOADING,
  });
};

//-------------------------zoho login Clean Data--------------------

export const ZohoLoginCleanData = () => (dispatch) => {
  dispatch({
    type: TYPES.ZOHO_LOGIN_CLEAR_DATA,
  });
};

//------------------------- xero login --------------------

export const XeroLogin = () => (dispatch) => {
  dispatch(XeroLoginLoading());
  Axios.get(`https://asvinth-backend-xero-dev-eastus.azurewebsites.net/login`)
    .then((response) => {
      dispatch({
        type: TYPES.XERO_LOGIN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.XERO_LOGIN_FAIL,
      });
    });
};

//-------------------------xero login Loading --------------------

export const XeroLoginLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.XERO_LOGIN_LOADING,
  });
};

//-------------------------xero login Clean Data--------------------

export const XeroLoginCleanData = () => (dispatch) => {
  dispatch({
    type: TYPES.XERO_LOGIN_CLEAR_DATA,
  });
};

//------------------------- Report Permission Dialog --------------------

export const ReportPermissionDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.REPORT_PERMISSION_DIALOG,
    payload:status
  });
};

//------------------------- User Confirmation --------------------

export const UserConfirmationbyAdmin = (token) => (dispatch) => {
  dispatch(UserConfirmationLoading());
  Axios.get(`${auth_url}/activate-user/${token}`)
    .then((response) => {
      dispatch({
        type: TYPES.USER_CONFIRMATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.USER_CONFIRMATION_FAIL,
      });
    });
};

//-------------------------User Confirmation Loading --------------------

export const UserConfirmationLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.USER_CONFIRMATION_LOADING,
  });
};

//------------------------ User Confirmation Clean Data--------------------

export const UserConfirmationCleanData = () => (dispatch) => {
  dispatch({
    type: TYPES.USER_CONFIRMATION_CLEAR_DATA,
  });
};