import { useEffect, useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";

// project imports
import "./message.css";

// mui imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";

export default function Message(props) {
  let date = new Date();
  const CURSOR_CLASS_NAME = "custom-type-animation-cursor";
  const inputRef = useRef();
  const [graphMessageInput, setGraphMessageInput] = useState("");
  // const [graphId, setGraphId] = useState(0);
  const [loading, setLoading] = useState(false);
  let user = sessionStorage.getItem("username");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (props.typing === false && props.image === true && props.graphID !== 0) {
      setLoading(false);
    }

    let date = new Date(props.time + "Z");

    setTime(`${date.getHours()}:${date.getMinutes()}`);
  }, [props]);

  const handleGraphQuery = (e) => {
    // e.preventDefault();
    // setGraphId(props.graphID);
    props.graphQueryDetails({ graphMSG: graphMessageInput, graphID: props.graphID });
    setGraphMessageInput("");
    setLoading(true);
  };

  const handleKeyDown = (event) => {
    //<------use enter key to submit form
    if (event.which === 13) {
      // inputRef.current.getElementsByTagName("textarea")[0].style.height = "auto";
      // setGraphId(props.graphID);
      props.graphQueryDetails({ graphMSG: graphMessageInput, graphID: props.graphID });
      setGraphMessageInput("");
      setLoading(true);
      event.preventDefault();
    }
  };

  return (
    <div>
      <Box
        sx={{
          // my: 2,
          display: "grid",
          flexFlow: "row",
          justifyContent: props.isUser ? "right" : "left",
          backgroundColor: props.isUser ? "" : "rgb(229, 246, 253)",
          padding: "8px",
        }}>
        <Box>
          <Typography
            gutterBottom
            variant="body2"
            component="div"
            sx={{
              maxWidth: props.image ? "100%" : "400px",
              overflowX: "auto",
              fontSize: "1rem",
            }}>
            {props.isUser ? (
              props.content
            ) : props.typing ? (
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  `${props.content}`,
                  (el) => el.classList.remove(CURSOR_CLASS_NAME), // A reference to the element gets passed as the first argument of a callback function
                  // 2000,
                  // (el) => el.classList.add(CURSOR_CLASS_NAME),
                ]}
                style={{ whiteSpace: "pre-line" }}
                wrapper="span"
                speed={90}
                repeat={0}
                cursor={false}
                className={CURSOR_CLASS_NAME}
              />
            ) : props.content === "loading_message" ? (
              <div className="loader3">
                <div className="circle1"></div>
                <div className="circle1"></div>
                <div className="circle1"></div>
                <div className="circle1"></div>
                <div className="circle1"></div>
              </div>
            ) : props.image ? (
              <Stack rowGap={2}>
                <img src={props.content} alt={props.content} style={{ height: "fit-content", width: "95%" }}></img>
                <Stack direction="row" columnGap={2}>
                  <a
                    href={props.content}
                    download
                    className="graph-download-button"
                    style={{ pointerEvents: loading === true ? "none" : "" }}>
                    <FileDownloadOutlinedIcon sx={{ fontSize: "16px" }} /> Download Graph
                  </a>

                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    multiline
                    maxRows={2}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    value={graphMessageInput}
                    onChange={(event) => setGraphMessageInput(event.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "4px 8px",
                      },
                      width: "70%",
                    }}
                    disabled={loading === true ? true : false}
                    placeholder="User Input"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {loading === false ? (
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={() => {
                                handleGraphQuery();
                              }}
                              type="submit"
                              disabled={graphMessageInput === "" ? true : false}>
                              <Tooltip title="Send Graph Message">
                                <SendIcon sx={{ fontSize: "15px", color: "#0093fb" }} />
                              </Tooltip>
                            </IconButton>
                          ) : (
                            <CircularProgress sx={{ color: "#0093fb" }} size={20} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Stack>
            ) : (
              props.content.split("\n").map((line, index) => (
                <span key={index}>
                  {line} <br />
                </span>
              ))
            )}
          </Typography>
          <Typography
            sx={{
              display: "grid",
              flexFlow: "row",
              justifyContent: props.isUser ? "right" : "left",
              color: "#707070",
              fontSize: "12px",
            }}>
            {props.isUser
              ? `User ${time === "NaN:NaN" ? `${date.getHours()}:${date.getMinutes()}` : time}`
              : `GenInsight.ai ${time === "NaN:NaN" ? `${date.getHours()}:${date.getMinutes()}` : time}`}
          </Typography>
          {/* {props.image && <img src={props.image} alt="Bot response" style={{ width: "100%" }} />}
          {!props.isUser && props.choices && (
            <Box>
              {props.choices.map((choice, index) => (
                <Chip key={index} label={choice} onClick={() => props.handleChoice(choice)} sx={{ mr: 0.5, mb: 0.5 }} />
              ))}
            </Box>
          )} */}
        </Box>
      </Box>
    </div>
  );
}
