import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import CorestartLogo from "../../Assets/CorestartLogo.png";
import { Button, Divider, TextField, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import ReportPermission from "./ReportPermission";
import { ReportPermissionDialog } from "../LandingPage/LandingPageActions/LandingPageActions";
import { useDispatch, useSelector } from "react-redux";

function ReportAccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogoClick = () => {
    navigate("/");
  };

  //Report Permission Dialog
  const reportPermissionDialog = useSelector(
    (state) => state.LandingPageReducers.reportPermissionDialog
  );

  const handleConfirm = () => {
    dispatch(ReportPermissionDialog(!reportPermissionDialog));
  };

  const handleCancel = () => {
    navigate("/");
    window.location.reload(false);
    
  }

  return (
    <>
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ m: { xs: 3, md: 6, lg: 13 }, mt: { xs: 7 }, mb: { lg: 5 } }}
      >
        <motion.div
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <img
            src={CorestartLogo}
            width="200"
            height="33"
            style={{ cursor: "pointer" }}
            onClick={handleLogoClick}
            alt="Corestart Logo"
          />
        </motion.div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          backgroundColor: "#e5f6fd",
          borderRadius: "5px",
          m: { xs: 3, md: 6, lg: 25 },
          mt: { lg: 0 },
          mb: { lg: 1 },
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.7 }}
        >
          <Typography
            sx={{ textAlign: "center", mt: 5, fontWeight: 900, fontSize: 20 }}
          >
            Finance Automation Platform - Access Reports
          </Typography>
          <Divider sx={{ ml: 8, mr: 8, mt: 2 }} />
          <Typography sx={{ mt: 2, fontWeight: 500, textAlign: "center" }}>
            Would you be interested in obtaining access to the financial report?
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                mt: 4,
                ml: { xs: 4, lg: 50 },
                mr: { xs: 4, lg: 50 },
              }}
            >
              <Button
                fullWidth
                onClick={handleConfirm}
                endIcon={<DoneIcon />}
                variant="contained"
                sx={{ textTransform: "capitalize", p: 1.8 }}
              >
                Yes, Confirm
              </Button>
              <Button
                fullWidth
                onClick={handleCancel}
                endIcon={<ClearIcon />}
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  mt: 3,
                  mb: 6,
                  p: 1.8,
                  border: "1px solid #000000",
                  color: "#000000",
                  background: "#ffffff",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
    </Grid>
    {reportPermissionDialog ? <ReportPermission />:null}
    </>
  );
}

export default ReportAccess;
