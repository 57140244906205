import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ReportPermissionDialog } from "../LandingPage/LandingPageActions/LandingPageActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ReportPermission() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Report Permission Dialog
  const reportPermissionDialog = useSelector(
    (state) => state.LandingPageReducers.reportPermissionDialog
  );

  const handleClose = () => {
    dispatch(ReportPermissionDialog(!reportPermissionDialog));
    navigate("/login");
  };

  return (
    <div>
      <Dialog
        open={reportPermissionDialog}
        keepMounted
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontWeight: 900 }}>{"Report Acccess"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <iframe
                title="Corestrat_Reports"
                src="https://app.powerbi.com/reportEmbed?reportId=cb9b9092-d9f2-46e5-94d0-3699fec8084d&autoAuth=true&ctid=f8f764a8-f449-4de9-9910-c97a39540393"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
