import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { ReactComponent as UploadIllustration } from "../../Assets/file-upload.svg";
import { Button, Hidden } from "@mui/material";
import BackupIcon from "@mui/icons-material/Backup";
import Skeleton from "@mui/material/Skeleton";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from "uuid";
import database from "../../Assets/database.png";
import { useSelector, useDispatch } from "react-redux";
import {
  InvoiceParsingPdf,
  InvoiceParsingDialogbox,
  AccountsList,
  CustomerList,
  AccountMapping,
  InvoiceParsingPdfSingle,
  InvoiceExtractionViewDialog,
  TrainingClarData,
  SaveApDataClarData,
  TDXTax

} from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";
import InvoiceParsingDialog from "./InvoiceParsingDialog";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PendingInvoiceIndex from "../AccountsPayable/AccountsPayablePages/PendingInvoiceIndex";
import TrackInvoice from "../AccountsPayable/AccountsPayablePages/TrackInvoice";
import VendorKeys from "./AccountsPayablePages/VendorKeys";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SortIcon from "@mui/icons-material/Sort";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import InvoiceExtractionView from "./AccountsPayablePages/InvoiceExtractionView";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Index() {
  //------------------------------------------------------------------------
  // Accounting Software
  const accountingSoftware = localStorage.getItem("AcoountingSoftware");
  //---------------------------------------------------------------------------
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Get access token - Loading Status

  const fetchAccessTokenLoading = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenLoading
  );

  //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  //--------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      CustomerList(
        fetchAccessTokenSucess.access_token,
        fetchAccessTokenSucess.org_id
      )
    );
    dispatch(AccountsList(fetchAccessTokenSucess.access_token));
    dispatch(
      TDXTax(
        fetchAccessTokenSucess.access_token,
        fetchAccessTokenSucess.org_id
      )
    );
  }, []);

  //-------------------------------------------------------------------------------

  //-------------------GSTN As User Input - For Zoho ------------------------------

  const accountingSoftareType = localStorage.getItem("AcoountingSoftware");

  function validateGSTIN(gstin) {
    const pattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return pattern.test(gstin);
  }

  const [GSTIN, setGSTIN] = React.useState();
  const [GSTINError, setGSTINError] = React.useState(false);

  const handleGSTIN = (e) => {
    let value = validateGSTIN(e);
    if (validateGSTIN(e)) {
      setGSTIN(e);
      setGSTINError(false);
    } else {
      setGSTIN(e);
      setGSTINError(true);
    }
  };

  //-------------------------------------------------------------------------------

  //Qb Account List

  const [accountType, setAccountType] = React.useState([]);

  const [accountTypeArray, setAccountTypeArray] = React.useState([]);

  const accountListLoading = useSelector(
    (state) => state.AccountsPayableReducers.accountListLoading
  );

  const accountListSucess = useSelector(
    (state) => state.AccountsPayableReducers.accountListSucess
  );

  useEffect(() => {
    updateAccountList();
  }, [accountListSucess]);

  const updateAccountList = () => {
    setAccountType(accountListSucess.accounts_data);
    let names = [];
    if (accountListSucess.accounts_data) {
      names = accountListSucess.accounts_data.map((item) => item.acc_type);
    }
    setAccountTypeArray(names);
  };

  //-------------------------------------------------------------------

  //QB Customer List
  const customerListSucess = useSelector(
    (state) => state.AccountsPayableReducers.customerListSucess
  );

  const customerListLoading = useSelector(
    (state) => state.AccountsPayableReducers.customerListLoading
  );
  useEffect(() => {
    updateCustomerList();
  }, [customerListSucess]);

  const [customerListUpdated, setCustomerListUpdated] = React.useState([]);
  const [currencyDataUpdated, setCurrencyDataUpdated] = React.useState([]);

  // multiCurrencyEnabledFlag === True -> Accounting software will accept different currency when user create a new vendor
  // multiCurrencyEnabledFlag === False -> Newly created vendor will be assigned with "homeCurrency" <- Which is provided from the accounting software

  const [multiCurrencyEnabledFlag, setMultiCurrencyEnabledFlag] =
    React.useState();

  //"homeCurrency" <- Which is provided from the accounting software
  const [homeCurrency, setHomeCurrency] = React.useState();
  const [userSelectedCurrency, setUserSelectedCurrency] = React.useState();
  const [addNewFlag, setAddNewFlag] = React.useState(false);
  const [addNewLineItemFlag, setNewLineItemFlag] = React.useState(false);
  const [vendor, setVendor] = React.useState("");

  const updateCustomerList = () => {
    setCustomerListUpdated(
      customerListSucess.vendors
        ? customerListSucess.vendors.map((item) => item.name)
        : []
    );
    setCurrencyDataUpdated(
      customerListSucess.currency_detail
        ? customerListSucess.currency_detail.currency_data
        : []
    );
    setMultiCurrencyEnabledFlag(
      customerListSucess.currency_detail
        ? customerListSucess.currency_detail.multi_currency_enabled
        : ""
    );
    setHomeCurrency(
      customerListSucess.currency_detail
        ? customerListSucess.currency_detail.home_currency
        : ""
    );
  };

  //--------------------------------------------------------------------------
  const handleCurrencyChange = (e) => {
    setUserSelectedCurrency(e);
    handleUserInputs(invoiceCount, "ven_curr_code", e);
  };

  //Dialog Status -> True : Open , False is close, On Upload Invoice Button Dialog Status will set True
  const InvoiceParsingDialogValue = useSelector(
    (state) => state.AccountsPayableReducers.InvoiceParsingDialog
  );

  var accessToken = fetchAccessTokenSucess
    ? fetchAccessTokenSucess.access_token
    : "";

  useEffect(() => {
    updateAccessToken();
  }, [fetchAccessTokenSucess]);

  const updateAccessToken = () => {
    localStorage.setItem("accessToken", fetchAccessTokenSucess.access_token);
  };

  const tokenDate = localStorage.getItem("accessToken");
  //------------------------------------------------------------------------
  //Auto Selection

  const accountingMappingLoading = useSelector(
    (state) => state.AccountsPayableReducers.accountingMappingLoading
  );

  const accountingMappingSucess = useSelector(
    (state) => state.AccountsPayableReducers.accountingMappingSucess
  );

  const [count, setCount] = useState(0);

  useEffect(() => {
    getMappings();
  }, [accountingMappingSucess]);

  const getMappings = () => {
    if (accountingMappingSucess.acc_type) {
      handleCategoryChange(accountingMappingSucess.acc_type);
      setSelectedCategory(accountingMappingSucess.acc_type);
    }
    if (accountingMappingSucess.acc_name) {
      handleLineItemChange(accountingMappingSucess.acc_name);
      setSelectedLineitem(accountingMappingSucess.acc_name);
    }
    setCount(count + 1);
  };

  const accountingMappingStatus = useSelector(
    (state) => state.AccountsPayableReducers.accountingMappingStatus
  );

  const accountingMappingFail = useSelector(
    (state) => state.AccountsPayableReducers.accountingMappingFail
  );

  //------------------------------- Batch Upload Starts ----------------------------------------------------

  // Flag to keep the count of Batch Invoice

  const [invoiceCount, setInvoiceCount] = React.useState(1);

  // Handle Add New Invoice - Batch Upload of Invoice

  const handleAddNewInvoice = () => {
    setInvoiceCount(invoiceCount + 1);
    setVendor("");
    setSelectedCategory("");
    setSelectedLineitem("");
    setInputFields([
      ...inputFields,
      {
        id: invoiceCount + 1,
        vendor: "",
        category1: "",
        category2: "",
        file: "",
        ven_curr_code: "",
      },
    ]);
  };

  // Line Item Details
  const [inputFields, setInputFields] = React.useState([
    {
      id: 1,
      vendor: "",
      category1: "",
      category2: "",
      file: "",
      ven_curr_code: "",
    },
  ]);

  // Function to update Batch Upload Files - Object
  //=> If invoice Id = 1 => which means the first invoice details is entering ->
  // on click of add new invoice index count will increase
  // => seletedItem is the dropdown which user is selected

  const handleUserInputs = (invoiceIId, selectedItem, value) => {
    const newInputFields = inputFields.map((i) => {
      if (invoiceIId === i.id) {
        i[selectedItem] = value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  //-----------------------------------------------------------------------------
  const [selectedCategory, setSelectedCategory] = React.useState();
  const [lineitemArray, setLineitemArray] = React.useState([]);
  const [selectedLineItem, setSelectedLineitem] = React.useState();
  const [invoice, setInvoice] = React.useState("");

  // Function to find pip_id by name --------------------------
  const findPipId = (name) => {
    const result = customerListSucess.vendors.find((item) => item.name === name);
    if (result) {
      localStorage.setItem("pip_id",result.pip_id)
    } else {
      localStorage.setItem("pip_id","null")
    }
  };

  //--------------------------------------------------------------------------

  const handleVendorChange = (e) => {
    if (e == "Add New Vendor") {
      setAddNewFlag(true);
      setVendor("");
    } else {
      findPipId(e)
      setSelectedCategory("");
      setSelectedLineitem("");
      dispatch(AccountMapping(fetchAccessTokenSucess.access_token, e));
      setVendor(e);
      handleUserInputs(invoiceCount, "vendor", e);
      handleUserInputs(invoiceCount, "ven_curr_code", homeCurrency);
    }
  };

  const handleVendorChangeInput = (e) => {
    setVendor(e);
    handleUserInputs(invoiceCount, "vendor", e);
  };
  const handleInvoiceFileInput = (e) => {
    const data = e.target.files[0];
    if (invoiceCount === 1) {
      setInvoice([
        {
          data,
        },
      ]);
    }
    if (invoiceCount > 1) {
      setInvoice([
        ...invoice,
        {
          data,
        },
      ]);
    }

    handleUserInputs(invoiceCount, "file", e.target.files[0].name);
  };

  useEffect(() => {
    checkForTheInvoieCount();
  }, [invoice]);

  const checkForTheInvoieCount = () => {
    if (invoice.length > inputFields.length) {
      invoice.splice(invoice.length - 2, 1);
    }
  };

  //---------------------- Sort Alphabetically -  -------------------------

  function sortAlphabetically(arr) {
    return arr.sort(function (a, b) {
      return a.localeCompare(b);
    });
  }

  //-------------------------------------------------------------

  const handleCategoryChange = (e) => {
    setSelectedCategory(e);
    const type = accountType.filter((item) => item.acc_type == e);
    if (type.length > 0) {
      setLineitemArray(sortAlphabetically(type[0].acc_names));
      handleUserInputs(invoiceCount, "category1", e);
    }
  };

  const handleLineItemChange = (e) => {
    if (e == "Add New Line Item") {
      setNewLineItemFlag(true);
      setSelectedLineitem("");
    } else {
      setSelectedLineitem(e);
      handleUserInputs(invoiceCount, "category2", e);
    }
  };

  //delete invoice

  const handleDeleteInvoice = (id, file, index) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    setVendor("");
    setSelectedCategory("");
    setSelectedLineitem("");

    //------- Delete corresponding invoice

    const invoiceData = [...invoice];
    const filteredItems = invoiceData.filter((item) => item.data.name != file);
    setInvoice(filteredItems);
  };

  const handleAddNewAfterDelete = () => {
    //-------- Create a New Object for New Entries
    if (inputFields.length > 0) {
      setInputFields([
        ...inputFields,
        {
          id: invoiceCount,
          vendor: "",
          category1: "",
          category2: "",
          file: "",
          ven_curr_code: "",
        },
      ]);
    }
  };

  useEffect(() => {
    updateArray();
  }, [inputFields]);

  const updateArray = () => {
    if (inputFields.length === 0) {
      setInputFields([
        ...inputFields,
        {
          id: invoiceCount + 1,
          vendor: "",
          category1: "",
          category2: "",
          file: "",
          ven_curr_code: "",
        },
      ]);
      setInvoiceCount(invoiceCount + 1);
    }
  };

  useEffect(() => {
    setCount(count + 1);
    setSelectedCategory(selectedCategory);
    setSelectedLineitem(selectedLineItem);
  }, [selectedCategory, selectedLineItem]);

  //------------------------------- Batch Upload Ends ----------------------------------------------------

  //--------------------------- Validation for Upload Button ---------------------------------------------

  const [uploadButtonEnable, setUploadButtonEnable] = React.useState(false);

  useEffect(() => {
    checkAlluSerInput();
  }, [vendor, invoice, selectedCategory, selectedLineItem]);

  const checkAlluSerInput = () => {
    if (vendor && invoice && selectedCategory && selectedLineItem) {
      setUploadButtonEnable(true);
    } else {
      setUploadButtonEnable(false);
    }
  };

  // Function to get pip_id

  function extractPipIdByName(data, name) {
    if (data && name) {
      const foundItem = data.find((item) => item.name === name);
      if (foundItem) {
        return foundItem.pip_id;
      }
      return null;
    }
  }

  const [pipId, setPipId] = React.useState(null);

  useEffect(() => {
    updatePipId();
  }, [vendor]);

  const updatePipId = () => {
    const pipId = extractPipIdByName(
      customerListSucess ? customerListSucess.vendors : [],
      vendor
    );
    setPipId(pipId);
  };
  console.log("pipId", pipId);

  //---------------------------  Upload Button API Call -----------------------

  const handleUploadInvoice = () => {
    dispatch(SaveApDataClarData())
    dispatch(TrainingClarData())
    localStorage.setItem("SelectedVendor", vendor);
    if (inputFields.length === 1) {
      dispatch(InvoiceExtractionViewDialog(!invoiceExtractionViewDialog));
      // dispatch(InvoiceParsingDialogbox(!InvoiceParsingDialogValue));
      dispatch(
        InvoiceParsingPdfSingle(
          invoice,
          fetchAccessTokenSucess.access_token,
          inputFields,
          fetchAccessTokenSucess.org_id,
          GSTIN,
          pipId
        )
      );
    } else {
      dispatch(InvoiceParsingDialogbox(!InvoiceParsingDialogValue));
      //dispatch(InvoiceParsingDialogbox(!InvoiceParsingDialogValue));
      dispatch(
        InvoiceParsingPdf(
          invoice,
          fetchAccessTokenSucess.access_token,
          inputFields,
          fetchAccessTokenSucess.org_id
        )
      );
    }
  };

  //UI Validation if API Failed - Should show Illustration

  const customerListFail = useSelector(
    (state) => state.AccountsPayableReducers.customerListFail
  );

  const accountListFail = useSelector(
    (state) => state.AccountsPayableReducers.accountListFail
  );

  //--single function that replaces underscores with spaces and capitalizes the first letter ---

  function replaceAndCapitalize(str) {
    return str.replace(/_/g, " ").replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  // Invoice Extraction View Dialog

  const invoiceExtractionViewDialog = useSelector(
    (state) => state.AccountsPayableReducers.invoiceExtractionViewDialog
  );

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 4, height: "100vh" }}>
        <Grid
          container
          spacing={0}
          sx={{
            boxShadow: " 0px 30px 60px rgb(39 39 71 / 20%)",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mt: 2,
            }}
          >
            <Typography
              sx={{
                p: 3,
                pb: 1,
                pt: 0,
                fontSize: "16px",
                fontWeight: 600,
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Accounts{" "}
              <span
                style={{
                  color: "#0093FB",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                }}
              >
                Payable
              </span>
            </Typography>
            <Divider sx={{ p: 2, pt: 0, ml: 2, mr: 2, pb: 0 }} />
          </Grid>
          {accountingSoftware == "skip" ? (
            <>
              <Grid item xs={12}>
                <Box sx={{ width: "100%", pl: 2, pr: 2 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Track Invoice"
                        {...a11yProps(0)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <TrackInvoice />
                  </TabPanel>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Box sx={{ width: "100%", pl: 2, pr: 2 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Upload Invoice"
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Pending Invoice"
                        {...a11yProps(1)}
                      />
                      <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Track Invoice"
                        {...a11yProps(2)}
                      />
                      <Tab
                        sx={{ textTransform: "capitalize" }}
                        label="Vendor Keys"
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <>
                      <Grid item xs={12}>
                        {!customerListFail && !accountListFail ? (
                          <>
                            <Alert
                              sx={{
                                ml: 3,
                                mr: 3,
                                mb: 0,
                                fontFamily: "'Roboto', sans-serif",
                              }}
                              severity="info"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "'Roboto', sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Kindly ensure invoice(s) is ready for upload
                              </Typography>
                            </Alert>
                          </>
                        ) : null}

                        <Grid container>
                          {/* <Grid item xs={12} sx={{ p: 3, pb: 1, pt: 1, pl: 4.3 }}>
                        <IconBreadcrumbs />
                      </Grid> */}
                        </Grid>
                        {customerListLoading || accountListLoading ? (
                          <>
                            <Container maxWidth="lg">
                              <Hidden mdDown>
                                <Grid container sx={{ mt: 2 }}>
                                  <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      sx={{ borderRadius: 2 }}
                                      // width={260}
                                      height={60}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      sx={{ borderRadius: 2 }}
                                      // width={260}
                                      height={60}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      sx={{ borderRadius: 2 }}
                                      // width={260}
                                      height={60}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      sx={{ borderRadius: 2 }}
                                      // width={260}
                                      height={60}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sx={{ p: 1, mt: 1 }}>
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      sx={{ borderRadius: 2 }}
                                      // width={1030}
                                      height={300}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      mb: 5,
                                      mt: 2,
                                    }}
                                  >
                                    <Skeleton
                                      variant="rectangular"
                                      animation="wave"
                                      sx={{ borderRadius: 2 }}
                                      width={410}
                                      height={40}
                                    />
                                  </Grid>
                                </Grid>
                              </Hidden>
                            </Container>
                          </>
                        ) : (
                          <>
                            {customerListFail || accountListFail ? (
                              <>
                                <Grid container>
                                  <Grid item xs={12} sx={{ pt: 0 }}>
                                    {customerListFail ? (
                                      <>
                                        <Alert severity="error">
                                          <Typography
                                            sx={{
                                              fontFamily:
                                                "'Roboto', sans-serif",
                                              fontSize: 14,
                                            }}
                                          >
                                            Sorry.. Failed to fetch the customer
                                            details from accounting software!
                                          </Typography>
                                        </Alert>
                                      </>
                                    ) : (
                                      <>
                                        <Alert severity="error">
                                          <Typography
                                            sx={{
                                              fontFamily:
                                                "'Roboto', sans-serif",
                                              fontSize: 14,
                                            }}
                                          >
                                            Sorry.. Failed to fetch the account
                                            details from accounting software!
                                          </Typography>
                                        </Alert>
                                      </>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      pt: 8,
                                      pb: 8,
                                    }}
                                  >
                                    <img src={database} />
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Container maxWidth="lg">
                                  <Grid container sx={{ mt: 2 }}>
                                    {Object.keys(customerListSucess).length >
                                      0 &&
                                    customerListUpdated &&
                                    addNewFlag === false ? (
                                      <>
                                        <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                          <TextField
                                            select
                                            fullWidth
                                            id="Vendor"
                                            label="Select Vendor"
                                            variant="outlined"
                                            value={vendor}
                                            onChange={(event) =>
                                              handleVendorChange(
                                                event.target.value
                                              )
                                            }
                                          >
                                            {[
                                              ["Add New Vendor"],
                                              ...customerListUpdated,
                                            ].map((option, key) => (
                                              <MenuItem
                                                key={key}
                                                value={option}
                                                sx={{
                                                  fontFamily:
                                                    "'Roboto', sans-serif",
                                                  color:
                                                    key == 0 ? "#FF0000" : "",
                                                  "&:hover": {
                                                    borderLeft:
                                                      "5px solid #008ffc",
                                                    borderRadius: 1,
                                                    fontWeight: 600,
                                                  },
                                                }}
                                              >
                                                {option}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                          <TextField
                                            fullWidth
                                            id="Vendor"
                                            label="Vendor Name"
                                            variant="outlined"
                                            value={vendor}
                                            helperText={
                                              customerListUpdated
                                                ? "Click for list of Vendors"
                                                : ""
                                            }
                                            FormHelperTextProps={{
                                              onClick: () =>
                                                setAddNewFlag(false),
                                            }}
                                            onChange={(event) =>
                                              handleVendorChangeInput(
                                                event.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                      </>
                                    )}
                                    {accountType ? (
                                      <>
                                        <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                          <TextField
                                            select
                                            fullWidth
                                            id="Category"
                                            label="Select Category"
                                            variant="outlined"
                                            value={selectedCategory}
                                            onChange={(event) =>
                                              handleCategoryChange(
                                                event.target.value
                                              )
                                            }
                                          >
                                            {accountTypeArray.map(
                                              (option, key) => (
                                                <MenuItem
                                                  key={key}
                                                  keepMounted
                                                  value={option}
                                                  sx={{
                                                    fontFamily:
                                                      "'Roboto', sans-serif",
                                                    "&:hover": {
                                                      borderLeft:
                                                        "5px solid #008ffc",
                                                      borderRadius: 1,
                                                      fontWeight: 600,
                                                    },
                                                  }}
                                                >
                                                  {replaceAndCapitalize(option)}
                                                </MenuItem>
                                              )
                                            )}
                                          </TextField>
                                        </Grid>
                                      </>
                                    ) : null}
                                    {lineitemArray && !addNewLineItemFlag ? (
                                      <>
                                        <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                          <TextField
                                            select
                                            fullWidth
                                            id="Sub-Category"
                                            label="Line item"
                                            variant="outlined"
                                            disabled={!selectedCategory}
                                            value={selectedLineItem}
                                            onChange={(event) =>
                                              handleLineItemChange(
                                                event.target.value
                                              )
                                            }
                                          >
                                            {[
                                              ["Add New Line Item"],
                                              ...lineitemArray,
                                            ].map((option, key) => (
                                              <MenuItem
                                                keepMounted
                                                sx={{
                                                  fontFamily:
                                                    "'Roboto', sans-serif",
                                                  color:
                                                    key == 0 ? "#FF0000" : "",
                                                  "&:hover": {
                                                    borderLeft:
                                                      "5px solid #008ffc",
                                                    borderRadius: 1,
                                                    fontWeight: 600,
                                                  },
                                                }}
                                                key={key}
                                                value={option}
                                              >
                                                {option}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                          <TextField
                                            fullWidth
                                            id="Sub-Category"
                                            label="Line item"
                                            variant="outlined"
                                            disabled={!selectedCategory}
                                            value={selectedLineItem}
                                            helperText="Click for list of Line Items"
                                            FormHelperTextProps={{
                                              onClick: () =>
                                                setNewLineItemFlag(false),
                                            }}
                                            onChange={(event) =>
                                              handleLineItemChange(
                                                event.target.value
                                              )
                                            }
                                          ></TextField>
                                        </Grid>
                                      </>
                                    )}

                                    <Grid item xs={12} md={3} sx={{ p: 1 }}>
                                      <Button
                                        fullWidth
                                        onClick={handleAddNewInvoice}
                                        startIcon={<AddIcon />}
                                        variant="outlined"
                                        sx={{
                                          textTransform: "capitalize",
                                          height: 55,
                                        }}
                                      >
                                        Add New Invoice
                                      </Button>
                                    </Grid>

                                    {addNewFlag === true &&
                                    multiCurrencyEnabledFlag == true ? (
                                      <>
                                        <Grid
                                          item
                                          xs={12}
                                          md={3}
                                          sx={{
                                            pl: { xs: 1, md: 1 },
                                            pr: { xs: 1, md: 1 },
                                          }}
                                        >
                                          <TextField
                                            fullWidth
                                            id="userselectedcurrency"
                                            select
                                            value={userSelectedCurrency}
                                            label="Please choose the currency"
                                            onChange={(event) =>
                                              handleCurrencyChange(
                                                event.target.value
                                              )
                                            }
                                          >
                                            {currencyDataUpdated.map(
                                              (option, key) => (
                                                <MenuItem
                                                  key={key}
                                                  value={option.code}
                                                  sx={{
                                                    fontFamily:
                                                      "'Roboto', sans-serif",
                                                    "&:hover": {
                                                      borderLeft:
                                                        "5px solid #008ffc",
                                                      borderRadius: 1,
                                                      fontWeight: 600,
                                                    },
                                                  }}
                                                >
                                                  {option.name} {"-"}{" "}
                                                  {option.code}
                                                </MenuItem>
                                              )
                                            )}
                                          </TextField>
                                        </Grid>
                                        {accountingSoftareType == "zoho" ? (
                                          <>
                                            <Grid
                                              item
                                              xs={12}
                                              md={3}
                                              sx={{
                                                pl: { xs: 1, md: 1 },
                                                pr: { xs: 1, md: 1 },
                                              }}
                                            >
                                              <TextField
                                                error={GSTINError}
                                                id="gstin"
                                                name="gstin"
                                                fullWidth
                                                sx={{ mt: { xs: 1, md: 0 } }}
                                                value={GSTIN}
                                                onChange={(e) =>
                                                  handleGSTIN(e.target.value)
                                                }
                                                label="GSTIN Number"
                                                helperText={
                                                  GSTINError
                                                    ? "Invalid GSTIN Number."
                                                    : ""
                                                }
                                              />
                                            </Grid>
                                          </>
                                        ) : null}
                                      </>
                                    ) : null}

                                    <Grid
                                      item
                                      xs={12}
                                      sx={{
                                        border: {
                                          xs: "none",
                                          sm: "none",
                                          md: "1px solid #C5C5C5",
                                        },
                                        display: "flex",
                                        borderRadius: 2,
                                        flexDirection: "column",
                                        mt: 2,
                                        ml: { xs: 0, sm: 2, lg: 1 },
                                        mr: { xs: 0, sm: 2, lg: 1 },
                                        p: 2,
                                        mb: 2,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <UploadIllustration />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "30px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <input
                                          type="file"
                                          name="invoice"
                                          multiple
                                          onChange={handleInvoiceFileInput}
                                        ></input>
                                      </div>
                                    </Grid>
                                    {invoiceCount > 1 ? (
                                      <>
                                        <Grid item xs={12}>
                                          <>
                                            <Typography
                                              sx={{
                                                textAlign: "center",
                                                fontWeight: 600,
                                                pt: 1,
                                                pb: 1,
                                              }}
                                            >
                                              {" "}
                                              Invoice Details
                                            </Typography>
                                            <TableContainer>
                                              <Table
                                                sx={{ minWidth: 650 }}
                                                aria-label="simple table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell
                                                      sx={{
                                                        fontFamily:
                                                          "'Roboto', sans-serif",
                                                        fontWeight: 600,
                                                      }}
                                                      align="center"
                                                    >
                                                      #
                                                    </TableCell>
                                                    <TableCell
                                                      sx={{
                                                        fontFamily:
                                                          "'Roboto', sans-serif",
                                                        fontWeight: 600,
                                                      }}
                                                      align="center"
                                                    >
                                                      Vendor Name
                                                    </TableCell>
                                                    <TableCell
                                                      sx={{
                                                        fontFamily:
                                                          "'Roboto', sans-serif",
                                                        fontWeight: 600,
                                                      }}
                                                      align="center"
                                                    >
                                                      Category
                                                    </TableCell>
                                                    <TableCell
                                                      sx={{
                                                        fontFamily:
                                                          "'Roboto', sans-serif",
                                                        fontWeight: 600,
                                                      }}
                                                      align="center"
                                                    >
                                                      Line item
                                                    </TableCell>
                                                    <TableCell
                                                      sx={{
                                                        fontFamily:
                                                          "'Roboto', sans-serif",
                                                        fontWeight: 600,
                                                      }}
                                                      align="center"
                                                    >
                                                      Invoice File
                                                    </TableCell>
                                                    <TableCell
                                                      sx={{
                                                        fontFamily:
                                                          "'Roboto', sans-serif",
                                                        fontWeight: 600,
                                                      }}
                                                      align="center"
                                                    >
                                                      Action
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <>
                                                  <TableBody>
                                                    {inputFields.map(
                                                      (row, index) => (
                                                        <TableRow
                                                          key={index}
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              { border: 0 },
                                                          }}
                                                        >
                                                          <TableCell align="center">
                                                            {index + 1}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.vendor}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.category1}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.category2}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.file}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            <Tooltip title="Delete Invoice">
                                                              <IconButton
                                                                onClick={() =>
                                                                  handleDeleteInvoice(
                                                                    row.id,
                                                                    row.file,
                                                                    index
                                                                  )
                                                                }
                                                                edge="start"
                                                                color="inherit"
                                                                aria-label="menu"
                                                                sx={{
                                                                  ml: 0,
                                                                  color:
                                                                    "#FF0000",
                                                                }}
                                                              >
                                                                <DeleteIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </>
                                              </Table>
                                            </TableContainer>

                                            <Divider />
                                          </>
                                        </Grid>
                                      </>
                                    ) : null}

                                    <Grid item xs={12} sx={{ pt: 2 }}>
                                      <Container maxWidth="xs">
                                        <Grid item xs={12}>
                                          <Button
                                            fullWidth
                                            disabled={inputFields.length === 0}
                                            endIcon={<BackupIcon />}
                                            sx={{
                                              textTransform: "capitalize",
                                              padding: 1,
                                              background: "#0093FB",
                                              fontFamily:
                                                "'Roboto', sans-serif",
                                              "&:hover": {
                                                background: "#0093FB",
                                              },
                                            }}
                                            variant="contained"
                                            onClick={handleUploadInvoice}
                                          >
                                            Save Details
                                          </Button>
                                        </Grid>
                                      </Container>
                                    </Grid>
                                  </Grid>
                                </Container>
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <PendingInvoiceIndex />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <TrackInvoice />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <VendorKeys />
                  </TabPanel>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      {InvoiceParsingDialogValue ? <InvoiceParsingDialog /> : null}
      {invoiceExtractionViewDialog ? (
        <InvoiceExtractionView vendor={vendor} />
      ) : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={accountingMappingLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fetchAccessTokenLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Index;
