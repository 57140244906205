const TYPES = {
  //---- Budget Upload------
  BUDGET_UPLOAD_LOADING: "BUDGET_UPLOAD_LOADING",
  BUDGET_UPLOAD_SUCCESS: "BUDGET_UPLOAD_SUCCESS",
  BUDGET_UPLOAD_FAIL: "BUDGET_UPLOAD_LIST_FAIL",
  BUDGET_UPLOAD_CLEAR_DATA: "BUDGET_UPLOAD_CLEAR_DATA",

  //---- Budget Dialog ------
  BUGET_DIALOG: "BUDGET_DIALOG",

  //---- SYNC Actuals ------

  SYNC_ACTUALS_LOADING: "SYNC_ACTUALS_LOADING",
  SYNC_ACTUALS_SUCCESS: "SYNC_ACTUALS_SUCCESS",
  SYNC_ACTUALS_FAIL: "SYNC_ACTUALS_LIST_FAIL",
  SYNC_ACTUALS_CLEAR_DATA: "SYNC_ACTUALS_CLEAR_DATA",

  //---- budget_approval_count ------

  BUDGET_APPROVAL_COUNT_LOADING: "BUDGET_APPROVAL_COUNT_LOADING",
  BUDGET_APPROVAL_COUNT_SUCCESS: "BUDGET_APPROVAL_COUNT_SUCCESS",
  BUDGET_APPROVAL_COUNT_FAIL: "BUDGET_APPROVAL_COUNT_FAIL",
  BUDGET_APPROVAL_COUNT_CLEAR_DATA: "BUDGET_APPROVAL_COUNT_CLEAR_DATA",

  //---- budget_approval ------

  BUDGET_APPROVAL_LOADING: "BUDGET_APPROVAL_LOADING",
  BUDGET_APPROVAL_SUCCESS: "BUDGET_APPROVAL_SUCCESS",
  BUDGET_APPROVAL_FAIL: "BUDGET_APPROVAL_FAIL",
  BUDGET_APPROVAL_CLEAR_DATA: "BUDGET_APPROVAL_CLEAR_DATA",

  //---- budget_Decline ------

  BUDGET_DECLINE_LOADING: "BUDGET_DECLINE_LOADING",
  BUDGET_DECLINE_SUCCESS: "BUDGET_DECLINE_SUCCESS",
  BUDGET_DECLINE_FAIL: "BUDGET_DECLINE_FAIL",
  BUDGET_DECLINE_CLEAR_DATA: "BUDGET_DECLINE_CLEAR_DATA",

  //---- budget_approval_DIALOG ------

  BUDGET_APPROVAL_DIALOG: "BUDGET_APPROVAL_DIALOG",

  //---- budget_Decline_DIALOG ------

  BUDGET_DECLINE_DIALOG: "BUDGET_DECLINE_DIALOG",
};
export default TYPES;
