import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import {
  BudgetApproval,
  BudgetApprovalDialog,
  BudgetDecline,
  BudgetDeclineDialog,
} from "../FinancialReporting/FinancialReportingActions/FinancialReportingActions";
import ApprovalDialog from "./ApprovalDialog";
import DeclineDialog from "./DeclineDialog";

export default function BudgetReview() {
  const dispatch = useDispatch();

  const budgetApprovalCountSucessResponse = useSelector(
    (state) =>
      state.FinancialReportingReducers.budgetApprovalCountSucessResponse
  );

  const budgetApprovalCountLoading = useSelector(
    (state) => state.FinancialReportingReducers.budgetApprovalCountLoading
  );

  const budgetApprovalCountFail = useSelector(
    (state) => state.FinancialReportingReducers.budgetApprovalCountFail
  );

  const budgetApprovalDialog = useSelector(
    (state) => state.FinancialReportingReducers.budgetApprovalDialog
  );

  const budgetDeclineDialog = useSelector(
    (state) => state.FinancialReportingReducers.budgetDeclineDialog
  );

  console.log("budgetApprovalDialog", budgetApprovalDialog);

  const handleApprove = (id) => {
    dispatch(BudgetApprovalDialog(!budgetApprovalDialog));
    dispatch(BudgetApproval(id));
  };
  const handleDecline = (id) => {
    dispatch(BudgetDeclineDialog(!budgetDeclineDialog));
    dispatch(BudgetDecline(id));
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 900 }}>#</TableCell>
              <TableCell sx={{ fontWeight: 900 }} align="left">
                File Name
              </TableCell>
              <TableCell sx={{ fontWeight: 900 }} align="left">
                Uploaded By
              </TableCell>
              <TableCell sx={{ fontWeight: 900 }} align="left">
                Version
              </TableCell>
              <TableCell sx={{ fontWeight: 900 }} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {budgetApprovalCountSucessResponse.length ? (
            <>
              <TableBody>
                {budgetApprovalCountSucessResponse.map((row, index) => (
                  <TableRow
                    key={row.index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.file_name}</TableCell>
                    <TableCell align="left">{row.uploaded_by}</TableCell>
                    <TableCell align="left">{row.version}</TableCell>
                    <TableCell align="center">
                      <>
                        <Button
                          variant="contained"
                          onClick={() => handleApprove(row.id)}
                          sx={{
                            textTransform: "capitalize",
                            background: "#32CD32",
                            "&:hover": {
                              background: "#32CD32",
                            },
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          onClick={() => handleDecline(row.id)}
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            ml: 2,
                            background: "#FF0000",
                            "&:hover": {
                              background: "#FF0000",
                            },
                          }}
                        >
                          Decline
                        </Button>
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : null}
        </Table>
      </TableContainer>
      {budgetApprovalDialog ? <ApprovalDialog /> : null}
      {budgetDeclineDialog ? <DeclineDialog /> : null}
    </>
  );
}
