import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import {
    BudgetDeclineDialog,
  BudgetApprovalCount,
} from "../FinancialReporting/FinancialReportingActions/FinancialReportingActions";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

export default function DeclineDialog() {
  const dispatch = useDispatch();

  const budgetDeclineDialog = useSelector(
    (state) => state.FinancialReportingReducers.budgetDeclineDialog
  );

  const handleClose = () => {
    dispatch(BudgetDeclineDialog(!budgetDeclineDialog));
    dispatch(BudgetApprovalCount());
  };

  const budgetDeclineLoading = useSelector(
    (state) => state.FinancialReportingReducers.budgetDeclineLoading
  );

  const budgetDeclineSucess = useSelector(
    (state) => state.FinancialReportingReducers.budgetDeclineSucess
  );

  const budgetDeclineFail = useSelector(
    (state) => state.FinancialReportingReducers.budgetDeclineFail
  );

  return (
    <div>
      <Dialog
       fullWidth={true}
       maxWidth={"sm"}
        open={budgetDeclineDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Decline Budget"}</DialogTitle>
        {budgetDeclineLoading ? (
          <DialogContent sx={{display:"flex",justifyContent:"center"}}>
            <CircularProgress />
          </DialogContent>
        ) : null}

        {budgetDeclineSucess ? (
          <DialogContent>
            <Alert severity="success">budget removed successfully!</Alert>
          </DialogContent>
        ) : null}

        {budgetDeclineFail ? (
          <DialogContent>
            <Alert severity="error">
              Sorry! Failed to process the request.
            </Alert>
          </DialogContent>
        ) : null}

        <DialogActions>
          <Button sx={{textTransform:"capitalize"}} onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
