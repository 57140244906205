import { combineReducers } from "redux";
import AccountsPayableReducers from "../../Pages/AccountsPayable/AccountsPayableReducers/AccountsPayableReducers";
import LandingPageReducers from "../../Pages/LandingPage/LandingPageReducers/LandingPageReducers";
import DashboardReducers from '../../Pages/Dashboard/DashboardReducers/DashboardReducers'
import AccountReceivableReducers from '../../Pages/AccountReceivable/AccountReceivableReducers/AccountReceivableReducers'
import FinancialReportingReducers from '../../Pages/FinancialReporting/FinancialReportingReducers/FinancialReportingReducers'
const rootReducer = combineReducers({
  AccountsPayableReducers: AccountsPayableReducers,
  LandingPageReducers:LandingPageReducers,
  DashboardReducers:DashboardReducers,
  AccountReceivableReducers:AccountReceivableReducers,
  FinancialReportingReducers:FinancialReportingReducers
});
export default rootReducer;
