import React from "react";
import Grid from "@mui/material/Grid";
import NotFound from "../Assets/404.jpg";
import CorestratLogo from "../Assets/Corestrat lcon.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotFound404() {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate("/");
    }
  return (
    <Grid container>
      <Grid item xs={12}>
        <img
          src={CorestratLogo}
          style={{
            height: "50px",
            padding: 3,
            marginTop: 10,
            marginLeft: 3,
            pointerEvents: "none",
          }}
          alt="Corestart_Logo"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height: "600px",
          width: "1000px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={NotFound}
          style={{
            marginTop: 0,
            maxWidth: "100%",
            maxHeight: "100%",
            marginLeft: 1,
            pointerEvents: "none",
          }}
          alt="404_Illustration"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button variant="contained" onClick={handleButtonClick} sx={{textTransform:"none"}}>Back to Homepage</Button>
      </Grid>
    </Grid>
  );
}
