import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";
import {
  PendingInvoiceList,
  InvoiceEditDialog,
  ViewInvoiceDialog,
} from "../AccountsPayableActions/AccountsPayableActions";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import EditInvoice from "./EditInvoice";
import noData from "../../../Assets/undraw_No_data_re_kwbl-removebg-preview.png";
import IconBreadcrumbsPendingInvoice from "./IconBreadcrumbsPendingInvoice";
import ViewInvoice from "./ViewInvoice";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DeletePendingInvoice,
  DeletePendingInvoiceDialog,
} from "../AccountsPayableActions/AccountsPayableActions";
import DeletePendinginvoice from "./DeletePendingInvoice";

export default function PendingInvoice() {
  const dispatch = useDispatch();

  
  //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  //Pending Invoice List
  useEffect(() => {
    dispatch(PendingInvoiceList());
  }, []);

  //Pending Invoice List
  const pendingInvoiceListSucess = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceListSucess
  );

  useEffect(() => {
    handlePendingInvoice();
  }, [pendingInvoiceListSucess]);

  var listOfPendingInvoice = [];

  const handlePendingInvoice = () => {
    // var listOfPendingInvoice = pendingInvoiceListSucess
    //   ? pendingInvoiceListSucess.all_pen_invs
    //   : [];
  };

  //Pending Invoice List --Loading Status
  const pendingInvoiceListLoading = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceListLoading
  );

  //--------------------------------------------------------------------------------

  function sortBasedOnUploadDate(arr) {
    if(arr) {
      return arr.sort(
        (a, b) => new Date(b.UploadedDate) - new Date(a.UploadedDate)
      );
    }
  }

  //--------------------------------------------------------------------------------

  var listOfPendingInvoice = pendingInvoiceListSucess
    ? sortBasedOnUploadDate(pendingInvoiceListSucess.all_pen_invs)
    : [];

  //--------------------------------------------------------------------------------

  //Pending Invoice List -- Loading Status
  const invoiceEditDialog = useSelector(
    (state) => state.AccountsPayableReducers.invoiceEditDialog
  );
  const [editInviceIndex, setEditInvoiceIndex] = React.useState("");
  const handleEdit = (index) => {
    setEditInvoiceIndex(index);
    dispatch(InvoiceEditDialog(!invoiceEditDialog));
  };

  //View Invoice Dialog

  const viewInvoiceDialog = useSelector(
    (state) => state.AccountsPayableReducers.viewInvoiceDialog
  );

  const handleViewInvoice = (file_url) => {
    window.open(file_url);
  };

  //-------------------------------- Delete Invoice -------------------------------

  const deletePendingInvoiceDialog = useSelector(
    (state) => state.AccountsPayableReducers.deletePendingInvoiceDialog
  );

  const handleDeleteInvoice = (id) => {
    dispatch(DeletePendingInvoiceDialog(!deletePendingInvoiceDialog));
    dispatch(DeletePendingInvoice(id));
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {pendingInvoiceListLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <CircularProgressWithLabel />
              </div>
            ) : null}
          </Grid>
          {listOfPendingInvoice && listOfPendingInvoice.length ? (
            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid #C5C5C5",
                borderRadius: 2,
              }}
            >
              <TableContainer sx={{ height: "65vh" }}>
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        #
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Vendor Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Category
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Line item
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Invoice Number
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Billing Date
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Net Terms
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Tax
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: 600,
                        }}
                        align="center"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <>
                    <TableBody>
                      {listOfPendingInvoice.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row.VendorName}</TableCell>
                          <TableCell align="center">{row.Category1}</TableCell>
                          <TableCell align="center">{row.Category2}</TableCell>
                          {row.InvoiceNumber === "" ? (
                            <TableCell
                              align="center"
                              sx={{
                                fontFamily: "'Roboto', sans-serif",
                                color:"#FF0000",
                                fontWeight: 600,
                              }}
                            >
                              Data Missing
                            </TableCell>
                          ) : (
                            <TableCell
                              align="center"
                              sx={{
                                fontFamily: "'Roboto', sans-serif",
                              }}
                            >
                              {row.InvoiceNumber}
                            </TableCell>
                          )}
                          {row.InvoiceTotal === "" ? (
                            <TableCell
                              align="center"
                              sx={{
                                fontFamily: "'Roboto', sans-serif",
                                color:"#FF0000",
                                fontWeight: 600,
                              }}
                            >
                              Data Missing
                            </TableCell>
                          ) : (
                            <TableCell
                              align="center"
                              sx={{
                                fontFamily: "'Roboto', sans-serif",
                              }}
                            >
                              {row.InvoiceTotal}
                            </TableCell>
                          )}
                          {row.BillingDate === "" ? (
                            <TableCell
                              align="center"
                              sx={{
                                fontFamily: "'Roboto', sans-serif",
                                color:"#FF0000",
                                fontWeight: 600,
                              }}
                            >
                              Data Missing
                            </TableCell>
                          ) : (
                            <TableCell
                              align="center"
                              sx={{
                                fontFamily: "'Roboto', sans-serif",
                              }}
                            >
                              {row.BillingDate}
                            </TableCell>
                          )}
                          {row.NetTerms === "" ? (
                            <TableCell
                              align="center"
                              sx={{
                                fontFamily: "'Roboto', sans-serif",
                                color:"#FF0000",
                                fontWeight: 600,
                              }}
                            >
                              Data Missing
                            </TableCell>
                          ) : (
                            <TableCell
                              align="center"
                              sx={{
                                fontFamily: "'Roboto', sans-serif",
                              }}
                            >
                              {row.NetTerms}
                            </TableCell>
                          )}
                          {row.InvoiceTotal != "" &&
                          row.InvoiceNumber != "" &&
                          row.BillingDate != "" ? (
                            <>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "'Roboto', sans-serif",
                                  color:"#FF0000",
                                  fontWeight:600
                                }}
                              >
                                {row.tax_amount}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell
                                align="center"
                                sx={{
                                  fontFamily: "'Roboto', sans-serif",
                                }}
                              >
                                {row.tax_amount}
                              </TableCell>
                            </>
                          )}

                          <TableCell align="center">
                            <Tooltip title="Edit Details">
                              <IconButton
                                onClick={() => handleEdit(index)}
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ ml: 0, color: "#0093FB" }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Download Invoice">
                              <IconButton
                                disabled={!row.file_url}
                                onClick={() => handleViewInvoice(row.file_url)}
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ ml: 0, color: "#4CBB17" }}
                              >
                                <ArrowDownwardIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Invoice">
                              <IconButton
                                onClick={() => handleDeleteInvoice(row.Id)}
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ ml: 0, color: "#FF0000" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img src={noData} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      {invoiceEditDialog ? <EditInvoice index={editInviceIndex} /> : null}
      {viewInvoiceDialog ? <ViewInvoice index={editInviceIndex} /> : null}
      {deletePendingInvoiceDialog ? <DeletePendinginvoice /> : null}
    </>
  );
}
