import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import CorestartLogo from "../../Assets/CorestartLogo.png";
import { Divider, TextField, Typography } from "@mui/material";
import qb from "../../Assets/qb2.png";
import zoho from "../../Assets/zoho-modified.png";
import xero from "../../Assets/xero_logo-modified.png";
import {useSelector } from "react-redux";

function SelectAccountingSoftware() {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/");
  };

  //Sign In -> Redirect URL - QB
  const UrlFetchSucess = useSelector(
    (state) => state.LandingPageReducers.UrlFetchSucess
  );

  //Sign In -> Redirect URL - ZOHO
  const zohoUrlFetchSucess = useSelector(
    (state) => state.LandingPageReducers.zohoUrlFetchSucess
  );

  console.log("zohoUrlFetchSucess",zohoUrlFetchSucess)

  //Handle Select QB as Accounting Software

  const handleQBClick = () => {
    localStorage.setItem("AcoountingSoftware", "qb");
    window.location.href = UrlFetchSucess ? UrlFetchSucess.url : null;
  };

  //Handle Select QB as Accounting Software

  const handleZohoClick = () => {
    localStorage.setItem("AcoountingSoftware", "zoho");
    window.location.href = zohoUrlFetchSucess ? zohoUrlFetchSucess.url : null;
  };

  //Handle Select XERO as Accounting Software

  const handleXeroClick = () => {
    localStorage.setItem("AcoountingSoftware", "xero");
    window.location.href = 'https://asvinth-backend-xero-dev-eastus.azurewebsites.net/login';
  };

  //Handle Skip Accounting Software  -> Skip -> None of the accounting software is selected

  const handleSkip = () => {
    localStorage.setItem("AcoountingSoftware", "skip");
    navigate("/dashboard");
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ m: { xs: 3, md: 6, lg: 13 }, mt: { xs: 7 }, mb: { lg: 5 } }}
      >
        <motion.div
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <img
            src={CorestartLogo}
            width="200"
            height="33"
            style={{ cursor: "pointer" }}
            onClick={handleLogoClick}
            alt="Corestart Logo"
          />
        </motion.div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          backgroundColor: "#e5f6fd",
          borderRadius: "5px",
          m: { xs: 3, md: 6, lg: 25 },
          mt: { lg: 0 },
          mb: { lg: 1 },
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.7 }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography
            onClick={handleSkip}
              sx={{
                ml: 3,
                mr: 3,
                pt: 3,
                fontWeight: 900,
                cursor: "pointer",
                color: "#0093FB",
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
            >
              Skip
            </Typography>
          </div>
          <Typography
            sx={{ textAlign: "center", mt: 5, fontWeight: 900, fontSize: 20 }}
          >
            Finance Automation Platform - Accounting Software
          </Typography>
          <Divider sx={{ ml: 8, mr: 8, mt: 2 }} />
          <Grid container>
            <Grid
              item
              xs={12}
              onClick={handleXeroClick}
              sx={{
                border: "1px solid #D8D8D8",
                borderRadius: 1,
                mt: 4,
                ml: { xs: 4, lg: 34 },
                mr: { xs: 4, lg: 34 },
                height: "100%",
                width: "100%",
                p: 0.8,
                display: "flex",
                background: "#ffffff",
                justifyContent: "center",
                "&:hover": {
                  background: "#A3EBB1",
                  cursor: "pointer",
                },
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  marginLeft: "10px",
                  height: "6.5vh",
                  width: "8vw",
                }}
                src={xero}
              />
            </Grid>
            <Grid
              item
              xs={12}
              onClick={handleZohoClick}
              sx={{
                border: "1px solid #D8D8D8",
                borderRadius: 1,
                mt: 4,
                ml: { xs: 4, lg: 34 },
                mr: { xs: 4, lg: 34 },
                height: "100%",
                width: "100%",
                p: 0.8,
                background: "#ffffff",
                display: "flex",
                justifyContent: "center",
                "&:hover": {
                  background: "#A3EBB1",
                  cursor: "pointer",
                },
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  marginLeft: "10px",
                  height: "8vh",
                  width: "10vw",
                }}
                src={zoho}
              />
            </Grid>
            <Grid
              item
              xs={12}
              onClick={handleQBClick}
              sx={{
                border: "1px solid #D8D8D8",
                borderRadius: 1,
                mt: 4,
                ml: { xs: 4, lg: 34 },
                mr: { xs: 4, lg: 34 },
                mb: 6,
                height: "100%",
                width: "100%",
                background: "#ffffff",
                p: 0.8,
                display: "flex",
                justifyContent: "center",
                "&:hover": {
                  background: "#A3EBB1",
                  cursor: "pointer",
                },
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  marginLeft: "10px",
                }}
                src={qb}
              />
            </Grid>
          </Grid>
        </motion.div>
      </Grid>
    </Grid>
  );
}

export default SelectAccountingSoftware;
