import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { CustomerList } from "../AccountsPayableActions/AccountsPayableActions";
import { useSelector, useDispatch } from "react-redux";
import database from "../../../Assets/database.png";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  GetVendorKey,
  SaveVendorKey,
  SaveVendorKeyClearData
} from "../AccountsPayableActions/AccountsPayableActions";

function VendorKeys() {
  const dispatch = useDispatch();

  //Access Token
  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  useEffect(() => {
    dispatch(CustomerList(fetchAccessTokenSucess.access_token,fetchAccessTokenSucess.org_id));
    dispatch(GetVendorKey());
  }, []);

  //UI Validation if API Failed - Should show Illustration
  //QB Customer List
  const customerListSucess = useSelector(
    (state) => state.AccountsPayableReducers.customerListSucess
  );

  const customerListLoading = useSelector(
    (state) => state.AccountsPayableReducers.customerListLoading
  );

  const customerListFail = useSelector(
    (state) => state.AccountsPayableReducers.customerListFail
  );

  console.log("customerListFail",customerListFail)

  // API Response for Get Vendor Key Details

  const getVenorkeyLoading = useSelector(
    (state) => state.AccountsPayableReducers.getVenorkeyLoading
  );

  const getVenorkeySucess = useSelector(
    (state) => state.AccountsPayableReducers.getVenorkeySucess
  );

  const getVenorkeyFail = useSelector(
    (state) => state.AccountsPayableReducers.getVenorkeyFail
  );

  // API Response for Save Vendor Key Details

  const saveVenorkeyLoading = useSelector(
    (state) => state.AccountsPayableReducers.saveVenorkeyLoading
  );

  const saveVenorkeySucess = useSelector(
    (state) => state.AccountsPayableReducers.saveVenorkeySucess
  );

  const saveVenorkeyFail = useSelector(
    (state) => state.AccountsPayableReducers.saveVenorkeyFail
  );

  const saveVenorkeyStatus = useSelector(
    (state) => state.AccountsPayableReducers.saveVenorkeyStatus
  );

  useEffect(() => {
      getData();
  }, [saveVenorkeySucess]);

  const getData = () => {
    dispatch(GetVendorKey());
  }

  useEffect(() => {
    updateCustomerList();
  }, [customerListSucess]);

  useEffect(() => {
    updateVendorKeyList();
  }, [getVenorkeySucess]);

  const [customerListUpdated, setCustomerListUpdated] = React.useState([]);
  const [vendorKeyListUpdated, setVendorKeyListUpdated] = React.useState([]);
  const updateCustomerList = () => {
    setCustomerListUpdated(
      customerListSucess.vendors
        ? customerListSucess.vendors.map((item) => item.name)
        : []
    );
  };
  const updateVendorKeyList = () => {
    setVendorKeyListUpdated(getVenorkeySucess.keys_data);
  };

  //---------------------------------------------------------------
  const [vendor, setVendor] = React.useState("");
  const [selectedData, setSelectedData] = React.useState([]);

  useEffect(() => {
    updateInputs();
  }, [selectedData]);

  const updateInputs = () => {
  
    if (Object.keys(selectedData).length > 0) {
      setInvoiceAmountKey(selectedData[0].inv_amount_key)
      setInvoiceDateKey(selectedData[0].inv_date_key)
      setInvoiceNumberKey(selectedData[0].inv_number_key)
      setNetTermKey(selectedData[0].net_terms_key)  
      setDueDateKey(selectedData[0].due_date_key)
      setVatkey(selectedData[0].vat_amount_key)
      setID(selectedData[0].id);
    }
    else {
      setInvoiceAmountKey("")
      setInvoiceDateKey("")
      setInvoiceNumberKey("")
      setNetTermKey("")  
      setDueDateKey("")
      setVatkey("")
      setID(0);
    }
  };
  //------------------------------------------------------------------

  const handleVendorChange = (e) => {
    dispatch(SaveVendorKeyClearData())
    setVendor(e);
    let data = vendorKeyListUpdated.filter((item) => item.vendor_name == e);
   // console.log("data",data)
    setSelectedData(data);
  };

 // console.log("selectedData", selectedData);

  //User Inputs -------------------------------------------------------

  const [invoiceAmountKey, setInvoiceAmountKey] = React.useState(
   
  );
  const handleSetInvoiceAmountKey = (e) => {
    setInvoiceAmountKey(e);
  };

  const [invoiceDateKey, setInvoiceDateKey] = React.useState(
 
  );
  const handleSetInvoiceDateKey = (e) => {
    setInvoiceDateKey(e);
  };

  const [invoiceNumberKey, setInvoiceNumberKey] = React.useState(

  );
  const handleSetInvoiceNumberKey = (e) => {
    setInvoiceNumberKey(e);
  };

  const [netTermKey, setNetTermKey] = React.useState(
    
  );
  const handleSetNetTermKey = (e) => {
    setNetTermKey(e);
  };

  const [dueDateKey, setDueDateKey] = React.useState();
  const handleSetDueDateKey = (e) => {
    setDueDateKey(e);
  };

  const [vatKey, setVatkey] = React.useState();
  const handlesetVatkey = (e) => {
    setVatkey(e);
  };

  const [id, setID] = React.useState();

  const handlesetID = (e) => {
    setID(e);
  };

  //Save Button
 // console.log("id", id);
  const handleSave = () => {
    // let idValue;
    // if(id == "") {
    //   idValue = 0
    // }
    // else
    //  {
    //   idValue= id
    //  }
    dispatch(
      SaveVendorKey(
        fetchAccessTokenSucess.access_token,
        vendor,
        invoiceAmountKey,
        invoiceDateKey,
        invoiceNumberKey,
        netTermKey,
        dueDateKey,
        vatKey,
        id
      )
    );
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 0 }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sx={{
            mt: 0,
          }}
        >
          <Alert sx={{ ml: 0, mr: 0, mb: 0 }} severity="info">
            <Typography
              sx={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px" }}
            >
              {" "}
              Vendor keys in invoice
            </Typography>
          </Alert>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", pt: 5 }}
        >
          {customerListLoading || getVenorkeyLoading ? (
            <>
              <CircularProgressWithLabel />
            </>
          ) : null}
        </Grid>
        {Object.keys(customerListSucess).length > 0 && customerListUpdated ? (
          <>
            <Grid item xs={12} md={4} sx={{ p: 1, pl: 0 }}>
              <TextField
                select
                fullWidth
                id="Vendor"
                label="Select Vendor"
                variant="outlined"
                value={vendor}
                onChange={(event) => handleVendorChange(event.target.value)}
              >
                {customerListUpdated.map((option, key) => (
                  <MenuItem
                    key={key}
                    value={option}
                    sx={{
                      fontFamily: "'Roboto', sans-serif",
                      "&:hover": {
                        borderLeft: "5px solid #008ffc",
                        borderRadius: 1,
                        fontWeight: 600,
                      },
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        ) : null}
      </Grid>
      {customerListFail ? (
        <>
          <Grid item xs={12}>
            <Alert severity="error">
              <Typography
                sx={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: 14,
                }}
              >
                Sorry.. Failed to fetch the customer details from accounting
                software!
              </Typography>
            </Alert>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 8,
              pb: 8,
            }}
          >
            <img src={database} />
          </Grid>
        </>
      ) : null}
      {!customerListLoading && vendor ? (
        <>
          <Grid
            item
            xs={12}
            sx={{ border: "1px solid #C5C5C5", borderRadius: 2, p: 3, mt: 2 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Invoice Amount Key"
                  fullWidth
                  value={invoiceAmountKey}
                  name="InvoiceAmountKey"
                  id="InvoiceAmountKey"
                  onChange={(event) =>
                    handleSetInvoiceAmountKey(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Invoice Date Key"
                  fullWidth
                  value={invoiceDateKey}
                  name="InvoiceDateKey"
                  id="InvoiceDateKey"
                  onChange={(event) =>
                    handleSetInvoiceDateKey(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Invoice Number Key"
                  fullWidth
                  value={invoiceNumberKey}
                  name="InvoiceNumberKey"
                  id="InvoiceNumberKey"
                  onChange={(event) =>
                    handleSetInvoiceNumberKey(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Net Term Key"
                  fullWidth
                  value={netTermKey}
                  name="NetTermKey"
                  id="NetTermKey"
                  onChange={(event) => handleSetNetTermKey(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Due Date Key"
                  fullWidth
                  value={dueDateKey}
                  name="DueDateKey"
                  id="DueDateKey"
                  onChange={(event) => handleSetDueDateKey(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Tax Key"
                  fullWidth
                  value={vatKey}
                  name="VatKey"
                  id="VatKey"
                  onChange={(event) => handlesetVatkey(event.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          {saveVenorkeyLoading ? (
            <>
              <Grid container sx={{ pt: 3 }}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CircularProgressWithLabel />
                </Grid>
              </Grid>
            </>
          ) : null}
          {saveVenorkeyStatus ? (
            <>
              <Grid container sx={{ pt: 3 }}>
                <Grid item xs={12}>
                  <Alert severity="success">
                    <Typography
                      sx={{ fontSize: 14, fontFamily: "'Roboto', sans-serif" }}
                    >
                      Vendor key details updated
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            </>
          ) : null}
          {saveVenorkeyFail ? (
            <>
              <Grid container sx={{ pt: 3 }}>
                <Grid item xs={12}>
                  <Alert severity="error">
                    <Typography
                      sx={{ fontSize: 14, fontFamily: "'Roboto', sans-serif" }}
                    >
                      Sorry! Failed to save vendor key details
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            </>
          ) : null}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", pt: 3 }}
          >
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{ textTransform: "capitalize", pl: 2, pr: 2 }}
            >
              Save Keys
            </Button>
          </Grid>
        </>
      ) : null}
    </Container>
  );
}

export default VendorKeys;
