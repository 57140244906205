import TYPES from "../AccountsPayableTypes/AccountsPayableTypes";
const initialState = {
  InvoiceParsingLoading: null,
  InvoiceParsingSucess: [],
  InvoiceParsingFailResponse: [],
  InvoiceParsingSucessStatus: null,
  InvoiceParsingFail: null,
  InvoiceParsingDialog: false,

  pendingInvoiceListLoading: null,
  pendingInvoiceListSucess: [],
  pendingInvoiceListSucessStatus: null,
  pendingInvoiceListFail: null,

  pendingInvoiceListInAccountingSoftwareLoading: null,
  pendingInvoiceListInAccountingSoftwareSucess: [],
  pendingInvoiceListInAccountingSoftwareSucessStatus: null,
  pendingInvoiceListInAccountingSoftwareFail: null,

  pendingInvoiceUploadLoading: null,
  pendingInvoiceUploadSucess: [],
  pendingInvoiceUploadSucessStatus: null,
  pendingInvoiceUploadFail: null,

  pendingInvoiceUploadInAccountingSoftwareLoading: null,
  pendingInvoiceUploadInAccountingSoftwareSucess: [],
  pendingInvoiceUploadInAccountingSoftwareSucessStatus: null,
  pendingInvoiceUploadInAccountingSoftwareFail: null,

  trackInvoiceLoading: null,
  trackInvoiceSucess: [],
  trackInvoiceStatus: null,
  trackInvoiceFail: null,

  invoiceEditDialog: false,

  invoiceApproveDialog: false,

  accountListLoading: null,
  accountListSucess: [],
  accountListSucessStatus: null,
  accountListFail: null,

  customerListLoading: null,
  customerListSucess: [],
  customerListSucessStatus: null,
  customerListFail: null,

  accountingMappingLoading: null,
  accountingMappingSucess: [],
  accountingMappingStatus: null,
  accountingMappingFail: null,

  getVenorkeyLoading: null,
  getVenorkeySucess: [],
  getVenorkeyStatus: null,
  getVenorkeyFail: null,

  saveVenorkeyLoading: null,
  saveVenorkeySucess: [],
  saveVenorkeyStatus: null,
  saveVenorkeyFail: null,

  viewInvoiceDialog: false,

  loginLoading: null,
  loginStatus: [],
  loginFail: null,

  registerLoading: null,
  registerStatus: null,
  registerSucess: [],
  registerFail: [],

  otpLoading: null,
  otpStatus: null,
  otpSucess: [],
  otpFail: [],

  deleteInvoiceLoading: null,
  deleteInvoiceSuccess: null,
  deleteInvoiceFail: null,

  deleteInvoiceFromAccountingSoftareLoading: null,
  deleteInvoiceFromAccountingSoftareSuccess: null,
  deleteInvoiceFromAccountingSoftareFail: null,

  deleteInvoiceDialog: false,

  zohoTokenLoading: null,
  zohoTokenSuccess: null,
  zohoTokenResponse: [],
  zohoTokenFail: null,

  deletePendingInvoiceDialog: false,

  deletePendingInvoiceLoading: null,
  deletePendingInvoiceSuccess: null,
  deletePendingInvoiceFail: null,

  invoiceExtractionViewDialog: false,

  trainingLoading: null,
  trainingSuccess: null,
  trainingFail: null,

  saveApDataLoading: null,
  saveApDataSuccess: null,
  saveApDataFail: null,

  tdxTaxLoding: null,
  tdxTaxSuccessResponse: [],
  tdxTaxFail: null,
};

export default function AccountsPayableReducers(state = initialState, action) {
  switch (action.type) {
    case TYPES.INVOICE_PARSING_LOADING:
      return {
        ...state,
        InvoiceParsingLoading: true,
        InvoiceParsingSucess: [],
        InvoiceParsingFailResponse: [],
        InvoiceParsingFail: false,
        InvoiceParsingSucessStatus: false,
      };
    case TYPES.INVOICE_PARSING_SUCCESS:
      return {
        ...state,
        InvoiceParsingLoading: false,
        InvoiceParsingSucess: action.payload,
        InvoiceParsingFailResponse: [],
        InvoiceParsingFail: false,
        InvoiceParsingSucessStatus: true,
      };
    case TYPES.INVOICE_PARSING_FAIL:
      return {
        ...state,
        InvoiceParsingLoading: false,
        InvoiceParsingSucess: [],
        InvoiceParsingFailResponse: action.payload,
        InvoiceParsingFail: true,
        InvoiceParsingSucessStatus: false,
      };
    case TYPES.INVOICE_PARSING_CLEAR_DATA:
      return {
        ...state,
        InvoiceParsingLoading: null,
        InvoiceParsingSucess: [],
        InvoiceParsingFailResponse: [],
        InvoiceParsingFail: null,
        InvoiceParsingSucessStatus: null,
      };
    case TYPES.INVOICE_PARSING_DIALOG:
      return {
        ...state,
        InvoiceParsingDialog: action.payload,
      };
    case TYPES.PENDING_INVOICE_LIST_LOADING:
      return {
        ...state,
        pendingInvoiceListLoading: true,
        pendingInvoiceListSucess: [],
        pendingInvoiceListSucessStatus: false,
        pendingInvoiceListFail: false,
      };
    case TYPES.PENDING_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        pendingInvoiceListLoading: false,
        pendingInvoiceListSucess: action.payload,
        pendingInvoiceListSucessStatus: true,
        pendingInvoiceListFail: false,
      };
    case TYPES.PENDING_INVOICE_LIST_FAIL:
      return {
        ...state,
        pendingInvoiceListLoading: false,
        pendingInvoiceListSucess: [],
        pendingInvoiceListSucessStatus: false,
        pendingInvoiceListFail: true,
      };
    case TYPES.PENDING_INVOICE_LIST_CLEAR_DATA:
      return {
        ...state,
        pendingInvoiceListLoading: null,
        pendingInvoiceListSucess: [],
        pendingInvoiceListSucessStatus: null,
        pendingInvoiceListFail: null,
      };
    case TYPES.PENDING_INVOICE_UPLOAD_LOADING:
      return {
        ...state,
        pendingInvoiceUploadLoading: true,
        pendingInvoiceUploadSucess: [],
        pendingInvoiceUploadSucessStatus: false,
        pendingInvoiceUploadFail: false,
      };
    case TYPES.PENDING_INVOICE_UPLOAD_SUCCESS:
      return {
        ...state,
        pendingInvoiceUploadLoading: false,
        pendingInvoiceUploadSucess: action.payload,
        pendingInvoiceUploadSucessStatus: true,
        pendingInvoiceUploadFail: false,
      };
    case TYPES.PENDING_INVOICE_UPLOAD_FAIL:
      return {
        ...state,
        pendingInvoiceUploadLoading: false,
        pendingInvoiceUploadSucess: [],
        pendingInvoiceUploadSucessStatus: false,
        pendingInvoiceUploadFail: true,
      };
    case TYPES.PENDING_INVOICE_UPLOAD_CLEAR_DATA:
      return {
        ...state,
        pendingInvoiceUploadLoading: null,
        pendingInvoiceUploadSucess: [],
        pendingInvoiceUploadSucessStatus: null,
        pendingInvoiceUploadFail: null,
      };

    case TYPES.INVOICE_EDIT_DIALOG:
      return {
        ...state,
        invoiceEditDialog: action.payload,
      };

    case TYPES.INVOICE_APPROVE_DIALOG:
      return {
        ...state,
        invoiceApproveDialog: action.payload,
      };

    case TYPES.TRACK_INVOICE_LOADING:
      return {
        ...state,
        trackInvoiceLoading: true,
        trackInvoiceSucess: [],
        trackInvoiceStatus: false,
        trackInvoiceFail: false,
      };
    case TYPES.TRACK_INVOICE_SUCCESS:
      return {
        ...state,
        trackInvoiceLoading: false,
        trackInvoiceSucess: action.payload,
        trackInvoiceStatus: true,
        trackInvoiceFail: false,
      };
    case TYPES.TRACK_INVOICE_FAIL:
      return {
        ...state,
        trackInvoiceLoading: false,
        trackInvoiceSucess: [],
        trackInvoiceStatus: false,
        trackInvoiceFail: true,
      };
    case TYPES.TRACK_INVOICE_CLEAR_DATA:
      return {
        ...state,
        trackInvoiceLoading: null,
        trackInvoiceSucess: [],
        trackInvoiceStatus: null,
        trackInvoiceFail: null,
      };
    case TYPES.ACCOUNT_LIST_LOADING:
      return {
        ...state,
        accountListLoading: true,
        accountListSucess: [],
        accountListSucessStatus: false,
        accountListFail: false,
      };
    case TYPES.ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        accountListLoading: false,
        accountListSucess: action.payload,
        accountListSucessStatus: true,
        accountListFail: false,
      };
    case TYPES.ACCOUNT_LIST_FAIL:
      return {
        ...state,
        accountListLoading: false,
        accountListSucess: [],
        accountListSucessStatus: false,
        accountListFail: true,
      };
    case TYPES.ACCOUNT_LIST_CLEAR_DATA:
      return {
        ...state,
        accountListLoading: null,
        accountListSucess: [],
        accountListSucessStatus: null,
        accountListFail: null,
      };
    case TYPES.CUSTOMER_LIST_LOADING:
      return {
        ...state,
        customerListLoading: true,
        customerListSucess: [],
        customerListSucessStatus: false,
        customerListFail: false,
      };
    case TYPES.CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        customerListLoading: false,
        customerListSucess: action.payload,
        customerListSucessStatus: true,
        customerListFail: false,
      };
    case TYPES.CUSTOMER_LIST_FAIL:
      return {
        ...state,
        customerListLoading: false,
        customerListSucess: [],
        customerListSucessStatus: false,
        customerListFail: true,
      };
    case TYPES.CUSTOMER_LIST_CLEAR_DATA:
      return {
        ...state,
        customerListLoading: null,
        customerListSucess: [],
        customerListSucessStatus: null,
        customerListFail: null,
      };
    case TYPES.PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_LOADING:
      return {
        ...state,
        pendingInvoiceUploadInAccountingSoftwareLoading: true,
        pendingInvoiceUploadInAccountingSoftwareSucess: [],
        pendingInvoiceUploadInAccountingSoftwareSucessStatus: false,
        pendingInvoiceUploadInAccountingSoftwareFail: false,
      };
    case TYPES.PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_SUCCESS:
      return {
        ...state,
        pendingInvoiceUploadInAccountingSoftwareLoading: false,
        pendingInvoiceUploadInAccountingSoftwareSucess: action.payload,
        pendingInvoiceUploadInAccountingSoftwareSucessStatus: true,
        pendingInvoiceUploadInAccountingSoftwareFail: false,
      };
    case TYPES.PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_FAIL:
      return {
        ...state,
        pendingInvoiceUploadInAccountingSoftwareLoading: false,
        pendingInvoiceUploadInAccountingSoftwareSucess: [],
        pendingInvoiceUploadInAccountingSoftwareSucessStatus: false,
        pendingInvoiceUploadInAccountingSoftwareFail: true,
      };
    case TYPES.PENDING_INVOICE_UPLOAD_IN_ACCOUNTING_SOFTWARE_CLEAR_DATA:
      return {
        ...state,
        pendingInvoiceUploadInAccountingSoftwareLoading: null,
        pendingInvoiceUploadInAccountingSoftwareSucess: [],
        pendingInvoiceUploadInAccountingSoftwareSucessStatus: null,
        pendingInvoiceUploadInAccountingSoftwareFail: null,
      };

    case TYPES.PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_LOADING:
      return {
        ...state,
        pendingInvoiceListInAccountingSoftwareLoading: true,
        pendingInvoiceListInAccountingSoftwareSucess: [],
        pendingInvoiceListInAccountingSoftwareSucessStatus: false,
        pendingInvoiceListInAccountingSoftwareFail: false,
      };
    case TYPES.PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_SUCCESS:
      return {
        ...state,
        pendingInvoiceListInAccountingSoftwareLoading: false,
        pendingInvoiceListInAccountingSoftwareSucess: action.payload,
        pendingInvoiceListInAccountingSoftwareSucessStatus: true,
        pendingInvoiceListInAccountingSoftwareFail: false,
      };
    case TYPES.PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_FAIL:
      return {
        ...state,
        pendingInvoiceListInAccountingSoftwareLoading: false,
        pendingInvoiceListInAccountingSoftwareSucess: [],
        pendingInvoiceListInAccountingSoftwareSucessStatus: false,
        pendingInvoiceListInAccountingSoftwareFail: true,
      };
    case TYPES.PENDING_INVOICE_LIST_IN_ACCOUNTING_SOFTWARE_CLEAR_DATA:
      return {
        ...state,
        pendingInvoiceListInAccountingSoftwareLoading: null,
        pendingInvoiceListInAccountingSoftwareSucess: [],
        pendingInvoiceListInAccountingSoftwareSucessStatus: null,
        pendingInvoiceListInAccountingSoftwareFail: null,
      };
    case TYPES.ACCOUNT_MAPPING_LOADING:
      return {
        ...state,
        accountingMappingLoading: true,
        accountingMappingSucess: [],
        accountingMappingStatus: false,
        accountingMappingFail: false,
      };
    case TYPES.ACCOUNT_MAPPING_SUCCESS:
      return {
        ...state,
        accountingMappingLoading: false,
        accountingMappingSucess: action.payload,
        accountingMappingStatus: true,
        accountingMappingFail: false,
      };
    case TYPES.ACCOUNT_MAPPING_FAIL:
      return {
        ...state,
        accountingMappingLoading: false,
        accountingMappingSucess: [],
        accountingMappingStatus: false,
        accountingMappingFail: true,
      };
    case TYPES.ACCOUNT_MAPPING_CLEAR_DATA:
      return {
        ...state,
        accountingMappingLoading: null,
        accountingMappingSucess: [],
        accountingMappingStatus: null,
        accountingMappingFail: null,
      };
    case TYPES.GET_VENDORKEY_LOADING:
      return {
        ...state,
        getVenorkeyLoading: true,
        getVenorkeySucess: [],
        getVenorkeyStatus: false,
        getVenorkeyFail: false,
      };
    case TYPES.GET_VENDORKEY_SUCCESS:
      return {
        ...state,
        getVenorkeyLoading: false,
        getVenorkeySucess: action.payload,
        getVenorkeyStatus: true,
        getVenorkeyFail: false,
      };
    case TYPES.GET_VENDORKEY_FAIL:
      return {
        ...state,
        getVenorkeyLoading: false,
        getVenorkeySucess: [],
        getVenorkeyStatus: false,
        getVenorkeyFail: true,
      };
    case TYPES.GET_VENDORKEY_CLEAR_DATA:
      return {
        ...state,
        getVenorkeyLoading: null,
        getVenorkeySucess: [],
        getVenorkeyStatus: null,
        getVenorkeyFail: null,
      };
    case TYPES.SAVE_VENDORKEY_LOADING:
      return {
        ...state,
        saveVenorkeyLoading: true,
        saveVenorkeySucess: [],
        saveVenorkeyStatus: false,
        saveVenorkeyFail: false,
      };
    case TYPES.SAVE_VENDORKEY_SUCCESS:
      return {
        ...state,
        saveVenorkeyLoading: false,
        saveVenorkeySucess: action.payload,
        saveVenorkeyStatus: true,
        saveVenorkeyFail: false,
      };
    case TYPES.SAVE_VENDORKEY_FAIL:
      return {
        ...state,
        saveVenorkeyLoading: false,
        saveVenorkeySucess: [],
        saveVenorkeyStatus: false,
        saveVenorkeyFail: true,
      };
    case TYPES.SAVE_VENDORKEY_CLEAR_DATA:
      return {
        ...state,
        saveVenorkeyLoading: null,
        saveVenorkeySucess: [],
        saveVenorkeyStatus: null,
        saveVenorkeyFail: null,
      };

    case TYPES.VIEW_INVOICE_DIALOG:
      return {
        ...state,
        viewInvoiceDialog: action.payload,
      };
    case TYPES.LOGIN_LOADING:
      return {
        ...state,
        loginLoading: true,
        loginStatus: [],
        loginFail: false,
      };
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginStatus: action.payload,
        loginFail: false,
      };
    case TYPES.LOGIN_FAIL:
      return {
        ...state,
        loginLoading: false,
        loginStatus: [],
        loginFail: true,
      };
    case TYPES.LOGIN_CLEAR_DATA:
      return {
        ...state,
        loginLoading: null,
        loginStatus: [],
        loginFail: null,
      };
    case TYPES.REGISTER_LOADING:
      return {
        ...state,
        registerLoading: true,
        registerStatus: false,
        registerSucess: [],
        registerFail: [],
      };
    case TYPES.REGISTER_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerStatus: true,
        registerSucess: action.payload,
        registerFail: [],
      };
    case TYPES.REGISTER_FAIL:
      return {
        ...state,
        registerLoading: false,
        registerStatus: false,
        registerSucess: [],
        registerFail: action.payload,
      };
    case TYPES.REGISTER_CLEAR_DATA:
      return {
        ...state,
        registerLoading: null,
        registerStatus: null,
        registerSucess: [],
        registerFail: [],
      };
    case TYPES.OTP_LOADING:
      return {
        ...state,
        otpLoading: true,
        otpStatus: false,
        otpSucess: [],
        otpFail: [],
      };
    case TYPES.OTP_SUCCESS:
      return {
        ...state,
        otpLoading: false,
        otpStatus: true,
        otpSucess: action.payload,
        otpFail: [],
      };
    case TYPES.OTP_FAIL:
      return {
        ...state,
        otpLoading: false,
        otpStatus: false,
        otpSucess: [],
        otpFail: action.payload,
      };
    case TYPES.OTP_CLEAR_DATA:
      return {
        ...state,
        otpLoading: null,
        otpStatus: null,
        otpSucess: [],
        otpFail: [],
      };
    case TYPES.DELETE_INVOICE_LOADING:
      return {
        ...state,
        deleteInvoiceLoading: true,
        deleteInvoiceSuccess: false,
        deleteInvoiceFail: false,
      };
    case TYPES.DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        deleteInvoiceLoading: false,
        deleteInvoiceSuccess: true,
        deleteInvoiceFail: false,
      };
    case TYPES.DELETE_INVOICE_FAIL:
      return {
        ...state,
        deleteInvoiceLoading: false,
        deleteInvoiceSuccess: false,
        deleteInvoiceFail: true,
      };
    case TYPES.DELETE_INVOICE_CLEAR_DATA:
      return {
        ...state,
        deleteInvoiceLoading: null,
        deleteInvoiceSuccess: null,
        deleteInvoiceFail: null,
      };
    case TYPES.DELETE_INVOICE_DIALOG:
      return {
        ...state,
        deleteInvoiceDialog: action.payload,
      };
    case TYPES.DELETE_QB_INVOICE_LOADING:
      return {
        ...state,
        deleteInvoiceFromAccountingSoftareLoading: true,
        deleteInvoiceFromAccountingSoftareSuccess: false,
        deleteInvoiceFromAccountingSoftareFail: false,
      };
    case TYPES.DELETE_QB_INVOICE_SUCCESS:
      return {
        ...state,
        deleteInvoiceFromAccountingSoftareLoading: false,
        deleteInvoiceFromAccountingSoftareSuccess: true,
        deleteInvoiceFromAccountingSoftareFail: false,
      };
    case TYPES.DELETE_QB_INVOICE_FAIL:
      return {
        ...state,
        deleteInvoiceFromAccountingSoftareLoading: false,
        deleteInvoiceFromAccountingSoftareSuccess: false,
        deleteInvoiceFromAccountingSoftareFail: true,
      };
    case TYPES.DELETE_QB_INVOICE_CLEAR_DATA:
      return {
        ...state,
        deleteInvoiceFromAccountingSoftareLoading: null,
        deleteInvoiceFromAccountingSoftareSuccess: null,
        deleteInvoiceFromAccountingSoftareFail: null,
      };
    case TYPES.ZOHO_TOKEN_LOADING:
      return {
        ...state,
        zohoTokenLoading: true,
        zohoTokenSuccess: false,
        zohoTokenResponse: [],
        zohoTokenFail: false,
      };
    case TYPES.ZOHO_TOKEN_SUCCESS:
      return {
        ...state,
        zohoTokenLoading: false,
        zohoTokenSuccess: true,
        zohoTokenResponse: action.payload,
        zohoTokenFail: false,
      };
    case TYPES.ZOHO_TOKEN_FAIL:
      return {
        ...state,
        zohoTokenLoading: false,
        zohoTokenSuccess: false,
        zohoTokenResponse: [],
        zohoTokenFail: true,
      };
    case TYPES.ZOHO_TOKEN_CLEAR_DATA:
      return {
        ...state,
        zohoTokenLoading: null,
        zohoTokenSuccess: null,
        zohoTokenResponse: [],
        zohoTokenFail: null,
      };
    case TYPES.DELETE_PENDING_INVOICE_DIALOG:
      return {
        ...state,
        deletePendingInvoiceDialog: action.payload,
      };
    case TYPES.DELETE_PENDING_INVOICE_LOADING:
      return {
        ...state,
        deletePendingInvoiceLoading: true,
        deletePendingInvoiceSuccess: false,
        deletePendingInvoiceFail: false,
      };
    case TYPES.DELETE_PENDING_INVOICE_SUCCESS:
      return {
        ...state,
        deletePendingInvoiceLoading: false,
        deletePendingInvoiceSuccess: true,
        deletePendingInvoiceFail: false,
      };
    case TYPES.DELETE_PENDING_INVOICE_FAIL:
      return {
        ...state,
        deletePendingInvoiceLoading: false,
        deletePendingInvoiceSuccess: false,
        deletePendingInvoiceFail: true,
      };
    case TYPES.DELETE_PENDING_INVOICE_CLEAR_DATA:
      return {
        ...state,
        deletePendingInvoiceLoading: null,
        deletePendingInvoiceSuccess: null,
        deletePendingInvoiceFail: null,
      };
    case TYPES.INVOICE_EXTRACTION_VIEW_DIALOG:
      return {
        ...state,
        invoiceExtractionViewDialog: action.payload,
      };
    case TYPES.TRAINING_LOADING:
      return {
        ...state,
        trainingLoading: true,
        trainingSuccess: false,
        trainingFail: false,
      };
    case TYPES.TRAINING_SUCCESS:
      return {
        ...state,
        trainingLoading: false,
        trainingSuccess: action.payload,
        trainingFail: false,
      };
    case TYPES.TRAINING_FAIL:
      return {
        ...state,
        trainingLoading: false,
        trainingSuccess: false,
        trainingFail: true,
      };
    case TYPES.TRAINING_CLEAR_DATA:
      return {
        ...state,
        trainingLoading: null,
        trainingSuccess: null,
        trainingFail: null,
      };
    case TYPES.SAVE_AP_DATA_LOADING:
      return {
        ...state,
        saveApDataLoading: true,
        saveApDataSuccess: false,
        saveApDataFail: false,
      };
    case TYPES.SAVE_AP_DATA_SUCCESS:
      return {
        ...state,
        saveApDataLoading: false,
        saveApDataSuccess: action.payload,
        saveApDataFail: false,
      };
    case TYPES.SAVE_AP_DATA_FAIL:
      return {
        ...state,
        saveApDataLoading: false,
        saveApDataSuccess: false,
        saveApDataFail: true,
      };
    case TYPES.SAVE_AP_DATA_CLEAR_DATA:
      return {
        ...state,
        saveApDataLoading: null,
        saveApDataSuccess: null,
        saveApDataFail: null,
      };
    case TYPES.TDX_TAX_LOADING:
      return {
        ...state,
        tdxTaxLoding: true,
        tdxTaxSuccessResponse: [],
        tdxTaxFail: false,
      };
    case TYPES.TDX_TAX_SUCCESS:
      return {
        ...state,
        tdxTaxLoding: false,
        tdxTaxSuccessResponse: action.payload,
        tdxTaxFail: false,
      };
    case TYPES.TDX_TAX_FAIL:
      return {
        ...state,
        tdxTaxLoding: false,
        tdxTaxSuccessResponse: [],
        tdxTaxFail: true,
      };
    case TYPES.TDX_TAX_CLEAR_DATA:
      return {
        ...state,
        tdxTaxLoding: null,
        tdxTaxSuccessResponse: [],
        tdxTaxFail: null,
      };
    default:
      return {
        ...state,
      };
  }
}
