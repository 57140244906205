import React, { useState} from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { ViewInvoiceDialog } from "../AccountsPayableActions/AccountsPayableActions";
import { Document, Page } from "react-pdf";
import { Grid} from "@mui/material";

export default function ViewInvoice(props) {
  const dispatch = useDispatch();
  //----------------------------------------------
  const selectedIndex = props.index;
 console.log("selectedIndex",selectedIndex)
  const pendingInvoiceListSucess = useSelector(
    (state) => state.AccountsPayableReducers.pendingInvoiceListSucess
  );

  console.log("pendingInvoiceListSucess",pendingInvoiceListSucess)

  var listOfPendingInvoice = pendingInvoiceListSucess
    ? pendingInvoiceListSucess.all_pen_invs
    : [];


  var selectedArray = listOfPendingInvoice[selectedIndex];
  console.log("listOfPendingInvoice",listOfPendingInvoice)

  //View Invoice Dialog
  const viewInvoiceDialog = useSelector(
    (state) => state.AccountsPayableReducers.viewInvoiceDialog
  );
  const handleClose = () => {
    dispatch(ViewInvoiceDialog(!viewInvoiceDialog));
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(listOfPendingInvoice[selectedIndex].file_url);

  console.log("pdf",listOfPendingInvoice[selectedIndex].file_url)

  return (
    <React.Fragment>
      <Dialog
        fullWidth="true"
        maxWidth="lg"
        open={viewInvoiceDialog}
        onClose={handleClose}
      >
        <DialogTitle>Invoice View</DialogTitle>
        <Grid container>
          <Grid item xs={12} sx={{display:"flex",justifyContent:"center"}}>
          <iframe src={pdfBlobUrl} height="600" width="800"></iframe>  
          </Grid>
        </Grid>
     
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
