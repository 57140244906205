//-------------------------------------------------- Prod URLs -----------------------------------------------------------------------------------------

// export const base_url = "https://gaurav-backend-fap-production-eastus.azurewebsites.net";

//export const base_url = "https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net";

//export const auth_url = "https://gaurav-backend-fap-production-eastus.azurewebsites.net";

export const auth_url = "https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net";
export const redirect_url = "https://core-fap.com/dashboard";
// export const redirect_url = "http://localhost:3000/dashboard";
//export const redirect_url = "https://shine-frontend-fap-prod-eastus.azurewebsites.net/dashboard";

//export const redirect_url = "https://shine-frontend-fap-dev-eastus.azurewebsites.net/dashboard";



//----------------------------------------------------Power Bi Reports - CoreStart  ---------------------------------------------------------------------

//Combined Report

export const CombinedReport =
  "https://app.powerbi.com/reportEmbed?reportId=dbb888e0-dd7e-430d-a086-f922fe8cb61b&autoAuth=true&ctid=f8f764a8-f449-4de9-9910-c97a39540393";

//QB Test  - P&L - Test - CoreStrat

export const QuickBooksPLTest =
  "https://app.powerbi.com/reportEmbed?reportId=2b3cc9d5-ed16-4c46-9ac6-bde78f3d06bf&autoAuth=true&ctid=f8f764a8-f449-4de9-9910-c97a39540393";

//QB Test  - P&L - Prod - CoreStrat

export const QuickBooksPLProd =
  "https://app.powerbi.com/reportEmbed?reportId=88224d01-d35f-4412-b371-f07c30c791ce&autoAuth=true&ctid=f8f764a8-f449-4de9-9910-c97a39540393";

//QB Test  - BlanceSheet - Test - CoreStrat

export const QuickBooksBSTest =
  "https://app.powerbi.com/reportEmbed?reportId=9ce3b1ef-a5b0-4a7a-be56-0e8ed62fb583&autoAuth=true&ctid=f8f764a8-f449-4de9-9910-c97a39540393";

//QB Test  - BlanceSheet - Prod - CoreStrat

export const QuickBooksBSProd =
  "https://app.powerbi.com/view?r=eyJrIjoiZWU5OGEwN2ItYzRiZi00MGNmLTkwOTEtZTIzNGI5OGIyMGJhIiwidCI6ImY4Zjc2NGE4LWY0NDktNGRlOS05OTEwLWM5N2EzOTU0MDM5MyJ9";

//QB Test  - CashFlow - Test - CoreStrat

export const QuickBooksCFTest =
  "https://app.powerbi.com/reportEmbed?reportId=fd7671bb-f078-4566-a63e-88caa097e115&autoAuth=true&ctid=f8f764a8-f449-4de9-9910-c97a39540393";

//QB Test  - CashFlow - Prod - CoreStrat

export const QuickBooksCFProd =
  "https://app.powerbi.com/view?r=eyJrIjoiZTVkOGI1NjktZDc3Zi00M2IyLThiOGUtZWJiYjRmM2FlOTBmIiwidCI6ImY4Zjc2NGE4LWY0NDktNGRlOS05OTEwLWM5N2EzOTU0MDM5MyJ9";


//----------------------------------------------------Power Bi Reports - Unravel  ---------------------------------------------------------------------

//P&L - Prod - UnRavel

export const ZohoPLProd =
"https://app.powerbi.com/view?r=eyJrIjoiMzk1ODM5ZmMtYjMxNC00MDg4LTkyY2UtZDg3MTFiM2FjMDM4IiwidCI6ImY4Zjc2NGE4LWY0NDktNGRlOS05OTEwLWM5N2EzOTU0MDM5MyJ9";

//BlanceSheet - Prod - UnRavel

export const ZohoBSProd =
"https://app.powerbi.com/view?r=eyJrIjoiZmJmMDc4NjItMTM5YS00MjQzLTgyMDYtZTMxYzVlMzVmYjAzIiwidCI6ImY4Zjc2NGE4LWY0NDktNGRlOS05OTEwLWM5N2EzOTU0MDM5MyJ9";


