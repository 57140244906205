import TYPES from "../LandingPageTypes/LandingPageTypes";
const initialState = {
  UrlFetchLoading: null,
  UrlFetchSucess: [],
  UrlFetchSucessStatus: null,
  UrlFetchFail: null,
  fetchAccessTokenLoading: null,
  fetchAccessTokenSucess: [],
  fetchAccessTokenStatus: null,
  fetchAccessTokenFail: null,
  zohoUrlFetchLoading: null,
  zohoUrlFetchSucess: [],
  zohoUrlFetchSucessStatus: null,
  zohoUrlFetchFail: null,
  reportPermissionDialog: false,
  userConfirmationLoading: null,
  userConfirmationSucess: [],
  userConfirmationStatus: null,
  userConfirmationFail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.URL_FETCH_LOADING:
      return {
        ...state,
        UrlFetchLoading: true,
        UrlFetchSucess: [],
        UrlFetchFail: false,
        UrlFetchSucessStatus: false,
      };
    case TYPES.URL_FETCH_SUCCESS:
      return {
        ...state,
        UrlFetchLoading: false,
        UrlFetchSucess: action.payload,
        UrlFetchFail: false,
        UrlFetchSucessStatus: true,
      };
    case TYPES.URL_FETCH_FAIL:
      return {
        ...state,
        UrlFetchLoading: false,
        UrlFetchSucess: [],
        UrlFetchFail: true,
        UrlFetchSucessStatus: false,
      };
    case TYPES.URL_FETCH_CLEAR_DATA:
      return {
        ...state,
        UrlFetchLoading: null,
        UrlFetchSucess: [],
        UrlFetchFail: null,
        UrlFetchSucessStatus: null,
      };
    case TYPES.FETCH_ACCESS_TOKEN_LOADING:
      return {
        ...state,
        fetchAccessTokenLoading: true,
        fetchAccessTokenSucess: [],
        fetchAccessTokenStatus: false,
        fetchAccessTokenFail: false,
      };
    case TYPES.FETCH_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        fetchAccessTokenLoading: false,
        fetchAccessTokenSucess: action.payload,
        fetchAccessTokenStatus: true,
        fetchAccessTokenFail: false,
      };
    case TYPES.FETCH_ACCESS_TOKEN_FAIL:
      return {
        ...state,
        fetchAccessTokenLoading: false,
        fetchAccessTokenSucess: [],
        fetchAccessTokenStatus: false,
        fetchAccessTokenFail: true,
      };
    case TYPES.FETCH_ACCESS_TOKEN_CLEAR_DATA:
      return {
        ...state,
        fetchAccessTokenLoading: null,
        fetchAccessTokenSucess: [],
        fetchAccessTokenStatus: null,
        fetchAccessTokenFail: null,
      };
    case TYPES.ZOHO_LOGIN_LOADING:
      return {
        ...state,
        zohoUrlFetchLoading: true,
        zohoUrlFetchSucess: [],
        zohoUrlFetchSucessStatus: false,
        zohoUrlFetchFail: false,
      };
    case TYPES.ZOHO_LOGIN_SUCCESS:
      return {
        ...state,
        zohoUrlFetchLoading: false,
        zohoUrlFetchSucess: action.payload,
        zohoUrlFetchSucessStatus: true,
        zohoUrlFetchFail: false,
      };

    case TYPES.ZOHO_LOGIN_FAIL:
      return {
        ...state,
        zohoUrlFetchLoading: false,
        zohoUrlFetchSucess: [],
        zohoUrlFetchSucessStatus: false,
        zohoUrlFetchFail: true,
      };
    case TYPES.ZOHO_LOGIN_CLEAR_DATA:
      return {
        ...state,
        zohoUrlFetchLoading: null,
        zohoUrlFetchSucess: [],
        zohoUrlFetchSucessStatus: null,
        zohoUrlFetchFail: null,
      };
    case TYPES.USER_CONFIRMATION_LOADING:
      return {
        ...state,
        userConfirmationLoading: true,
        userConfirmationSucess: [],
        userConfirmationStatus: false,
        userConfirmationFail: false,
      };
    case TYPES.USER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        userConfirmationLoading: false,
        userConfirmationSucess: action.payload,
        userConfirmationStatus: true,
        userConfirmationFail: false,
      };
    case TYPES.USER_CONFIRMATION_FAIL:
      return {
        ...state,
        userConfirmationLoading: false,
        userConfirmationSucess: [],
        userConfirmationStatus: false,
        userConfirmationFail: true,
      };
      case TYPES.USER_CONFIRMATION_CLEAR_DATA:
        return {
          ...state,
          userConfirmationLoading: null,
          userConfirmationSucess: [],
          userConfirmationStatus: null,
          userConfirmationFail: null,
        };
    case TYPES.REPORT_PERMISSION_DIALOG:
      return {
        ...state,
        reportPermissionDialog: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
