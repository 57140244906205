import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import CorestartLogo from "../../Assets/CorestartLogo.png";
import PersonIcon from "@mui/icons-material/Person";
import { Divider, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailIcon from "@mui/icons-material/Mail";
import { Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { Register } from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

const userTypeItems = [
  {
    value: "accountant",
    label: "Accounting Admin",
  },
  {
    value: "Financial Analyst",
    label: "Financial Analyst",
  },
  {
    value: "Finance Controller",
    label: "Finance Controller",
  },
];

function LoginView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Show and Hide Password Logic

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  //Redirect to OTP
  const handleSignUp = () => {
    navigate("/otp");
  };

  //Handle Login

  const handleLoginClick = () => {
    navigate("/login");
  };

  //Handle Logo Click

  const handleLogoClick = () => {
    navigate("/");
  };

  // User Input Variables

  const [email, setEmail] = React.useState();
  const [name, setName] = React.useState();
  const [password, setPassword] = React.useState();
  const [userRole, setUserRole] = React.useState("");

  //Handle Inputs

  const handleEmail = (e) => {
    setEmail(e);
  };
  const handleName = (e) => {
    setName(e);
  };
  const handlePassword = (e) => {
    setPassword(e);
  };

  const handleUserRoleChange = (event) => {
    setUserRole(event);
  };

  //Snackbar Message Logic

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setSnackBarOpen(false);
  };

  //Handle User Create Account
  const createAccount = () => {
    dispatch(Register(name, email, password, userRole));
    setSnackBarOpen(true);
  };

  //register Response

  const registerLoading = useSelector(
    (state) => state.AccountsPayableReducers.registerLoading
  );

  const registerStatus = useSelector(
    (state) => state.AccountsPayableReducers.registerStatus
  );

  const registerSucess = useSelector(
    (state) => state.AccountsPayableReducers.registerSucess
  );

  const registerFail = useSelector(
    (state) => state.AccountsPayableReducers.registerFail
  );

  console.log("registerFail",registerFail)

  useEffect(() => {
    storeInformation();
  }, [registerSucess]);

  const storeInformation = () => {
    if (registerSucess) {
      var data = registerSucess.data;
      if (
        registerSucess.message == "OTP sent to user email" ||
        registerSucess.message == "New OTP sent to user email"
      ) {
        localStorage.setItem("UserEmail", data ? data.email : "");
        localStorage.setItem("Id", data ? data.id : "");
        localStorage.setItem("Name", data ? data.name : "");
        navigate("/otp");
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ m: { xs: 3, md: 6, lg: 13 }, mt: { xs: 7 }, mb: { lg: 2 } }}
        >
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            <img
              src={CorestartLogo}
              width="200"
              height="33"
              style={{ cursor: "pointer" }}
              onClick={handleLogoClick}
              alt="Corestart Logo"
            />
          </motion.div>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#e5f6fd",
            borderRadius: "5px",
            m: { xs: 3, md: 6, lg: 25 },
            mt: { lg: 0 },
            mb: { lg: 1 },
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 4,
                    fontWeight: 900,
                    fontSize: 20,
                  }}
                >
                  Finance Automation Platform - Sign In
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{ ml: { xs: 4, lg: 30 }, mr: { xs: 4, lg: 30 }, mt: 2 }}
                />
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={5} sx={{ ml: 2, mr: 2 }}>
                  <Typography
                    sx={{
                      ml: 0.1,
                      mb: 1,
                      mt: 2,
                      fontWeight: 600,
                      fontFamily: "'Roboto', sans-serif",
                    }}
                  >
                    Name
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-name"
                      type={"text"}
                      value={name}
                      onChange={(e) => handleName(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <PersonIcon style={{ color: "#0093FB" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{ background: "white" }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={5} sx={{ ml: 2, mr: 2 }}>
                  <Typography
                    sx={{
                      ml: 0.1,
                      mb: 1,
                      mt: 2,
                      fontWeight: 600,
                      fontFamily: "'Roboto', sans-serif",
                    }}
                  >
                    User Type
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    name="usertype"
                    id="usertype"
                    style={{ background: "white" }}
                    value={userRole}
                    onChange={(e) => handleUserRoleChange(e.target.value)}
                  >
                    {userTypeItems.map((option) => (
                      <MenuItem
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #008ffc",
                            borderRadius: 1,
                            fontWeight: 600,
                          },
                        }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={5} sx={{ ml: 2, mr: 2 }}>
                  <Typography
                    sx={{
                      ml: 0.1,
                      mb: 1,
                      mt: 1,
                      fontWeight: 600,
                      fontFamily: "'Roboto', sans-serif",
                    }}
                  >
                    Email Address
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-email"
                      type={"email"}
                      value={email}
                      onChange={(e) => handleEmail(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <MailIcon style={{ color: "#0093FB" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{ background: "white" }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={5} sx={{ ml: 2, mr: 2 }}>
                  <Typography
                    sx={{
                      ml: 0.1,
                      mt: 1,
                      mb: 1,
                      fontWeight: 600,
                      fontFamily: "'Roboto', sans-serif",
                    }}
                  >
                    Password
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={password}
                      onChange={(e) => handlePassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityOff style={{ color: "#0093FB" }} />
                            ) : (
                              <Visibility style={{ color: "#0093FB" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      style={{ background: "white" }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={5} sx={{ ml: 2, mr: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={createAccount}
                    endIcon={<LoginIcon />}
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: 600,
                      fontFamily: "'Roboto', sans-serif",
                      p: 1.2,
                      mt: 5,
                      fontSize: 16,
                    }}
                  >
                    {" "}
                    Sign Up{" "}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: { xs: 1, lg: 2 },
                  mr: { xs: 1, lg: 2 },
                }}
              >
                <Typography
                  style={{
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  Already have an account?{" "}
                  <span
                    onClick={handleLoginClick}
                    style={{
                      color: "#0093FB",
                      fontWeight: 600,
                      cursor: "pointer",
                      fontFamily: "'Roboto', sans-serif",
                    }}
                  >
                    {" "}
                    Sign In
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </motion.div>
        </Grid>
      </Grid>
      {registerLoading ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackBarOpen}
            onClose={handleClose}
            message="Creating Account... "
            key={vertical + horizontal}
            action={
              <React.Fragment>
                <CircularProgress color="inherit" size={20} sx={{ mr: 2 }} />
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          ></Snackbar>
        </>
      ) : null}
      {Object.keys(registerFail).length? (
        <>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackBarOpen}
            onClose={handleClose}
            message={Object.keys(registerFail).length ? registerFail.message : "Process failed."}
            key={vertical + horizontal}
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />
        </>
      ) : null}
    </>
  );
}

export default LoginView;
