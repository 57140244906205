import TYPES from "../DashboardTypes/DashboardTypes";
const initialState = {
  selectedMenuItem: "Dashboard",

  getUserRoleLoading: null,
  getUserRoleSuccess: [],
  getuserRoleFail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.SELECTED_MENU_ITEM:
      return {
        ...state,
        selectedMenuItem: action.payload,
      };
    case TYPES.GET_USER_ROLE_LOADING:
      return {
        ...state,
        getUserRoleLoading: true,
        getUserRoleSuccess: [],
        getuserRoleFail: false,
      };
    case TYPES.GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        getUserRoleLoading: false,
        getUserRoleSuccess: action.payload,
        getuserRoleFail: false,
      };
    case TYPES.GET_USER_ROLE_FAIL:
      return {
        ...state,
        getUserRoleLoading: false,
        getUserRoleSuccess: [],
        getuserRoleFail: true,
      };
    case TYPES.GET_USER_ROLE_CLEAR_DATA:
      return {
        ...state,
        getUserRoleLoading: null,
        getUserRoleSuccess: [],
        getuserRoleFail: null,
      };
    default:
      return {
        ...state,
      };
  }
}
