import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { UserMenuSelection } from "../Dashboard/DashboardActions/DashboardActions";
import { useDispatch, useSelector } from "react-redux";
import LayersIcon from "@mui/icons-material/Layers";


export default function IconBreadcrumbs() {
  const dispatch = useDispatch();

  const handleHomeClick = () => {
    dispatch(UserMenuSelection("Dashboard"));
  }
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "'Roboto', sans-serif",
          }}
          color="inherit"
          onClick={handleHomeClick}
        >
          <DashboardIcon
            sx={{
              mr: 0.5,
              fontFamily: "'Roboto', sans-serif",
            }}
            fontSize="16px"
          />
        <span>Dashboard</span>  
        </Link>
        <Link
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "'Roboto', sans-serif",
          }}
          color="inherit"
          fontSize="16px"
        >
          <LayersIcon sx={{ mr: 0.5 }} fontSize="inherit" />
         <span>Account Receivable</span>
        </Link>
      </Breadcrumbs>
    </div>
  );
}
