import TYPES from "../AccountReceivableTypes/AccountReceivableTypes";
const initialState = {
  arLoading: null,
  arSucess: [],
  arSucessStatus: null,
  arFail: null,
  arFailResponse: [],
  arDialog: false,
  arUploadLoading: null,
  arUploadSucess: [],
  arUploadSucessStatus: null,
  arUploadFailResponse: [],
  arUploadFail: null,
  arUploadDialog: false,
  sendMailDialog: false,
  sendMailLoading: null,
  sendMailSucess: [],
  sendMailSucessStatus: null,
  sendMailFail: null,
  fetchCustomerEmailLoading: null,
  fetchCustomerEmailSucess: [],
  fetchCustomerEmailStatus: null,
  fetchCustomerEmailFail: null,
  fetchInvoicePDFLoading: null,
  fetchInvoicePDFSucess: [],
  fetchInvoicePDFStatus: null,
  fetchInvoicePDFFail: null,

  trackInvoiceLoading: null,
  trackInvoiceSucess: [],
  trackInvoiceFail: null,

  deleteInvoiceLoading: null,
  deleteInvoiceSucess: null,
  deleteInvoiceFail: null,

  deleteInvoiceFromAccountingSoftareLoading: false,
  deleteInvoiceFromAccountingSoftareSuccess: false,
  deleteInvoiceFromAccountingSoftareFail: false,

  deleteDialog: false,

  taxSlabLoading: null,
  taxSlabSucess: [],
  taxSlabFail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.AR_LOADING:
      return {
        ...state,
        arLoading: true,
        arSucess: [],
        arFailResponse: [],
        arFail: false,
        arSucessStatus: false,
      };
    case TYPES.AR_SUCCESS:
      return {
        ...state,
        arLoading: false,
        arSucess: action.payload,
        arFailResponse: [],
        arFail: false,
        arSucessStatus: true,
      };
    case TYPES.AR_FAIL:
      return {
        ...state,
        arLoading: false,
        arSucess: [],
        arFailResponse: action.payload,
        arFail: true,
        arSucessStatus: false,
      };
    case TYPES.AR_CLEAR_DATA:
      return {
        ...state,
        arLoading: null,
        arSucess: [],
        arFailResponse: [],
        arFail: null,
        arSucessStatus: null,
      };
    case TYPES.AR_DIALOG:
      return {
        ...state,
        arDialog: action.payload,
      };
    case TYPES.AR_INVOICE_UPLOAD_LOADING:
      return {
        ...state,
        arUploadLoading: true,
        arUploadSucess: [],
        arUploadSucessStatus: false,
        arUploadFail: false,
        arUploadFailResponse: [],
      };
    case TYPES.AR_INVOICE_UPLOAD_SUCCESS:
      return {
        ...state,
        arUploadLoading: false,
        arUploadSucess: action.payload,
        arUploadSucessStatus: true,
        arUploadFailResponse: [],
        arUploadFail: false,
      };
    case TYPES.AR_INVOICE_UPLOAD_FAIL:
      return {
        ...state,
        arUploadLoading: false,
        arUploadSucess: [],
        arUploadSucessStatus: false,
        arUploadFailResponse: action.payload,
        arUploadFail: true,
      };
    case TYPES.AR_INVOICE_UPLOAD_CLEAR_DATA:
      return {
        ...state,
        arUploadLoading: null,
        arUploadSucess: [],
        arUploadSucessStatus: null,
        arUploadFailResponse: [],
        arUploadFail: null,
      };
    case TYPES.AR_UPLOAD_DIALOG:
      return {
        ...state,
        arUploadDialog: action.payload,
      };
    case TYPES.SEND_EMAIL_DIALOG:
      return {
        ...state,
        sendMailDialog: action.payload,
      };
    case TYPES.SEND_EMAIL_LOADING:
      return {
        ...state,
        sendMailLoading: true,
        sendMailSucess: [],
        sendMailSucessStatus: false,
        sendMailFail: false,
      };
    case TYPES.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendMailLoading: false,
        sendMailSucess: action.payload,
        sendMailSucessStatus: true,
        sendMailFail: false,
      };
    case TYPES.SEND_EMAIL_FAIL:
      return {
        ...state,
        sendMailLoading: false,
        sendMailSucess: [],
        sendMailSucessStatus: false,
        sendMailFail: true,
      };
    case TYPES.SEND_EMAIL_CLEAR_DATA:
      return {
        ...state,
        sendMailLoading: null,
        sendMailSucess: [],
        sendMailSucessStatus: null,
        sendMailFail: null,
      };
    case TYPES.FETCH_CUSTOMER_EMAIL_LOADING:
      return {
        ...state,
        fetchCustomerEmailLoading: true,
        fetchCustomerEmailSucess: [],
        fetchCustomerEmailStatus: false,
        fetchCustomerEmailFail: false,
      };
    case TYPES.FETCH_CUSTOMER_EMAIL_SUCCESS:
      return {
        ...state,
        fetchCustomerEmailLoading: false,
        fetchCustomerEmailSucess: action.payload,
        fetchCustomerEmailStatus: true,
        fetchCustomerEmailFail: false,
      };
    case TYPES.FETCH_CUSTOMER_EMAIL_FAIL:
      return {
        ...state,
        fetchCustomerEmailLoading: false,
        fetchCustomerEmailSucess: [],
        fetchCustomerEmailStatus: false,
        fetchCustomerEmailFail: true,
      };
    case TYPES.FETCH_CUSTOMER_EMAIL_CLEAR_DATA:
      return {
        ...state,
        fetchCustomerEmailLoading: null,
        fetchCustomerEmailSucess: [],
        fetchCustomerEmailStatus: null,
        fetchCustomerEmailFail: null,
      };
    case TYPES.FETCH_INVOICE_PDF_LOADING:
      return {
        ...state,
        fetchInvoicePDFLoading: true,
        fetchInvoicePDFSucess: [],
        fetchInvoicePDFStatus: false,
        fetchInvoicePDFFail: false,
      };
    case TYPES.FETCH_INVOICE_PDF_SUCCESS:
      return {
        ...state,
        fetchInvoicePDFLoading: false,
        fetchInvoicePDFSucess: action.payload,
        fetchInvoicePDFStatus: true,
        fetchInvoicePDFFail: false,
      };
    case TYPES.FETCH_INVOICE_PDF_FAIL:
      return {
        ...state,
        fetchInvoicePDFLoading: false,
        fetchInvoicePDFSucess: [],
        fetchInvoicePDFStatus: false,
        fetchInvoicePDFFail: true,
      };
    case TYPES.FETCH_INVOICE_PDF_CLEAR_DATA:
      return {
        ...state,
        fetchInvoicePDFLoading: null,
        fetchInvoicePDFSucess: [],
        fetchInvoicePDFStatus: null,
        fetchInvoicePDFFail: null,
      };

    case TYPES.TRACK_INVOICE_LOADING:
      return {
        ...state,
        trackInvoiceLoading: true,
        trackInvoiceSucess: [],
        trackInvoiceFail: false,
      };
    case TYPES.TRACK_INVOICE_SUCCESS:
      return {
        ...state,
        trackInvoiceLoading: false,
        trackInvoiceSucess: action.payload,
        trackInvoiceFail: false,
      };
    case TYPES.TRACK_INVOICE_FAIL:
      return {
        ...state,
        trackInvoiceLoading: false,
        trackInvoiceSucess: [],
        trackInvoiceFail: true,
      };
    case TYPES.TRACK_INVOICE_CLEAR_DATA:
      return {
        ...state,
        trackInvoiceLoading: null,
        trackInvoiceSucess: [],
        trackInvoiceFail: null,
      };
    case TYPES.DELETE_INVOICE_LOADING:
      return {
        ...state,
        deleteInvoiceLoading: true,
        deleteInvoiceSucess: false,
        deleteInvoiceFail: false,
      };
    case TYPES.DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        deleteInvoiceLoading: false,
        deleteInvoiceSucess: true,
        deleteInvoiceFail: false,
      };
    case TYPES.DELETE_INVOICE_FAIL:
      return {
        ...state,
        deleteInvoiceLoading: false,
        deleteInvoiceSucess: false,
        deleteInvoiceFail: true,
      };
    case TYPES.DELETE_INVOICE_CLEAR_DATA:
      return {
        ...state,
        deleteInvoiceLoading: null,
        deleteInvoiceSucess: null,
        deleteInvoiceFail: null,
      };
    case TYPES.DELETE_QB_AR_INVOICE_LOADING:
      return {
        ...state,
        deleteInvoiceFromAccountingSoftareLoading: true,
        deleteInvoiceFromAccountingSoftareSuccess: false,
        deleteInvoiceFromAccountingSoftareFail: false,
      };
    case TYPES.DELETE_QB_AR_INVOICE_SUCCESS:
      return {
        ...state,
        deleteInvoiceFromAccountingSoftareLoading: false,
        deleteInvoiceFromAccountingSoftareSuccess: true,
        deleteInvoiceFromAccountingSoftareFail: false,
      };
    case TYPES.DELETE_QB_AR_INVOICE_FAIL:
      return {
        ...state,
        deleteInvoiceFromAccountingSoftareLoading: false,
        deleteInvoiceFromAccountingSoftareSuccess: false,
        deleteInvoiceFromAccountingSoftareFail: true,
      };
    case TYPES.DELETE_QB_AR_INVOICE_CLEAR_DATA:
      return {
        ...state,
        deleteInvoiceFromAccountingSoftareLoading: false,
        deleteInvoiceFromAccountingSoftareSuccess: false,
        deleteInvoiceFromAccountingSoftareFail: false,
      };
    case TYPES.DELETE_INVOICE_DAILOG:
      return {
        ...state,
        deleteDialog: action.payload,
      };
    case TYPES.TAX_SLAB_LOADING:
      return {
        ...state,
        taxSlabLoading: true,
        taxSlabSucess: [],
        taxSlabFail: false,
      };
    case TYPES.TAX_SLAB_SUCCESS:
      return {
        ...state,
        taxSlabLoading: false,
        taxSlabSucess: action.payload,
        taxSlabFail: false,
      };
    case TYPES.TAX_SLAB_FAIL:
      return {
        ...state,
        taxSlabLoading: false,
        taxSlabSucess: [],
        taxSlabFail: true,
      };
    case TYPES.TAX_SLAB_CLEAR_DATA:
      return {
        ...state,
        taxSlabLoading: null,
        taxSlabSucess: [],
        taxSlabFail: null,
      };
    default:
      return {
        ...state,
      };
  }
}
