const TYPES = {
  //---- AR ------
  AR_LOADING: "AR_LOADING",
  AR_SUCCESS: "AR_SUCCESS",
  AR_FAIL: "AR_LIST_FAIL",
  AR_CLEAR_DATA: "AR_CLEAR_DATA",

  //---- AR Dialog ------
  AR_DIALOG: "AR_DIALOG",

  //---- AR Invoice Upload------

  AR_INVOICE_UPLOAD_LOADING: "AR_INVOICE_UPLOAD_LOADING",
  AR_INVOICE_UPLOAD_SUCCESS: "AR_INVOICE_UPLOAD_SUCCESS",
  AR_INVOICE_UPLOAD_FAIL: "AR_INVOICE_UPLOAD_LIST_FAIL",
  AR_INVOICE_UPLOAD_CLEAR_DATA: "AR_INVOICE_UPLOAD_CLEAR_DATA",

  //---- AR Upload Dialog ------
  AR_UPLOAD_DIALOG: "AR_UPLOAD_DIALOG",

  //---- Send Email Dialog ------
  SEND_EMAIL_DIALOG: "SEND_EMAIL_DIALOG",

  //---- Send Email ------
  SEND_EMAIL_LOADING: "SEND_EMAIL_LOADING",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAIL: "SEND_EMAIL_FAIL",
  SEND_EMAIL_CLEAR_DATA: "SEND_EMAIL_CLEAR_DATA",

  //---- Fetch Customer Email ------
  FETCH_CUSTOMER_EMAIL_LOADING: "FETCH_CUSTOMER_EMAIL_LOADING",
  FETCH_CUSTOMER_EMAIL_SUCCESS: "FETCH_CUSTOMER_EMAIL_SUCCESS",
  FETCH_CUSTOMER_EMAIL_FAIL: "FETCH_CUSTOMER_EMAIL_FAIL",
  FETCH_CUSTOMER_EMAIL_CLEAR_DATA: "FETCH_CUSTOMER_EMAIL_CLEAR_DATA",

  //---- Fetch Invoice PDF------
  FETCH_INVOICE_PDF_LOADING: "FETCH_INVOICE_PDF_LOADING",
  FETCH_INVOICE_PDF_SUCCESS: "FETCH_INVOICE_PDF_SUCCESS",
  FETCH_INVOICE_PDF_FAIL: "FETCH_INVOICE_PDF_FAIL",
  FETCH_INVOICE_PDF_CLEAR_DATA: "FETCH_INVOICE_PDF_CLEAR_DATA",

  //---- Track Invoice ------

  TRACK_INVOICE_LOADING: "TRACK_INVOICE_PDF_LOADING",
  TRACK_INVOICE_SUCCESS: "TRACK_INVOICE_SUCCESS",
  TRACK_INVOICE_FAIL: "TRACK_INVOICE_FAIL",
  TRACK_INVOICE_CLEAR_DATA: "TRACK_INVOICE_CLEAR_DATA",

  //---- Delete Invoice ------

  DELETE_INVOICE_LOADING: "DELETE_INVOICE_LOADING",
  DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_FAIL: "DELETE_INVOICE_FAIL",
  DELETE_INVOICE_CLEAR_DATA: "DELETE_INVOICE_CLEAR_DATA",

   //---- Delete QB  -----------

   DELETE_QB_AR_INVOICE_LOADING: "DELETE_QB_AR_INVOICE_LOADING",
   DELETE_QB_AR_INVOICE_SUCCESS: "DELETE_QB_AR_INVOICE_SUCCESS",
   DELETE_QB_AR_INVOICE_FAIL: "DELETE_QB_AR_INVOICE_FAIL",
   DELETE_QB_AR_INVOICE_CLEAR_DATA: "DELETE_QB_AR_INVOICE_CLEAR_DATA",

   //---- Tax Slab  -----------

   TAX_SLAB_LOADING: "TAX_SLAB_LOADING",
   TAX_SLAB_SUCCESS: "TAX_SLAB_SUCCESS",
   TAX_SLAB_FAIL: "TAX_SLAB_FAIL",
   TAX_SLAB_CLEAR_DATA: "TAX_SLAB_CLEAR_DATA",

   //---- Delete Invoice Dialog ------
   
   DELETE_INVOICE_DAILOG: "DELETE_INVOICE_DAILOG",
};
export default TYPES;
