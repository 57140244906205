import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import {
  TrackInvoiceList,
  DeleteInvoiceDialogView,
  DeleteInvoice,
} from "../AccountsPayableActions/AccountsPayableActions";
import { useSelector, useDispatch } from "react-redux";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";
import Alert from "@mui/material/Alert";

export default function DeleteInvoiceDialog(props) {
  const dispatch = useDispatch();
  const id = props.index;

  //---------------------------------------------------------------------------

  const deleteInvoiceFromAccountingSoftareLoading = useSelector(
    (state) =>
      state.AccountsPayableReducers.deleteInvoiceFromAccountingSoftareLoading
  );

  const deleteInvoiceFromAccountingSoftareSuccess = useSelector(
    (state) =>
      state.AccountsPayableReducers.deleteInvoiceFromAccountingSoftareSuccess
  );

  const deleteInvoiceFromAccountingSoftareFail = useSelector(
    (state) =>
      state.AccountsPayableReducers.deleteInvoiceFromAccountingSoftareFail
  );
  //-------------------------------------------------------------------------
  useEffect(() => {
    dispatch(DeleteInvoice(id));
  }, []);

  //---------------------------------------------------------------------------

  // Dialog Status

  const deleteInvoiceDialog = useSelector(
    (state) => state.AccountsPayableReducers.deleteInvoiceDialog
  );

  const deleteInvoiceLoading = useSelector(
    (state) => state.AccountsPayableReducers.deleteInvoiceLoading
  );

  const deleteInvoiceSuccess = useSelector(
    (state) => state.AccountsPayableReducers.deleteInvoiceSuccess
  );

  const deleteInvoiceFail = useSelector(
    (state) => state.AccountsPayableReducers.deleteInvoiceFail
  );

  const handleClose = () => {
    dispatch(DeleteInvoiceDialogView(!deleteInvoiceDialog));
    dispatch(TrackInvoiceList());
  };

  return (
    <div>
      <Dialog
        open={deleteInvoiceDialog}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Delete Invoice</DialogTitle>
        <DialogContent>
          {deleteInvoiceLoading || deleteInvoiceFromAccountingSoftareLoading ? (
            <>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <CircularProgressWithLabel />
                </div>
              </Grid>
            </>
          ) : null}

          {deleteInvoiceSuccess || deleteInvoiceFromAccountingSoftareSuccess ? (
            <>
              <Grid item xs={12} sx={{ mt: 1 }}>
                {deleteInvoiceFromAccountingSoftareSuccess ? (
                  <>
                    <Alert severity="success" >
                      <Typography>
                        Invoice deleted successfully from accounting software.
                      </Typography>
                    </Alert>
                  </>
                ) : null}
                {deleteInvoiceSuccess ? (
                  <>
                    <Alert severity="success" sx={{ mt: 2 }}>
                      <Typography>
                        Invoice deleted successfully from database.
                      </Typography>
                    </Alert>
                  </>
                ) : null}
              </Grid>
            </>
          ) : null}
          {deleteInvoiceFail || deleteInvoiceFromAccountingSoftareFail ? (
            <>
              <Grid item xs={12} sx={{ mt: 1 }}>
                {deleteInvoiceFail ? (
                  <Alert severity="error">
                    <Typography>
                      Sorry! Failed to delete invoice from database.
                    </Typography>
                  </Alert>
                ) : null}
                {deleteInvoiceFromAccountingSoftareFail ? (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    <Typography>
                      Sorry! Failed to delete invoice from accounting software.
                    </Typography>
                  </Alert>
                ) : null}
              </Grid>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
