import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import CorestartLogo from "../../Assets/CorestartLogo.png";
import home_removebg from "../../Assets/home_removebg.png";
import { Button, Typography } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Hidden from "@mui/material/Hidden";
import { Icon } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import { IconButton } from "@mui/material";
import SegmentIcon from "@mui/icons-material/Segment";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  URLfetch,
  ZohoLogin,
} from "../LandingPage/LandingPageActions/LandingPageActions";
import {
  OTPClarData,
  RegisterClarData,
  LoginClarData,
} from "../AccountsPayable/AccountsPayableActions/AccountsPayableActions";

function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // API Calls

  useEffect(() => {
    dispatch(URLfetch());
    dispatch(ZohoLogin());
    dispatch(OTPClarData);
    dispatch(RegisterClarData);
    dispatch(LoginClarData);
    localStorage.removeItem("UserEmail");
    localStorage.removeItem("Id");
    localStorage.removeItem("Name");
    localStorage.removeItem("AcoountingSoftware");

  }, []);

  //Handle Social Media Clicks

  const handleTwitterClick = () => {
    window.open("https://twitter.com/labs_cs", "_blank");
  };
  const handleLinkedinClick = () => {
    window.open("https://www.linkedin.com/company/corestrat-labs/", "_blank");
  };

  //Handle Login Click

  const handleSignIn = () => {
    navigate("/login");
  };

  // Handle Create Account Click

  const handleCreateAccount = () => {
    navigate("/register");
  };

  //Seeting Up the Default Values ->
  //Skip -> None of the accounting software is selected

  localStorage.setItem("UserType", "accountant");
  localStorage.setItem("AcoountingSoftware", "skip");

  //Sign In -> Redirect URL -> Qb
  const UrlFetchSucess = useSelector(
    (state) => state.LandingPageReducers.UrlFetchSucess
  );

  //Sign In -> Redirect URL - Zoho
  const zohoUrlFetchSucess = useSelector(
    (state) => state.LandingPageReducers.zohoUrlFetchSucess
  );

  //Store API Response

  useEffect(() => {
    storeInformationqb();
  }, [UrlFetchSucess]);

  useEffect(() => {
    storeInformationzoho();
  }, [UrlFetchSucess]);

  const storeInformationqb = () => {
    localStorage.setItem("url", UrlFetchSucess.url);
    localStorage.setItem("client_secret", UrlFetchSucess.client_secret);
    localStorage.setItem("client_id", UrlFetchSucess.client_id);
    localStorage.setItem("auth_basic_str", UrlFetchSucess.auth_basic_str);
  };

  const storeInformationzoho = () => {
    localStorage.setItem("url", zohoUrlFetchSucess.url);
    localStorage.setItem("client_secret", zohoUrlFetchSucess.client_secret);
    localStorage.setItem("client_id", zohoUrlFetchSucess.client_id);
    localStorage.setItem("auth_basic_str", zohoUrlFetchSucess.auth_basic_str);
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ m: { xs: 3, md: 6, lg: 8 }, mt: { xs: 7 } }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{ m: { xs: 0, lg: 5 }, mb: { xs: 2, lg: 5 } }}>
              <Grid item xs={12} md={7}>
                <motion.div
                  initial={{ opacity: 0, y: -40 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.7 }}
                >
                  <img
                    src={CorestartLogo}
                    width="200"
                    height="33"
                    alt="Corestart Logo"
                  />
                </motion.div>
              </Grid>
              <Grid item xs={12} md={5}>
                <motion.div
                  initial={{ opacity: 0, y: -30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.7 }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        mt: { xs: 2, lg: 0 },
                      }}
                    >
                      <Icon>
                        <TwitterIcon
                          onClick={handleTwitterClick}
                          sx={{
                            "&:hover": {
                              color: "#0093FB",
                              cursor: "pointer",
                            },
                          }}
                        />
                      </Icon>
                      <Icon>
                        <LinkedInIcon
                          onClick={handleLinkedinClick}
                          sx={{
                            "&:hover": {
                              color: "#0093FB",
                              cursor: "pointer",
                            },
                          }}
                        />
                      </Icon>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", mt: { xs: 2, lg: 0 } }}
                    >
                      <div
                        style={{
                          borderLeft: "2px solid #bdbdbd",
                          height: "80%",
                          marginLeft: "30px",
                        }}
                      ></div>
                      <Icon sx={{ ml: 4 }}>
                        <MailIcon sx={{ color: "#0093FB" }} />
                      </Icon>
                      <Typography sx={{ ml: 1 }}>fap@corestart.ai</Typography>
                      <div
                        style={{
                          borderLeft: "2px solid #bdbdbd",
                          height: "80%",
                          marginLeft: "30px",
                        }}
                      ></div>
                    </Grid>
                    <Hidden lgDown>
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <IconButton
                          sx={{
                            backgroundColor: "#0093FB",
                            ml: 5,
                            mt: -1,
                            "&:hover": {
                              backgroundColor: "#0093FB",
                            },
                          }}
                        >
                          <SegmentIcon sx={{ color: "#ffffff" }} />
                        </IconButton>
                      </Grid>
                    </Hidden>
                  </Grid>
                </motion.div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              <Grid
                container
                sx={{
                  backgroundColor: "#e5f6fd",
                  borderRadius: "5px",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{ pt: 10, pl: { xs: 2, md: 4 }, pr: { xs: 2, md: 4 } }}
                >
                  <motion.div
                    initial={{ y: 200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1, delay: 1 }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 900,
                        fontSize: { xs: "205.25%", lg: "245.25%" },
                        fontFamily: "'Roboto', sans-serif",
                      }}
                    >
                      Delivering Modern
                    </Typography>
                  </motion.div>
                  <motion.div
                    initial={{ y: 200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1, delay: 1 }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 900,
                        fontSize: { xs: "205.25%", lg: "245.25%" },
                        fontFamily: "'Roboto', sans-serif",
                      }}
                    >
                      Finance Automation Infrastructure.
                    </Typography>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 1.2 }}
                  >
                    <Typography
                      sx={{ pt: 2, fontFamily: "'Roboto', sans-serif" }}
                    >
                      Finance automation platform offers features such as
                      invoicing, payment <br /> processing, expense tracking,
                      and financial reporting, all integrated <br /> within the
                      website infrastructure
                    </Typography>
                  </motion.div>

                  <Grid container sx={{ pt: 4 }} spacing={1}>
                    <Grid item xs={12} md={2}>
                      <motion.div
                        initial={{ opacity: 0, y: -30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, delay: 1.2 }}
                      >
                        <Button
                          onClick={handleSignIn}
                          variant="contained"
                          fullWidth
                          endIcon={<ArrowCircleRightIcon />}
                          sx={{ textTransform: "capitalize", p: 1 }}
                        >
                          Sign In
                        </Button>
                      </motion.div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <motion.div
                        initial={{ opacity: 0, y: -30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, delay: 1.2 }}
                      >
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={handleCreateAccount}
                          sx={{
                            textTransform: "capitalize",
                            border: "1px solid #000000",
                            color: "#000000",
                            background: "#ffffff",
                            p: 1,
                            mb: { xs: 3, lg: 0 },
                          }}
                        >
                          Create Account
                        </Button>
                      </motion.div>
                    </Grid>
                  </Grid>
                </Grid>
                <Hidden lgDown>
                  <Grid item xs={false} lg={6} sx={{ pt: 5, pb: 5 }}>
                    <motion.img
                      src={home_removebg}
                      alt="bg"
                      initial={{ x: 100, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 1, delay: 1.4 }}
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </motion.div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Index;
