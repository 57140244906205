import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./Common/Theme";
import { BrowserRouter as Router, Routes, Route,Navigate  } from "react-router-dom";
import Intro from "./Pages/Intro/Index";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/LandingPage/LoginView";
import Offline from './Common/Offline'
import NotFound404 from "./Common/NotFound404";
import NewAccount from "./Pages/LandingPage/CreateAccount";
import OTPView from "./Pages/LandingPage/OTPView";
import NormalUser from "./Pages/Dashboard/NormalUser";
import SelectAccountingSoftware from "./Pages/LandingPage/SelectAccountingSoftware";
import ReportAccess from "./Pages/LandingPage/ReportAccess";
import UserConfirmation from "./Pages/LandingPage/UserConfirmation";
import ProductDetails from './Pages/ProductDetails/Index'
//import LandingPage from "./Pages/LandingPage/Index";
//import LandingPage from "./Pages/LandingPage/LandingPage";
// import Login from "./Pages/LandingPage/Login";
//import NewAccount from "./Pages/LandingPage/NewAccount";
// import OTP from "./Pages/LandingPage/OTP";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/home" element={<Intro />} />
          <Route path="/login" element={<Login />} />
          <Route path="/accountingsoftware" element={<SelectAccountingSoftware />} />
          <Route path="/register" element={<NewAccount />} />
          <Route path="/otp" element={<OTPView />} />
          <Route path="/reportaccess" element={<ReportAccess />} />
          <Route path="/userconfirmation" element={<UserConfirmation />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/reports" element={<NormalUser />} />
          <Route path="/offline" element={<Offline />} />
          <Route path="/404" element={<NotFound404 />} />
          {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}

        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
