import TYPES from "../AccountReceivableTypes/AccountReceivableTypes";
import Axios from "axios";
// import { base_url } from "../../../constants";
import { redirect_url } from "../../../constants";

const accountingSoftareType = localStorage.getItem("AcoountingSoftware");
var base_url = "";

if (accountingSoftareType == "qb") {
  base_url =
    "https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net";
}

if (accountingSoftareType == "zoho") {
  base_url = "https://asvinth-backend-zoho-dev-eastus-staging.azurewebsites.net";
}

if (accountingSoftareType == "xero") {
  base_url = "https://asvinth-backend-xero-dev-eastus.azurewebsites.net";
}

//------------------------- AR --------------------

export const AR =
  (
    client_name,
    invoice_date,
    due_date,
    total_amount,
    token,
    items,
    user_currency,
    organizationId,
    selectedTaxSlab
  ) =>
  (dispatch) => {
    dispatch(ARLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const relmid = localStorage.getItem("realmId");

    const body = {
      data: {
        client_name: client_name,
        invoice_date: invoice_date,
        due_date: due_date,
        total_amount: total_amount,
        items: items,
        cust_curr_code: user_currency,
      },
      access_token: token,
      realmId: relmid,
      cust_curr_code: user_currency,
      organization_id: organizationId,
      vat_amount: selectedTaxSlab,
      invoice_num: "",
    };

    Axios.post(`${base_url}/ar-upload`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.AR_SUCCESS,
          payload: response.data,
        });
        dispatch(
          FetchInvoicePDF(
            client_name,
            invoice_date,
            due_date,
            total_amount,
            token,
            items,
            user_currency
          )
        );
      })
      .catch((error) => {
        dispatch({
          type: TYPES.AR_FAIL,
          payload: error.response,
        });
      });
  };

//------------------------- AR Loading --------------------

export const ARLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.AR_LOADING,
  });
};

//------------------------- ARClean Data--------------------

export const ARClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.AR_CLEAR_DATA,
  });
};

//------------------------- AR Dialog--------------------

export const ARDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.AR_DIALOG,
    payload: status,
  });
};

//------------------------- AR Invoice Upload --------------------

export const ARInvoiceUploadSingle =
  (client_name, token, pdf, user_currency, inputFieldsUpload, organizationId) =>
  (dispatch) => {
    dispatch(ARInvoiceUploadLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const relmid = localStorage.getItem("realmId");
    let data = null;
    data = new FormData();
    // data.append("ar_file", pdf);
    for (let i = 0; i < pdf.length; i++) {
      data.append("ar_file", pdf[i].data);
    }
    data.append("client_name", inputFieldsUpload[0].client_name);
    data.append("access_token", token);
    data.append("realmId", relmid);
    data.append("cust_curr_code", user_currency);
    data.append("organization_id", organizationId);
    data.append("category1", inputFieldsUpload[0].category1);
    data.append("category2", inputFieldsUpload[0].category2);

    Axios.post(`${base_url}/ar_file_upload`, data, config)
      .then((response) => {
        dispatch({
          type: TYPES.AR_INVOICE_UPLOAD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.AR_INVOICE_UPLOAD_FAIL,
          payload: error.response.data,
        });
      });
  };

export const ARInvoiceUpload =
  (token, invoice, items, organizationId) => (dispatch) => {
    dispatch(ARInvoiceUploadLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    let obj = {};
    obj.data = items;
    const relmid = localStorage.getItem("realmId");
    let data = null;
    data = new FormData();

    for (let i = 0; i < invoice.length; i++) {
      data.append("files", invoice[i].data);
    }
    data.append("access_token", token);
    data.append("realmId", relmid);
    data.append("files_metadata", JSON.stringify(obj));
    data.append("organization_id", organizationId);

    Axios.post(`${base_url}/ar_file_upload_bulk`, data, config)
      .then((response) => {
        dispatch({
          type: TYPES.AR_INVOICE_UPLOAD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.AR_INVOICE_UPLOAD_FAIL,
          payload: error.response.data,
        });
      });
  };

//-------------------------AR Invoice Upload Loading --------------------

export const ARInvoiceUploadLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.AR_INVOICE_UPLOAD_LOADING,
  });
};

//------------------------- AR Invoice Upload  Clean Data--------------------

export const ARInvoiceUploadClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.AR_INVOICE_UPLOAD_CLEAR_DATA,
  });
};

//------------------------- AR Upload Dialog--------------------

export const ARUploadDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.AR_UPLOAD_DIALOG,
    payload: status,
  });
};

//------------------------- Send Email Dialog--------------------

export const SendEmailDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.SEND_EMAIL_DIALOG,
    payload: status,
  });
};

//------------------------- Send Email ------------------------------

export const SendEmailData =
  (from, to, cc, bcc, subject, body, flag, pdf, token) => (dispatch) => {
    dispatch(SendEmailLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const relmid = localStorage.getItem("realmId");

    const pdfBlob = new Blob([pdf], { type: "application/pdf" });
    const data = new FormData();
    data.append("pdf", pdfBlob, "invoice.pdf");
    data.append("to", to);
    data.append("from", "fap@corestrat.ai");
    data.append("cc", cc);
    data.append("bcc", bcc);
    data.append("subject", subject);
    data.append("email body", body);
    data.append("copy_flag", flag);
    data.append("access_token", token);

    Axios.post(`${base_url}/sendemail`, data, config)
      .then((response) => {
        dispatch({
          type: TYPES.SEND_EMAIL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.SEND_EMAIL_FAIL,
          payload: error.response.data,
        });
      });
  };

//-------------------------Send Email Loading --------------------

export const SendEmailLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.SEND_EMAIL_LOADING,
  });
};

//------------------------- Send Email  Clean Data--------------------

export const SendEmailClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.SEND_EMAIL_CLEAR_DATA,
  });
};

//------------------------- Fetch Customer Email --------------------

export const FetchCustomerEmail = (client_name, token) => (dispatch) => {
  dispatch(FetchCustomerEmailLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  const relmid = localStorage.getItem("realmId");

  const body = {
    data: {
      client_name: client_name,
    },
    access_token: token,
    realmId: relmid,
  };

  Axios.post(`${base_url}/emailfetch`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.FETCH_CUSTOMER_EMAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.FETCH_CUSTOMER_EMAIL_FAIL,
      });
    });
};

//-------------------------Fetch Customer Email Loading --------------------

export const FetchCustomerEmailLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.FETCH_CUSTOMER_EMAIL_LOADING,
  });
};

//------------------------- Fetch Customer Email  Clean Data--------------------

export const FetchCustomerEmailClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.FETCH_CUSTOMER_EMAIL_CLEAR_DATA,
  });
};

//-------------------------  Fetch Invoice PDF --------------------

export const FetchInvoicePDF =
  (
    client_name,
    invoice_date,
    due_date,
    total_amount,
    token,
    items,
    user_currency
  ) =>
  (dispatch) => {
    dispatch(FetchInvoicePDFLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const relmid = localStorage.getItem("realmId");

    const body = {
      data: {
        client_name: client_name,
        invoice_date: invoice_date,
        due_date: due_date,
        total_amount: total_amount,
        items: items,
        cust_curr_code: user_currency,
      },
      access_token: token,
      realmId: relmid,
      cust_curr_code: user_currency,
    };

    Axios.post(`${base_url}/arpdfmaker`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.FETCH_INVOICE_PDF_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.FETCH_INVOICE_PDF_FAIL,
        });
      });
  };

//------------------------- Fetch Invoice PDF Loading --------------------

export const FetchInvoicePDFLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.FETCH_INVOICE_PDF_LOADING,
  });
};

//------------------------- Fetch Invoice PDF  Clean Data--------------------

export const FetchInvoicePDFClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.FETCH_INVOICE_PDF_CLEAR_DATA,
  });
};

//-------------------------  Track Invoice --------------------

export const TrackInvoiceAR = () => (dispatch) => {
  dispatch(TrackInvoiceARLoading());

  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  Axios.get(`${base_url}/get_account_receivables`, config)
    .then((response) => {
      dispatch({
        type: TYPES.TRACK_INVOICE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.TRACK_INVOICE_FAIL,
      });
    });
};

//------------------------- Track Invoice Loading --------------------

export const TrackInvoiceARLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.TRACK_INVOICE_LOADING,
  });
};

//------------------------- Track Invoice  Clean Data--------------------

export const TrackInvoiceARClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.TRACK_INVOICE_CLEAR_DATA,
  });
};

//-------------------------  Delete Invoice --------------------

export const DeleteInvoiceAR = (id) => (dispatch) => {
  dispatch(DeleteInvoiceARLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  Axios.delete(`${base_url}/delete_accounts_recievable/${id}`, config)
    .then((response) => {
      dispatch({
        type: TYPES.DELETE_INVOICE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.DELETE_INVOICE_FAIL,
      });
    });
};

//------------------------- Delete Invoice Loading --------------------

export const DeleteInvoiceARLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_INVOICE_LOADING,
  });
};

//------------------------- Delete Invoice  Clean Data--------------------

export const DeleteInvoiceARClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_INVOICE_CLEAR_DATA,
  });
};

//------------------------- Delete Invoice  Dialog--------------------

export const DeleteInvoiceDialogAR = (status) => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_INVOICE_DAILOG,
    payload: status,
  });
};

//--------------------- Delete From Accounting Software - Track Invoice --------------------

export const DeleteInvoiceFromAccountingSoftware =
  (tokenFromFile, type, id, organizationId) => (dispatch) => {
    dispatch(DeleteInvoiceFromAccountingSoftwareLoading());
    //Sending login token to ensure the data privacy

    const accessTokenLogin = localStorage.getItem("accessTokenLogin");
    const config = {
      headers: {
        Authorization: `Bearer ${accessTokenLogin}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    const relmid = localStorage.getItem("realmId");

    let data = null;
    data = new FormData();
    data.append("access_token", tokenFromFile);
    data.append("realm_id", relmid);
    data.append("invoice_id", id);
    data.append("organization_id", organizationId);

    Axios.post(`${base_url}/delete_accounts_recievable/${type}`, data, config)
      .then((response) => {
        dispatch({
          type: TYPES.DELETE_QB_AR_INVOICE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.DELETE_QB_AR_INVOICE_FAIL,
        });
      });
  };

//-------------------------Delete From Accounting Software - Track Invoice -Loading --------------------

export const DeleteInvoiceFromAccountingSoftwareLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_QB_AR_INVOICE_LOADING,
  });
};

//-------------------------Delete From Accounting Software - Track Invoice - Clear Data--------------------

export const DeleteInvoiceFromAccountingSoftwareClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.DELETE_QB_AR_INVOICE_CLEAR_DATA,
  });
};

//--------------------- Tax Slab --------------------

export const TaxSlab = (tokenFromFile, organizationId) => (dispatch) => {
  dispatch(TaxSlabLoading());
  //Sending login token to ensure the data privacy

  const accessTokenLogin = localStorage.getItem("accessTokenLogin");
  const config = {
    headers: {
      Authorization: `Bearer ${accessTokenLogin}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const relmid = localStorage.getItem("realmId");
  const body = {
    access_token: tokenFromFile,
    realm_id: relmid,
    organization_id: organizationId,
  };

  Axios.post(`${base_url}/get_tax_slabs`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.TAX_SLAB_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.TAX_SLAB_FAIL,
      });
    });
};

//-------------------------TaxSlab - Loading --------------------

export const TaxSlabLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.TAX_SLAB_LOADING,
  });
};

//-------------------------TaxSlab - Clear Data--------------------

export const TaxSlabClarData = () => (dispatch) => {
  dispatch({
    type: TYPES.TAX_SLAB_CLEAR_DATA,
  });
};
