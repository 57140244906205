import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Divider, Grid, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import {
  PendingInvoiceListInAccountingSoftware,
  InvoiceApproveDialog,
  TrackInvoiceList
} from "../AccountsPayableActions/AccountsPayableActions";
import CircularProgressWithLabel from "../../../Common/CircularProgressWithLabel";

export default function ApproveDialog() {
  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  //Pending Invoice List -- Loading Status
  const invoiceApproveDialog = useSelector(
    (state) => state.AccountsPayableReducers.invoiceApproveDialog
  );

  const handleClose = () => {
    dispatch(TrackInvoiceList());
    dispatch(InvoiceApproveDialog(!invoiceApproveDialog));
  };

  //Upload Pending Invoice Loading
  const pendingInvoiceUploadInAccountingSoftwareLoading = useSelector(
    (state) =>
      state.AccountsPayableReducers
        .pendingInvoiceUploadInAccountingSoftwareLoading
  );

  //Upload Pending Invoice Success
  const pendingInvoiceUploadInAccountingSoftwareSucess = useSelector(
    (state) =>
      state.AccountsPayableReducers
        .pendingInvoiceUploadInAccountingSoftwareSucess
  );

  //Upload Pending Invoice Success -Message
  const pendingInvoiceUploadInAccountingSoftwareSucessStatus = useSelector(
    (state) =>
      state.AccountsPayableReducers
        .pendingInvoiceUploadInAccountingSoftwareSucessStatus
  );

  //Upload Pending Invoice Fail
  const pendingInvoiceUploadInAccountingSoftwareFail = useSelector(
    (state) =>
      state.AccountsPayableReducers.pendingInvoiceUploadInAccountingSoftwareFail
  );

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={invoiceApproveDialog}
        onClose={handleClose}
      >
        <DialogTitle>Approve pendind invoice</DialogTitle>
        <DialogContent>
          {pendingInvoiceUploadInAccountingSoftwareLoading ? (
            <>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <CircularProgressWithLabel />
                </div>
              </Grid>
            </>
          ) : null}
            {pendingInvoiceUploadInAccountingSoftwareSucessStatus ? (
              <>
                <Grid item xs={12}>
                  <Alert severity="info">
                    {pendingInvoiceUploadInAccountingSoftwareSucess.message}
                  </Alert>
                </Grid>
              </>
            ) : null}
            {pendingInvoiceUploadInAccountingSoftwareFail ? (
              <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Alert severity="error">
                    Invoice Upload failed! Please try again
                  </Alert>
                </Grid>
              </>
            ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
