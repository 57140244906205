const TYPES = {
  //---- User Menu Selection ------
  SELECTED_MENU_ITEM: "SELECTED_MENU_ITEM",

  //---- Get User Role  ------
  GET_USER_ROLE_LOADING: "GET_USER_ROLE_LOADING",
  GET_USER_ROLE_SUCCESS: "GET_USER_ROLE_SUCCESS",
  GET_USER_ROLE_FAIL: "GET_USER_ROLE_FAIL",
  GET_USER_ROLE_CLEAR_DATA: "GET_USER_ROLE_CLEAR_DATA",
};
export default TYPES;
