import React, { useEffect } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Index from "../AccountsPayable/Index";
import { Button, Hidden } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Intro from "./Intro";
import { UserMenuSelection } from "../Dashboard/DashboardActions/DashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { FetchAccessToken } from "../LandingPage/LandingPageActions/LandingPageActions";
import AccountReceivable from "../AccountReceivable/Index";
import TrackInvoice from "../AccountsPayable/AccountsPayablePages/TrackInvoice";

import FinancialReporting from "../FinancialReporting/Index";
import { useNavigate } from "react-router-dom";
import PendingInvoiceIndex from "../AccountsPayable/AccountsPayablePages/PendingInvoiceIndex";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GetUserRole } from "../Dashboard/DashboardActions/DashboardActions";

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {
  //Get access token - Loading Status

  //----------------------- Browser Online Check ----------------------------------

  const navigate = useNavigate();

  useEffect(() => {
    checkBrowserOnline();
  }, [navigator.onLine]);

  const checkBrowserOnline = () => {
    if (navigator.onLine === false) {
      navigate("/offline");
    }
  };

  //-----------------------------------------------------------------------------

  const [open, setOpen] = React.useState(true);
  const [apDrawerOpen, setApDrawerOpen] = React.useState(false);

  const handleClick = () => {
    setApDrawerOpen(!apDrawerOpen);
  };
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    navigate("/home");
    window.location.reload()
    // dispatch(UserMenuSelection("Dashboard"));
  };

  //Getting Id From - URL -> For QB Online Auth
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const realmId = urlParams.get("realmId");
  localStorage.setItem("code", code);
  localStorage.setItem("state", state);
  localStorage.setItem("realmId", realmId);

  //---------------------------------------------------------------

  const clientAuthStrig = localStorage.getItem("auth_basic_str");

  useEffect(() => {
    dispatch(FetchAccessToken(code, clientAuthStrig, realmId));
  }, [code]);

  //--------------------------------------------------------------

  const fetchAccessTokenSucess = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenSucess
  );

  useEffect(() => {
    dispatch(
      GetUserRole(
        fetchAccessTokenSucess.access_token,
        fetchAccessTokenSucess.org_id
      )
    );
  }, [fetchAccessTokenSucess]);

  //---------------------------------------------------------------

  //------------Setting The User Role -----------------------------

  const getUserRoleSuccess = useSelector(
    (state) => state.DashboardReducers.getUserRoleSuccess
  );

  //---------------------------------------------------------------

  //Menu Type
  const selectedMenuItem = useSelector(
    (state) => state.DashboardReducers.selectedMenuItem
  );
  function InputContainerType(menuType) {
    switch (menuType) {
      case "Dashboard":
        return <Intro />;
      case "AccountsPayable":
        return <Index />;
      case "AccountReceivable":
        return <AccountReceivable />;
      case "PendingInvoice":
        return <PendingInvoiceIndex />;
      case "TrackInvoice":
        return <TrackInvoice />;
      case "FinancialReporting":
        return <FinancialReporting />;
    }
  }

  //Menu Click Application
  const handleDashboard = () => {
    dispatch(UserMenuSelection("Dashboard"));
  };

  const handleAccountsPayable = () => {
    dispatch(UserMenuSelection("AccountsPayable"));
  };

  const handleAccountReceivable = () => {
    dispatch(UserMenuSelection("AccountReceivable"));
  };

  const handlePendingInvoice = () => {
    dispatch(UserMenuSelection("PendingInvoice"));
  };

  const handleTrackInvoice = () => {
    dispatch(UserMenuSelection("TrackInvoice"));
  };

  const handleFinancialReporting = () => {
    dispatch(UserMenuSelection("FinancialReporting"));
  };

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar sx={{ background: "#0093FB" }} position="absolute">
            <Toolbar
              sx={{
                pr: "0px", // keep right padding when drawer closed
              }}
            >
              <Typography
                onClick={handleDashboard}
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{
                  flexGrow: 1,
                  fontFamily: "'Roboto', sans-serif",
                  fontWeight: 600,
                }}
              >
                Finance Automation Platform
              </Typography>
              <Hidden mdDown>
                <Typography
                  onClick={handleDashboard}
                  color="inherit"
                  noWrap
                  sx={{
                    fontFamily: "'Roboto', sans-serif",
                    pl: 3,
                    pr: 3,
                    borderBottom:
                      selectedMenuItem == "Dashboard"
                        ? "1px solid #ffffff"
                        : "",
                    fontWeight: selectedMenuItem == "Dashboard" ? "600" : "",
                    cursor: "pointer",
                    "&:hover": {
                      cursor: "pointer",
                      fontWeight: 600,
                      fontSize: 16,
                    },
                  }}
                >
                  Home
                </Typography>
                <Typography
                  color="inherit"
                  onClick={handleAccountsPayable}
                  noWrap
                  sx={{
                    fontFamily: "'Roboto', sans-serif",
                    pl: 3,
                    pr: 3,
                    borderBottom:
                      selectedMenuItem == "AccountsPayable"
                        ? "1px solid #ffffff"
                        : "",
                    fontWeight:
                      selectedMenuItem == "AccountsPayable" ? "600" : "",
                    cursor: "pointer",
                    "&:hover": {
                      cursor: "pointer",
                      fontWeight: 600,
                      fontSize: 16,
                    },
                  }}
                >
                  Accounts Payable
                </Typography>
                <Typography
                  onClick={handleAccountReceivable}
                  color="inherit"
                  noWrap
                  sx={{
                    fontFamily: "'Roboto', sans-serif",
                    pl: 3,
                    pr: 3,
                    borderBottom:
                      selectedMenuItem == "AccountReceivable"
                        ? "1px solid #ffffff"
                        : "",
                    fontWeight:
                      selectedMenuItem == "AccountReceivable" ? "600" : "",
                    cursor: "pointer",
                    "&:hover": {
                      cursor: "pointer",
                      fontWeight: 600,
                      fontSize: 16,
                    },
                  }}
                >
                  Account Receivable
                </Typography>
                <Typography
                  onClick={handleFinancialReporting}
                  color="inherit"
                  noWrap
                  sx={{
                    fontFamily: "'Roboto', sans-serif",
                    pl: 3,
                    pr: 3,
                    mr: 2,
                    borderBottom:
                      selectedMenuItem == "FinancialReporting"
                        ? "1px solid #ffffff"
                        : "",
                    fontWeight:
                      selectedMenuItem == "FinancialReporting" ? "600" : "",
                    cursor: "pointer",
                    "&:hover": {
                      cursor: "pointer",
                      fontWeight: 600,
                      fontSize: 16,
                    },
                  }}
                >
                  Financial Reporting
                </Typography>
              </Hidden>
              <IconButton color="inherit" sx={{ p: 1 }} onClick={handleLogout}>
                <Badge color="secondary">
                  <LogoutIcon />
                </Badge>
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            sx={{
              backgroundColor: "#fffffff",
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            {InputContainerType(selectedMenuItem)}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default function Dashboard() {
  const fetchAccessTokenLoading = useSelector(
    (state) => state.LandingPageReducers.fetchAccessTokenLoading
  );
  return (
    <>
      <DashboardContent />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fetchAccessTokenLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
