import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PendingInvoice from "./PendingInvoice";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import IconBreadcrumbsPendingInvoice from "./IconBreadcrumbsPendingInvoice";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import PendingInvoiceInAccountingSoftware from "./PendingInvoiceInAccountingSoftware";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PendingInvoiceIndex() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 0, height: "80vh" }}>
        <Grid
          container
          spacing={0}
        >
          <Grid
            item
            xs={12}
            sx={{
              mt: 0,
            }}
          >
            <Alert sx={{ ml: 0, mr: 0, mb: 0 }} severity="info">
              <Typography
                sx={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px" }}
              >
                Invoice pending
              </Typography>
            </Alert>
          </Grid>
          <Grid container>
            {/* <Grid item xs={12} sx={{ p:1 }}>
              <IconBreadcrumbsPendingInvoice />
            </Grid> */}
            {/* <Grid item xs={12}>
              <Divider sx={{ ml: 1, mr: 1 }} /> */}
            {/* </Grid> */}
          </Grid>
          <Box sx={{ width: "100%", ml: 1, mr: 1 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "'Roboto', sans-serif",
                  }}
                  label="Pending In Portal"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "'Roboto', sans-serif",
                  }}
                  label="Pending In Accounting Software"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <PendingInvoice />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PendingInvoiceInAccountingSoftware />
            </TabPanel>
          </Box>
        </Grid>
      </Container>
    </>
  );
}
