import React, { useState, createRef, useRef, useEffect } from "react";
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import Message from "./message";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HistoryIcon from "@mui/icons-material/History";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import genInsight from "../../../Assets/genInsight_logo.svg";
import ErrorAlert from "./errorAlert";
import CorestartLogo from "../../../Assets/CorestartLogo.png";
// import { auth_url } from "../../../constants";

const auth_url = "https://gaurav-backend-fap-production-eastus-staging.azurewebsites.net";
const createSessionQuery = `${auth_url}/chatbot/createSession`;
const userQuery = `${auth_url}/chatbot/processQuery`;
const graphQuery = `${auth_url}/chatbot/graphProcessQuery`;
const sessionHistoryQuery = `${auth_url}/chatbot/getAllSession`;
const chatHistoryQuery = `${auth_url}/chatbot/getAllMessage/`;
const downloadChatHistoryQuery = `${auth_url}/chatbot/downloadChatHistory/`;
const processGraphHistory = `${auth_url}/chatbot/processGraphFromBotResponse`;

const MonthsInAlphabets = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ITEM_HEIGHT = 48;
const timeout = 180000; // 3min timeout for userQuery & graphQuery\

const sampleQuestions = [
  "What is the total revenue for UK region?",
  "What is the variance in cashflow between 2022 and 2023?",
  "What is the month wise total accounts payable for UK region?",
  "What is the variance between actual and budgeted revenue for UK in 2023?",
];

function InsideDataAi() {
  const [chatOpenStatus, setChatOpenStatus] = React.useState(false);
  const [chatHistoryStatus, setChatHistoryStatus] = React.useState(false);

  const [jwtToken, setJwtToken] = useState({ Authorization: `Bearer ${localStorage.getItem("accessTokenLogin")}` });
  const inputRef = useRef();
  const messagesListRef = createRef();
  const [activeChat, setActiveChat] = useState();
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [allSession, setAllSession] = useState([]);
  const [sessionId, setSessionId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [showIcon, setShowIcon] = useState({ 0: false, 1: false, 2: false, 3: false });
  const [errorAlert, setErrorAlert] = useState(false);
  const [jwtTokenStatus, setJwtTokenStatus] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMoreOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setErrorAlert(false);
  };

  const handleChatHistory = () => {
    console.log("handleChatHistory");
    setChatHistoryStatus(!chatHistoryStatus);
  };

  const handleChatHistorySelection = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleIconAppear = (iconPosition, newValue) => {
    setShowIcon((prev) => {
      return { ...prev, [iconPosition]: newValue };
    });
  };

  const options = [
    { icon: AddIcon, iconName: "New Chat", ClickOption: "postCreateSession" },
    { icon: FileDownloadOutlinedIcon, iconName: "Download Chat", ClickOption: "getDownloadChatHistoryQuery" },
    { icon: HistoryIcon, iconName: "Chat History", ClickOption: "handleChatHistory" },
  ];

  // Reformatting the date by removing the time and convert date to dd/mm/yyyy
  // const FormatDate = (dateString) => {
  //   const date = new Date(dateString + "Z");
  //   setAllMonth((previousState) => new Set([...previousState, MonthsInAlphabets[date.getMonth()]]));
  //   return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  // };

  const FormatDate = (dataArr) => {
    let tempObj = {};
    dataArr.map((data, idx) => {
      let date = new Date(data.created_datetime + "Z");
      let month = MonthsInAlphabets[date.getMonth()];

      let currentDate = new Date();
      let todayDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
      let data_date = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

      let tempData = {
        id: data.id,
        date_time: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`,
        session_name: month in tempObj ? `Session ${tempObj[month].length + 1}` : `Session 1`,
      };

      if (data_date === todayDate) {
        if ("Today" in tempObj) {
          tempObj["Today"].push(tempData);
        } else {
          tempObj["Today"] = [tempData];
        }
      }

      if (month in tempObj) {
        tempObj[month].push(tempData);
      } else {
        tempObj[month] = [tempData];
      }

      if (dataArr.length - 1 === idx && "Today" in tempObj) {
        getChatHistoryQuery(data.id);
        setSessionId(data.id);
      }
    });

    setAllSession(tempObj);
    if ("Today" in tempObj) {
      setActiveChat(tempObj["Today"][tempObj["Today"].length - 1].id);
      setSessionName(tempObj["Today"][tempObj["Today"].length - 1].session_name);
    }
  };

  useEffect(() => {
    async function getSessionHistoryQuery() {
      setErrorAlert(false);
      setJwtTokenStatus("");
      let headers = jwtToken;
      await axios
        .get(sessionHistoryQuery, { withCredentials: false, headers })
        .then((response) => {
          if (response.status === 200 && response.data.response_message === "SUCCESS") {
            FormatDate(response.data.response_data);

            return;
          }
        })
        .catch((err) => {
          // handle error status
          // set error message into useState (error display on session storage)
          console.log(err);
          if (err.code === "ERR_NETWORK" || err.response.status === 500) {
            setErrorAlert(true);
            return;
          }
          if (err.response.status === 401) {
            setJwtTokenStatus("invalid");
            setErrorAlert(true);
            return;
          }
        });
    }
    getSessionHistoryQuery();
  }, []);

  const postUserQuery = async (content) => {
    setErrorAlert(false);
    setJwtTokenStatus("");
    setLoading(true);
    let graph_message_data = "";
    let message_id = 0;
    const headers = jwtToken;
    let data = { session_id: sessionId, user_query: content };
    if (sessionId === 0) {
      let session_Id = await postCreateSession();
      data = { session_id: session_Id, user_query: content };
    }

    // add the message to the state
    let update_messages = [
      ...messages,
      {
        content: content,
        isUser: true,
      },
      {
        content: "loading_message",
        isUser: false,
      },
    ];
    setMessages(update_messages);

    await axios
      .post(userQuery, data, { withCredentials: false, headers, timeout })
      .then((response) => {
        if (response.status === 200 && response.data.response_message === "BOT_RESPONSE") {
          // console.log("userQuery ", response);
          response.data.response_data.map((res_data, index) => {
            if (update_messages[update_messages.length - 1].content === "loading_message") {
              update_messages = update_messages.map((item, index) => {
                // if it is the last element, return a new object with the updated content
                if (index === update_messages.length - 1) {
                  if (res_data.message_type === "text") {
                    graph_message_data = res_data.message_data;
                    message_id = res_data.id;
                    return { ...item, content: res_data.message_data, messageID: res_data.id, typing: true };
                  } else {
                    if (res_data.message_status === "error") {
                      return item;
                    } else {
                      return {
                        ...item,
                        content: res_data.message_data,
                        typing: false,
                        isUser: false,
                        image: true,
                        graphID: res_data.id,
                        time: res_data.created_datetime,
                        messageID: res_data.id,
                      };
                    }
                  }
                }
                // otherwise, return the original item
                return item;
              });
              setMessages(update_messages);
            } else {
              if (res_data.message_type === "text") {
                update_messages.push({ content: res_data.message_data, typing: true, isUser: false });
              }
              setMessages(update_messages);
            }
          });
        }
        if (response.status === 200) {
          let graphdata = { session_id: sessionId, message_data: graph_message_data };
          // add the message to the state
          let update_graph_messages = [
            ...messages,
            {
              content: content,
              isUser: true,
            },
            {
              content: graph_message_data,
              typing: true,
              isUser: false,
              messageID: message_id,
            },
            {
              content: "loading_message",
              isUser: false,
            },
          ];
          setMessages(update_graph_messages);

          axios
            .post(processGraphHistory, graphdata, { withCredentials: false, headers, timeout })
            .then((response) => {
              // console.log(response);
              if (
                response.status === 200 &&
                response.data.response_message === "BOT_RESPONSE" &&
                response.data.response_data.length !== 0
              ) {
                response.data.response_data.map((res_data, index) => {
                  // console.log(res_data);
                  if (update_graph_messages[update_graph_messages.length - 1].content === "loading_message") {
                    update_graph_messages = update_graph_messages.map((item, index) => {
                      // if it is the last element, return a new object with the updated content
                      if (index === update_graph_messages.length - 1) {
                        if (res_data.message_type === "graph") {
                          if (res_data.message_status === "success") {
                            // console.log("success ", res_data.message_data);
                            return {
                              ...item,
                              content: res_data.message_data,
                              typing: false,
                              isUser: false,
                              image: true,
                              graphID: res_data.id,
                              messageID: res_data.id,
                            };
                          }
                          if (res_data.message_status === "error") {
                            // console.log("error ", res_data.message_data);
                            return item;
                            // return {
                            //   ...item,
                            //   content: res_data.message_data,
                            //   typing: false,
                            //   isUser: false,
                            //   graphID: res_data.id,
                            //   messageID: res_data.id,
                            // };
                          }
                        }
                      }
                      // otherwise, return the original item
                      return item;
                    });
                    if (res_data.message_status === "error") {
                      update_graph_messages.pop();
                    }
                    setMessages(update_graph_messages);
                  }
                });
              } else if (
                response.status === 200 &&
                response.data.response_message === "BOT_RESPONSE" &&
                response.data.response_data.length === 0
              ) {
                if (update_graph_messages[update_graph_messages.length - 1].content === "loading_message") {
                  update_graph_messages.pop();
                  // console.log(update_graph_messages);
                  setMessages(update_graph_messages);
                }
              }
              setLoading(false);
            })
            .catch((err) => {
              if (err.name === "AxiosError" || err.code === "ERR_NETWORK") {
                // console.log(err);
                if (update_graph_messages[update_graph_messages.length - 1].content === "loading_message") {
                  update_graph_messages = update_graph_messages.map((item, index) => {
                    // if it is the last element, return a new object with the updated content
                    if (index === update_graph_messages.length - 1) {
                      if (err.name === "AxiosError") {
                        setErrorAlert(true);
                        return;
                        // graph_message_data = "Internal Server Error, Please Try again.";
                        // return { ...item, content: `Internal Server Error, Please Try again.`, typing: true, isUser: false };
                      }
                    }
                    // otherwise, return the original item
                    return item;
                  });
                  update_graph_messages.pop();
                  setMessages(update_graph_messages);
                }
              }
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        // handle error status
        // set error message into useState (same with the above)
        setJwtTokenStatus("");
        if (err.response.status) {
          if (err.response.status === 401) {
            setJwtTokenStatus("invalid");
            setErrorAlert(true);
          }
          if (err.response.status === 400 || err.response.status === 403) {
            // setErrorMessageAlert(true);
            // setErrorMessage(err.response.data.response_message);
            setErrorAlert(true);
            if (update_messages[update_messages.length - 1].content === "loading_message") {
              update_messages.pop();
              setMessages(update_messages);
            }
            setLoading(false);
            // if (err.response.data.response_message.includes("Not enough access.")) {
            // }

            // if (update_messages[update_messages.length - 1].content === "loading_message") {
            //   update_messages = update_messages.map((item, index) => {
            //     if (index === update_messages.length - 1) {
            //       return { ...item, content: err.response.data.response_message, typing: true };
            //     }
            //     return item;
            //   });
            // }
            // setMessages(update_messages);
            return;
          }
        }

        if (err.name === "AxiosError" || err.code === "ERR_NETWORK") {
          // console.log(err.code);
          if (update_messages[update_messages.length - 1].content === "loading_message") {
            update_messages = update_messages.map((item, index) => {
              // if it is the last element, return a new object with the updated content
              if (index === update_messages.length - 1) {
                if (err.name === "AxiosError") {
                  setErrorAlert(true);
                  return;
                  // graph_message_data = "Internal Server Error, Please Try again.";
                  // return { ...item, content: `Internal Server Error, Please Try again.`, typing: true, isUser: false };
                }
              }
              // otherwise, return the original item
              return item;
            });
            update_messages.pop();
            setMessages(update_messages);
          }
        }
        setLoading(false);
      });
  };

  const postGraphQuery = async (graphProps) => {
    setErrorAlert(false);
    setJwtTokenStatus("");
    const headers = jwtToken;
    const data = { session_id: sessionId, message_id: graphProps["graphID"], user_query: graphProps["graphMSG"] };
    // console.log("data", data);
    await axios
      .post(graphQuery, data, { withCredentials: false, headers, timeout })
      .then((response) => {
        if (response.status === 200 && response.data.response_message === "BOT_RESPONSE") {
          if (response.data.jwt_token !== null) {
            sessionStorage.setItem("jwtToken", `${response.data.jwt_token}`);
            setJwtToken({ Authorization: `Bearer ${response.data.jwt_token}` });
          }
          //console.log("postGraphQuery," response)
          response.data.response_data.map((res_data, index) => {
            let updated_graph = messages.map((data, index) => {
              if (data.graphID === res_data.id) {
                return {
                  ...data,
                  content: res_data.message_data,
                  typing: false,
                  isUser: false,
                  image: true,
                  graphID: res_data.id,
                  time: res_data.created_datetime,
                };
              }
              return data;
            });
            setMessages(updated_graph);
          });
        }
      })
      .catch((err) => {
        // handle error status
        // set error message into useState (same with the above)
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          setJwtTokenStatus("invalid");
          setErrorAlert(true);
          return;
        }
      });
  };

  async function postCreateSession() {
    setErrorAlert(false);
    setJwtTokenStatus("");
    setAnchorEl(null);
    setMessageInput("");
    const data = {};
    const headers = jwtToken;
    let result = await axios
      .post(createSessionQuery, data, { withCredentials: false, headers, jwtToken })
      .then((response) => {
        if (response.status === 201 && response.data.response_message === "CREATED") {
          // console.log("postCreateSession ", response);
          const res_data = response.data.response_data;
          setActiveChat(res_data.id);
          setSessionId(res_data.id);
          // let date_time = FormatDate(res_data.created_datetime); // setAllMonth
          let date = new Date(res_data.created_datetime + "Z");
          let month = MonthsInAlphabets[date.getMonth()];
          let tempData = {
            id: res_data.id,
            date_time: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`,
            session_name: month in allSession ? `Session ${allSession[month].length + 1}` : `Session 1`,
          };

          let data2 = allSession;
          if ("Today" in data2) {
            data2["Today"].push(tempData);
          } else {
            data2["Today"] = [tempData];
          }
          if (month in data2) {
            data2[month].push(tempData);
          } else {
            data2[month] = [tempData];
          }

          setAllSession(data2);
          setSessionName(tempData.session_name);
          // setAllSession((allSession) => [
          //   ...allSession,
          //   { id: res_data.id, session_name: `Session ${res_data.id}`, date_time: date_time },
          // ]);
          setMessages([]);
        }
        return response.data.response_data.id;
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          setJwtTokenStatus("invalid");
          setErrorAlert(true);
          return;
        }
      });
    return result;
  }

  const getChatHistoryQuery = async (session_id) => {
    setErrorAlert(false);
    setJwtTokenStatus("");
    const headers = jwtToken;
    setMessageInput("");
    setMessages([]);
    setSessionId(session_id);
    await axios
      .get(`${chatHistoryQuery}${session_id}`, { withCredentials: false, headers })
      .then((response) => {
        if (response.status === 200 && response.data.response_message === "SUCCESS") {
          // console.log("chatHistoryQuery", response);
          response.data.response_data.map((res_data, index) => {
            setMessages((messages) => [
              ...messages,
              {
                content:
                  res_data.message_type === "graph" && res_data.message_status === "error"
                    ? "Error plotting graph"
                    : res_data.message_data,
                image: res_data.message_type === "graph" && res_data.message_status === "success" ? true : false,
                isUser: res_data.message_owner === "user" ? true : false,
                typing: false,
                graphID: res_data.message_type === "graph" && res_data.id,
                time: res_data.created_datetime,
              },
            ]);
          });
        }
      })
      .catch((err) => {
        // handle error status
        // set error message into useState (error display on session storage)
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          setJwtTokenStatus("invalid");
          setErrorAlert(true);
          return;
        }
      });
  };

  async function getDownloadChatHistoryQuery(session_id) {
    setErrorAlert(false);
    setJwtTokenStatus("");
    setAnchorEl(null);
    const headers = jwtToken;
    const data = {};
    await axios
      .post(`${downloadChatHistoryQuery}${session_id}`, data, {
        withCredentials: false,
        headers,
      })
      .then((response) => {
        if (response.status === 201 && response.data.response_message === "SUCCESS") {
          console.log("getDownloadChatHistoryQuery ", response);
          window.open(response.data.response_data.download_url, "_blank");
        }
      })
      .catch((err) => {
        // handle error status
        // set error message into useState (error message)
        console.log(err);
        if (err.code === "ERR_NETWORK" || err.response.status === 500) {
          setErrorAlert(true);
          return;
        }
        if (err.response.status === 401) {
          setJwtTokenStatus("invalid");
          setErrorAlert(true);
          return;
        }
      });
  }

  const handleKeyDown = (event) => {
    //<------use enter key to submit form
    if (event.which === 13) {
      // inputRef.current.getElementsByTagName("textarea")[0].style.height = "auto";
      postUserQuery(messageInput);
      setMessageInput("");
      event.preventDefault();
    }
  };

  const handleMainPromptSubmit = (event) => {
    event.preventDefault();

    postUserQuery(messageInput);
    setMessageInput("");
  };

  return (
    <>
      <div style={{ position: "absolute", right: "14px", bottom: "20px" }}>
        <IconButton
          aria-label="Chat Window"
          onClick={() => {
            setErrorAlert(false);
            setChatOpenStatus(!chatOpenStatus);
          }}>
          <Tooltip title="Click Me">
            <Avatar
              alt="GenInsight.ai"
              src={genInsight}
              sx={{
                padding: "10px 10px 10px 12px",
                width: 60,
                height: 60,
                flexShrink: 0,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                bgcolor: "#ffffff",
              }}
            />
          </Tooltip>
        </IconButton>
      </div>
      {chatOpenStatus ? (
        <>
          <Box
            sx={{
              position: "absolute",
              right: "14px",
              bottom: "4px",
              width: 620,
              height: 600,
              backgroundColor: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #ffffff",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            }}>
            {errorAlert === true && <ErrorAlert jwtToken={jwtTokenStatus} />}
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: "10px 8px" }}>
              <IconButton
                sx={{ height: "40px", width: "40px" }}
                aria-label="More Options"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleMoreOptionClick}
                disabled={loading === true ? true : false}>
                <Tooltip title="More options">
                  <MoreVertIcon />
                </Tooltip>
              </IconButton>

              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: chatHistoryStatus ? "57ch" : "16ch",
                  },
                }}>
                <Box>
                  {options.map((option, idx) => (
                    <MenuItem
                      key={idx}
                      onClick={() => {
                        if (option.ClickOption === "getDownloadChatHistoryQuery") {
                          getDownloadChatHistoryQuery(sessionId);
                          return;
                        }
                        if (option.ClickOption === "postCreateSession") {
                          postCreateSession();
                          setShowIcon({ 0: false, 1: false, 2: false, 3: false });
                          return;
                        }
                        if (option.ClickOption === "handleChatHistory") {
                          handleChatHistory();
                          return;
                        }
                      }}
                      sx={{
                        width: "14.5ch",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Tooltip key={idx} placement="right" title={`${option.iconName}`}>
                        <Stack justifyContent="center" alignItems="center">
                          <option.icon />
                          {option.iconName}
                        </Stack>
                      </Tooltip>
                    </MenuItem>
                  ))}

                  {chatHistoryStatus && (
                    <Stack
                      style={{
                        position: "absolute",
                        left: "16ch",
                        top: "0px",
                        width: "100%",
                        height: "100%",
                        padding: "10px 0px",
                        // backgroundColor: "red",
                      }}
                      direction="row">
                      <Divider orientation="vertical" flexItem sx={{ border: "1px solid #d9d9d9" }} />
                      <Stack>
                        <FormControl sx={{ m: 1, maxWidth: 130 }} size="small">
                          <InputLabel id="demo-select-small-label">Month</InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            value={selectedMonth}
                            label="Month"
                            onChange={handleChatHistorySelection}>
                            {allSession.length !== 0 ? (
                              Object.keys(allSession).map((item, idx) => {
                                return (
                                  <MenuItem key={idx} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem value="">None</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        <Box sx={{ width: "17rem", height: "7.5rem", overflow: "auto" }}>
                          {selectedMonth in allSession &&
                            allSession[selectedMonth]
                              .slice()
                              .reverse()
                              .map((session, idx) => {
                                return (
                                  <Stack
                                    key={idx}
                                    direction="row"
                                    gap={3}
                                    sx={{ padding: "2px 10px", cursor: "pointer", ":hover": { color: "#0093fb" } }}
                                    onClick={() => {
                                      setActiveChat(session["id"]);
                                      setAnchorEl(null);
                                      if (!(activeChat === session["id"])) {
                                        getChatHistoryQuery(session["id"]);
                                      }
                                      setSessionName(session.session_name);
                                    }}>
                                    {session.session_name} <span>{session["date_time"]}</span>
                                  </Stack>
                                );
                              })}
                        </Box>
                      </Stack>
                    </Stack>
                  )}
                </Box>
              </Menu>

              <Stack sx={{ textAlign: "center", cursor: "default" }}>
                <h3 style={{ paddingTop: "4px" }}>
                  Gen<span style={{ color: "#0093fb" }}>Insight</span>.ai
                </h3>
                <span style={{ fontSize: "14px" }}>{sessionName}</span>
              </Stack>
              <IconButton
                aria-label="Close Chat"
                sx={{ width: "40px", height: "40px" }}
                onClick={() => {
                  setChatOpenStatus(!chatOpenStatus);
                }}>
                <Tooltip title="Close chat">
                  <KeyboardArrowDownIcon />
                </Tooltip>
              </IconButton>
            </Stack>
            <Box sx={{ height: "88%", overflow: "auto" }}>
              {messages.map((message, index) => (
                <Message
                  key={index}
                  content={message.content}
                  image={message.image}
                  isUser={message.isUser}
                  choices={message.choices}
                  typing={message.typing}
                  graphID={message.graphID}
                  time={message.time}
                  handleChoice={postUserQuery}
                  graphQueryDetails={(graphProps) => postGraphQuery(graphProps)}
                />
              ))}
              <div id="section2" style={{ height: "5.625rem" }}></div>
            </Box>

            {messages.length === 0 && (
              <Stack
                sx={{
                  border: "2px solid #999999",
                  background: "rgba(255, 255, 255, 0.10)",
                  borderRadius: "12px",
                  zIndex: 10,
                  margin: "0 auto",
                  width: "80%",
                  marginBottom: "10px",
                  position: "absolute",
                  bottom: "70px",
                  left: "60px",
                }}>
                {sampleQuestions.map((text, index) => {
                  return (
                    <Stack columnGap={2} key={index}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        // className="sample_question_box"
                        sx={{
                          flexShrink: 0,
                          padding: "4px 8px",
                          width: "calc(100% - 0rem)",
                          maxWidth: "60rem",
                          color: "#999999",
                          ":hover": {
                            cursor: "pointer",
                            backgroundColor: "#d7d7d7",
                            borderRadius: "10px",
                            opacity: "100",
                            color: "#000000",
                          },
                        }}
                        onMouseEnter={() => handleIconAppear(index, true)}
                        onMouseLeave={() => handleIconAppear(index, false)}
                        onClick={() => {
                          postUserQuery(text);
                        }}>
                        <span style={{ fontSize: "12px" }}>{text}</span>
                        <Tooltip title="Send">
                          {showIcon[index] ? (
                            <SendIcon />
                          ) : (
                            <SendIcon
                              sx={{
                                opacity: "0",
                                ":hover": {
                                  cursor: "pointer",
                                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                                  borderRadius: "10px",
                                  opacity: "100",
                                },
                              }}
                            />
                          )}
                        </Tooltip>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            )}
            <TextField
              sx={{
                position: "absolute",
                bottom: "-2px",
                left: "-1px",
                width: "calc(100% - 8px)",
                padding: "10px 10px 0px 20px",
                // background: "red",
                background: "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 1.0%, #fff 20.00%)",
                height: "5rem",
                borderRadius: "0px 0px 2px 10px",
              }}
              fullWidth
              variant="outlined"
              autoComplete="off"
              size="small"
              ref={inputRef}
              onKeyDown={handleKeyDown}
              // multiline
              // maxRows={2}
              autoFocus={true}
              disabled={loading ? true : false}
              placeholder="Type your question"
              value={messageInput}
              onChange={(event) => setMessageInput(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      variant="contained"
                      onClick={handleMainPromptSubmit}
                      type="submit"
                      disabled={messageInput === "" ? true : false}>
                      <Tooltip title="Send Message">
                        <SendIcon className="send-message-icon" />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              columnGap={1}
              sx={{ position: "absolute", bottom: "0px", left: "210px" }}>
              <Typography sx={{ fontWeight: "700" }}>Powered by</Typography>
              <img src={CorestartLogo} alt="Corestrat" style={{ height: "18px" }}></img>
            </Stack>
          </Box>
        </>
      ) : null}
    </>
  );
}

export default InsideDataAi;
