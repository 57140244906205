import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import CorestartLogo from "../../Assets/CorestartLogo.png";
import { Divider, Typography } from "@mui/material";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import { UserConfirmationbyAdmin } from "../LandingPage/LandingPageActions/LandingPageActions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import Failed from "../../Assets/istockphoto-1140725964-612x612-removebg-preview.png";
import Success from "../../Assets/tick-removebg-preview.png";

function UserConfirmation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    navigate("/");
  };

  //Getting the access token from the parms

  //Getting Id From - URL -> For QB Online Auth
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const verificationToken = urlParams.get("token");
  localStorage.setItem("verificationToken", verificationToken);

  useEffect(() => {
    dispatch(UserConfirmationbyAdmin(verificationToken));
  }, [verificationToken]);

  //User Conformation API Response

  const userConfirmationLoading = useSelector(
    (state) => state.LandingPageReducers.userConfirmationLoading
  );

  const userConfirmationStatus = useSelector(
    (state) => state.LandingPageReducers.userConfirmationStatus
  );

  const userConfirmationFail = useSelector(
    (state) => state.LandingPageReducers.userConfirmationFail
  );

  const handleClose = () => {
    window.close();
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ m: { xs: 3, md: 6, lg: 13 }, mt: { xs: 7 }, mb: { lg: 5 } }}
      >
        <motion.div
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          <img
            src={CorestartLogo}
            width="200"
            height="33"
            style={{ cursor: "pointer" }}
            onClick={handleLogoClick}
            alt="Corestart Logo"
          />
        </motion.div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          backgroundColor: "#e5f6fd",
          borderRadius: "5px",
          m: { xs: 3, md: 6, lg: 25 },
          mt: { lg: 0 },
          mb: { lg: 1 },
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.7 }}
        >
          <Typography
            sx={{ textAlign: "center", mt: 5, fontWeight: 900, fontSize: 20 }}
          >
            Finance Automation Platform - User Confirmation
          </Typography>
          <Divider sx={{ ml: 8, mr: 8, mt: 2 }} />

          <Grid container>
            {userConfirmationLoading ? (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", mt: 5 }}
                >
                  <CircularProgressWithLabel />
                </Grid>
              </>
            ) : null}

            {userConfirmationStatus ? (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", mt: 5 }}
                >
                  <motion.div
                    initial={{ opacity: 0, y: -40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    <Typography sx={{ fontWeight: 900 }}>
                      Access Granted to FAP Portal.
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                >
                  <motion.div
                    initial={{ opacity: 0, y: -40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    <img
                      src={Success}
                      width="140"
                      height="140"
                      style={{ cursor: "pointer" }}
                      alt="Success"
                    />
                  </motion.div>
                </Grid>
              </>
            ) : null}

            {userConfirmationFail ? (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", mt: 5 }}
                >
                  <motion.div
                    initial={{ opacity: 0, y: -40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    <Typography sx={{ fontWeight: 900 }}>
                      Sorry! Failed to process the request.
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", mt: 0 }}
                >
                   <motion.div
                    initial={{ opacity: 0, y: -40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                     <img src={Failed} width="200" height="200" alt="Failed" />
                  </motion.div>
                 
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ textTransform: "capitalize", m: 3 }}
            >
              Close Window
            </Button>
          </Grid>
        </motion.div>
      </Grid>
    </Grid>
  );
}

export default UserConfirmation;
